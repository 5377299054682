import { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import UserAdHostListItem from "../UserAdHostList/UserAdHostListItem";

import { useStore } from "../../../hook-store/store";

// import { NODEURL } from "../../../App";

import classes from './SingleAdHost.module.css';

function SingleAdHost(props) {
  const { 
    // data, 
    // deleteAdHostDataHandler, 
    // selectedAdHostData,
    // setSelectedAdHostData,
    // isLoading 
  } = props;

  // console.log('SingleAdHost.js-props', props);

  const { id } = useParams();
  const navigate = useNavigate();

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  
  const { 
    adHostDataList,
   } = store.adHostStore;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (adHostDataList && adHostDataList.length === 0) {
      navigate('/');
    }
  },[adHostDataList]);


  const deleteAdHostDataHandler = async (url, token, adType, adPlaceId) => {
    try {
      setIsLoading(true);
      // const adType = adType;
      // const adPlaceId = adPlaceId;

      let deleteUrl = `${url}/user-ad-host?adType=${adType}&adPlaceId=${adPlaceId}`;

      if (adType === 'videoWithAd') {
        deleteUrl = `${url}/video-with-ad?adType=${adType}&adPlaceId=${adPlaceId}`;
      }

      const response = await fetch(
        // `${url}/user-ad-host?adType=${adType}&adPlaceId=${adPlaceId}`,
        deleteUrl,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(inputData),
        }
      );

      const resData = await response.json();

      if (response.ok) {

        // eslint-disable-next-line no-console
        console.log(resData, response);

        const dbAdHostData = resData.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);
        

        const deletedList = adHostDataList.filter(element => {
          return element.adPlaceId !== adPlaceId;
        });

        dispatch('SET_ADHOST_DATALIST', deletedList);

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'Ad host data deleted',
          message: '',
        }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);

        setIsLoading(false);

        return resData;

        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong", resData, response);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host data deletion failed',
        message: '',
      });

      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };


  let singleAdHostBody;

  if (adHostDataList && adHostDataList.length > 0) {
    const data = adHostDataList.find(ele => {
      return ele._id === id;
    });

    if (data) {
      singleAdHostBody = (
        <UserAdHostListItem 
          data={data}
          deleteAdHostDataHandler={deleteAdHostDataHandler}
          // selectedAdHostData={selectedAdHostData}
          // setSelectedAdHostData={setSelectedAdHostData}
          isLoading={isLoading}
        />
      )
    } else {
      singleAdHostBody = (
        <div>
          not-found
          <Link to="/">back-to-home</Link>
        </div>
      );
    }

  }



  return (
    <Fragment>
      <div className={classes.singleAdHost}>
        {/* single-adhost-component */}
        <div>
          {singleAdHostBody}
        </div>
      </div>
    </Fragment>
  );
}

export default SingleAdHost;
