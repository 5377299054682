import { useEffect, useState, Fragment } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap.css';

import { useStore } from "../../../../hook-store/store";


import { adPageUrl } from '../../../../App';

import { marks } from "../../../../images/marks";
import classes from './AdHostDataCode.module.css';

function AdHostDataCode(props) {
  // console.log('AdHostDataCode-props', props);
  const { selectedAdHostData } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    videoWithAdWidth,
    videoWithAdHeight,
    videoMuteState,
    allowFullScreen,
   } = store.videoWithAdStore;
  const [someText, setSometext] = useState('');

  const [ttClicked, setTtClicked] = useState(false);
  const [showCodeInfo, setShowCodeInfo] = useState(false);

  const toolTipClickedHandler = () => {
    if (!ttClicked) {
      setTtClicked(true);
  
      setTimeout(() => {
        setTtClicked(false);
      },3000);
    }
  }

  const showCodeInfoHandler = () => {
    setShowCodeInfo(!showCodeInfo);
  };

const textForCopy = `<div>
  <div>div-content</div>
  <span>inside-span ${someText}</span>
</div>
`;

let textForCopy2;

if (selectedAdHostData) {
  let width = 660;
  let height = 150;

  if (selectedAdHostData.adType === '300x65') {
    width = 330;
    height = 75; 
  }

  if (selectedAdHostData.adType === '450x90') {
    width = 500;
    height = 100; 
  }

  if (selectedAdHostData.adType === '600x130') {
    width = 660;
    height = 150; 
  }

  if (selectedAdHostData.adType === '150x500') {
    width = 170;
    height = 550; 
  }

  if (selectedAdHostData.adType === '300x300') {
    width = 350;
    height = 400; 
  }

  if (selectedAdHostData.adType === 'video300') {
    width = 350;
    height = 350; 
  }

  textForCopy2 = `<iframe id="${selectedAdHostData.adPlaceId}"
  style={{width:"${width}px", height:"${height}px", border:"none"}}  
  src="${adPageUrl}?adPlaceId=${selectedAdHostData.adPlaceId}"
/>`
  

  if (selectedAdHostData.adType === 'videoWithAd') {
    width = videoWithAdWidth;
    height = videoWithAdHeight; 
    
    let videoMute = 0;

    if (videoMuteState) {
      videoMute = 1;
    }


    textForCopy2 = `<iframe id="${selectedAdHostData.adPlaceId}"
    style={{maxWidth:"100%", maxHeight:"100vh", width:"${width}px", height:"${height}px", border:"none"}}  
    src="${adPageUrl}?adPlaceId=${selectedAdHostData.adPlaceId}&mute=${videoMute}"
/>`

if (allowFullScreen) {
    textForCopy2 = `<iframe id="${selectedAdHostData.adPlaceId}"
    style={{maxWidth:"100%", maxHeight:"100vh", width:"${width}px", height:"${height}px", border:"none"}}  
    src="${adPageUrl}?adPlaceId=${selectedAdHostData.adPlaceId}&mute=${videoMute}"
    allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
/>`
}

  }

}

{/* <iframe id='ad-iframe-top-600x130' title='ad-iframe-top-600x130'
style={{width:"605px", height:"135px", overflow: 'hidden', border:"none"}}  
src={ADDISPLAY_URL + `?adType=600x130&adPlaceId=${window.location.host}-1660706630287`} 
onLoad={() => {}}
/> */}

{/* <iframe id='ad-iframe-top-450x90' title='ad-iframe-top-450x90'
style={{width:"460px", height:"95px", overflow: 'hidden', border:"none"}}  
src={ADDISPLAY_URL + `?adType=450x90&adPlaceId=${window.location.host}-1660706630287`} 
onLoad={() => {}}
/> */}

{/* <iframe id='ad-iframe-top-300x65' title='ad-iframe-top-300x65'
style={{width:"330px", height:"80px", overflow: 'hidden', border:"none"}}  
src={ADDISPLAY_URL + `?adType=300x65&adPlaceId=${window.location.host}-1660706630287`}
onLoad={() => {}}
/> */}



  // Did you know you can use tildes instead of backticks for code in markdown? ✨
  const markdown = `
~~~js
${textForCopy2}
~~~
`;




  return (
    <Fragment>
      <div className={classes.adHostDataCodeTitle}>
        <span>
          <span>
            {t('userAdHostList13', 'Code for page')} 
          </span>
          {' '}
          <span>
            <span onClick={showCodeInfoHandler}>
              {marks.questionCicleOMark}
            </span>
            <Tooltip
              placement="top"
              overlay={
                <div style={{ maxHeight: 150, maxWidth: 400, fontSize: "1rem" }}>
                  <div className={classes.createAdHostTooltipClose}>
                    <span className={classes.createAdHostTooltipCloseButton}
                      onClick={showCodeInfoHandler}
                    >
                      {marks.closeMark}
                    </span>
                  </div>
                  <div>
                    {t('userAdHostList14', "Insert this code into your page's HTML code. It may be required to adjust styles to fit your page.")} 
                  </div>
                </div>
              }
              align={{ offset: [-10, -5] }}
              // visible={sizeInfoClicked}
              visible={showCodeInfo}
              defaultVisible={true}
            >          
              <span></span>
            </Tooltip>
          </span>
               
        </span>
        <span>
          <span className={classes.adHostDataCopyButton}
            onClick={() => {
              navigator.clipboard.writeText(textForCopy2);
              toolTipClickedHandler();
            }}
          >
            {t('userAdHostList15', 'copy')} {marks.cloneMark}
          </span>

          {ttClicked && (
            <Tooltip
              placement="top"
              overlay={
                <div style={{ maxHeight: 150, maxWidth: 300, }}>
                  copied
                </div>
              }
              align={{ offset: [-30, -5] }}
              visible={ttClicked}
              defaultVisible={true}
            >          
              <span></span>
            </Tooltip>
          )}
        </span>
      </div>

      <ReactMarkdown
        className={classes.adHostDataMarkdown}
        children={markdown}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                // style={dark}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />

      {/* <div>adjast-style-to-fit-page</div> */}


  {/* <div onClick={() => {
    setSometext('some-text-content');
    console.log('add some-text');
  }}>
    add-some-text
  </div>

  <div onClick={() => {
    navigator.clipboard.writeText(textForCopy2);
    console.log('copied: ', textForCopy2);
    }}
  >
    test-for-copy
  </div> */}
    </Fragment>
  );
}

export default AdHostDataCode;
