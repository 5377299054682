import { Fragment } from "react";

import PayOutAbout from "../../components/PayOutAbout/PayOutAbout";

function PayOutAboutPage(props) {

  return (
    <Fragment>
      <PayOutAbout />
    </Fragment>
  );
}

export default PayOutAboutPage;
