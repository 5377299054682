
import { useEffect, useState, Fragment } from "react";

// import Backdrop from "../../../../Backdrop/Backdrop";
// import Modal from "../../../../Modal/Modal";
import Loader from "../../../../Loader/Loader";
// import VideoWithAdVideos from "../VideoWithAdVideos";
// import UpdateThumbnail from "../../ThumbnailDisplay/UpdateThumbnail";
// import RefTest from "../../../vidtest/RefTest";
import AdBannerCreate from "./AdBannerCreate/AdBannerCreate";
import VideoWithAdBannerItem from "./VideoWithAdBannerItem";

import { useStore } from "../../../../../hook-store/store";

import { 
  getVideoWithAdBannerAds,
  deleteVideoWithAdBanner,
} from "../../../../../utils/ad-host/video-with-ad-banner-util";

import { toHHMMSS } from "../../../../../utils/ad-host/video-with-ad-util";

import { NODEURL } from "../../../../../App";

import classes from './VideoWithAdBanner.module.css';

function VideoWithAdBanner(props) {
  const { 
    adHostData,
    setAdHostData,
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  // const isAuth = store.uiStore.isAuth;

  // const [adHostData, setAdHostData] = useState();
  const [bannerAds, setBannerAds] = useState([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (adHostData) {
      getBannerAdsHandler(
        adHostData.adPlaceId,
        adHostData.adType,
      )
    }
  },[adHostData]);


  useEffect(() => {
    if (adHostData) {
      const videoEl = document.getElementById('video-for-banner');
  
      if (videoEl) {
        videoEl.addEventListener('loadeddata', (event) => {
          console.log(event, 'loadeddata', videoEl.duration);
          setVideoDuration(videoEl.duration);
        });

        videoEl.addEventListener("timeupdate", (event) => {
        //  console.log('timeupdate', videoEl.currentTime);
         setVideoCurrentTime(videoEl.currentTime);
        });
      }
    }
  },[adHostData]);


  const getBannerAdsHandler = async (adPlaceId, adType) => {
    try {
      setIsLoading(true);
      
      const resData = await getVideoWithAdBannerAds(
        NODEURL,
        localStorage.getItem('token'),
        adPlaceId,
        adType,
      );

      if (resData.data) {

        // setAdHostData(resData.data);
        setBannerAds(resData.data);

        // dispatch('SHOW_NOTIFICATION', {
        //     status: '',
        //     title: 'Ad host banner data deleted',
        //     message: '',
        // });
  
        // setTimeout(() => {
        //   dispatch('CLEAR_NOTIFICATION');
        // }, 1000*5);


        setIsLoading(false);

      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Get Ad host banner ads data failed',
        message: '',
      });
  
      setIsLoading(false);
    }
  };

  // const deleteBannerHandler = async (adPlaceId, adType, start, end) => {
  //   try {
  //     setIsLoading(true);
      
  //     const resData = await deleteVideoWithAdBanner(
  //       NODEURL,
  //       localStorage.getItem('token'),
  //       adPlaceId,
  //       adType,
  //       start,
  //       end,
  //     );

  //     if (resData.data) {

  //       // setAdHostData(resData.data);
  //       setBannerAds(resData.data);

  //       dispatch('SHOW_NOTIFICATION', {
  //           status: '',
  //           title: 'Ad host banner data deleted',
  //           message: '',
  //       });
  
  //       setTimeout(() => {
  //         dispatch('CLEAR_NOTIFICATION');
  //       }, 1000*5);


  //       setIsLoading(false);

  //     } else {
  //       // reject({ message: 'get ads failed' });
  //       throw new Error("something wrong");
  //     }
  //   } catch (err) {
  //     // eslint-disable-next-line no-console
  //     console.log(err);
  //     dispatch('SHOW_NOTIFICATION', {
  //       status: '',
  //       title: 'Ad host banner data deletion failed',
  //       message: '',
  //     });
  
  //     setIsLoading(false);
  //   }
  // };



  let videoWithAdBannerBody = (
        <div>no-banner-ads</div>
    );

  if (adHostData && bannerAds.length > 0) {

      videoWithAdBannerBody = (
        <div style={{}}>
          <ul>
            {bannerAds.map((bAd, index) => {
              return (
                <li key={index}
                  className={classes.videoWithAdBannerListItem}
                >
                  <VideoWithAdBannerItem 
                    adHostData={adHostData}
                    bAd={bAd}
                    setBannerAds={setBannerAds}
                  />
                </li>
              );
            })}
          </ul>
  
        </div>
      );
  }


  return (
    <Fragment>
      {isLoading && (<Loader />)}
      <div>
        <strong>Banner control</strong>
      </div>
      {/* <div>video-duration: {videoDuration}</div> */}
      <div>
        {videoWithAdBannerBody}
      </div>

      {adHostData && (
        <div>
          <div>
            <AdBannerCreate 
              adHostData={adHostData}
              setBannerAds={setBannerAds}
              bannerAds={bannerAds}
              // setAdHostData={setAdHostData}
            />
          </div>
          <div className={classes.videoWithAdBannerVideoContainer}>
            <video 
              id="video-for-banner"
              style={{
                // height:"200px", 
                maxHeight: "250px",
                maxWidth: "100%",
                // display: 'none'
              }}
              controls
              src={adHostData.uploadVideoUrl}
            />
          </div>
          {videoCurrentTime > 0 && (
            <div>time: {Math.floor(videoCurrentTime)} seconds ({toHHMMSS(videoCurrentTime)})</div>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default VideoWithAdBanner;

