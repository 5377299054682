// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';



import { useStore } from '../../../../hook-store/store';
// import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';
// import { getUserAdHostsForAdmin, getAdHostsForAdmin } from '../../../utils/admin/admin-ad-host-util';
// import { NODEURL } from '../../../App';

const allAdTypeList = [
  {
    adType: '300x65',
    displayName: '300x65',
  },
  {
    adType: '450x90',
    displayName: '450x90',
  },  
  {
    adType: '600x130',
    displayName: '600x130',
  },  
  {
    adType: '150x500',
    displayName: '150x500',
  },  
  {
    adType: '300x300',
    displayName: '300x300',
  },
  {
    adType: 'videoWithAd',
    displayName: 'videoWithAd',
  },
];

const AdminAdHostFilter = props =>  {
  const { 
    listFilter,
    setAdHostFilterHandler,
    setIsReverse,
    isReverse,
 } = props;

  
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    usersForAdmin,  
    adminSelectedUser,
    adHostsForAdmin
  } = store.adminStore;
  // const { isAuth } = store.uiStore;

  const [hostList, setHostList] = useState([]);
  const [adStatusList, setAdStatusList] = useState([]);


  useEffect(() => {
    if (adHostsForAdmin && adHostsForAdmin.length > 0) {
      const hList = [];
      const sList = [];

      for (const adHost of adHostsForAdmin) {
        hList.push(adHost.host);

        if (adHost.adStatus) {
          sList.push(adHost.adStatus);
        }
      }

      const uniqHList = _.uniqBy(hList);
      const uniqSList = _.uniqBy(sList);
      setHostList(uniqHList);
      setAdStatusList(uniqSList);

    }
  },[adHostsForAdmin]);


  const resetFilterHandler = () => {
    setAdHostFilterHandler('', '');
    setIsReverse(false);
  }


  let adminAdHostFilterBody;

  if (listFilter) {
    adminAdHostFilterBody = (
      <div>
        <button onClick={resetFilterHandler}>
          reset-filter
        </button>
        <select
          value={listFilter.type === 'adType' ? listFilter.value : ''}
          onChange={(e) => {
            setAdHostFilterHandler('adType', e.target.value);
          }}
        >
          <option value={''}>select-ad-type</option>
  
          {allAdTypeList.map((item, index) => (
            <option key={index} value={item.adType}>
              {item.adType}
            </option>
          ))}
        </select>
        <select
          value={listFilter.type === 'host' ? listFilter.value : ''}
          onChange={(e) => {
            setAdHostFilterHandler('host', e.target.value);
          }}
        >
          <option value={''}>select-host</option>
  
          {hostList.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
  
        <select
          value={listFilter.type === 'adStatus' ? listFilter.value : ''}
          onChange={(e) => {
            setAdHostFilterHandler('adStatus', e.target.value);
          }}
        >
          <option value={''}>select-ad-status</option>
  
          {adStatusList.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>

        <select
          value={listFilter.type === 'createdAt' ? listFilter.value : ''}
          onChange={(e) => {
            setAdHostFilterHandler('createdAt', e.target.value);
          }}
        >
          <option value={''}>select-created-at</option>
  
          {['90d', '180d', '365d'].map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        
        <button
          onClick={() => { setIsReverse(!isReverse) }}
        >
          reverse-list
        </button>

      </div>
    );
  }

  return (
    <Fragment>
      <div>{adminAdHostFilterBody}</div>
    </Fragment>
  );
}

export default AdminAdHostFilter;

