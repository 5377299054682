import { Fragment } from "react";
import { Link } from "react-router-dom";
// import AboutPage from "../../pages/AboutPage/AboutPage";
import AboutPageTextContent from "../AboutPageContent/AboutPageText";
import AboutPageContent from "../AboutPageContent/AboutPageContent";
import AuthRequired from "../Auth/AuthRequired";
import Backdrop from "../Backdrop/Backdrop";
import Modal from "../Modal/Modal";

import { useStore } from "../../hook-store/store";

import classes from './NotPageFound.module.css';

function NotPageFound(props) {

  const [store, dispatch] = useStore();
  const { isAuth, userData } = store.uiStore;

  let notPageFoundBody;

  if (!isAuth) {
    notPageFoundBody = (
      <div className={classes.notPageFoundNoAuth}>
        {/* <div>non-auth-not-found-content</div> */}
        <AboutPageTextContent />
        <AuthRequired />
      </div>
    )
  } else {
    notPageFoundBody = (
      <div>
        {/* <div>not-page-found-content</div> */}
        <AboutPageContent />
      </div>
    )
  }
  return (
    <Fragment>
      <div>{notPageFoundBody}</div>
      {userData && userData.accountStatus === 'disabled' && (
        <div>
          <Backdrop />
          <Modal>
            User account was disabled.
            <div>
              <Link to="/contact">contact link</Link>
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

export default NotPageFound;
