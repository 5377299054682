
import { displayCoinList } from './display-coin-list';

// import XlmLogo from '../../images/logos/xlm-logo.png';
// import XrpLogo from '../../images/logos/xrp-logo.png';
// import PolygonUSDTLogo from '../../images/logos/polygon-usdt-logo.webp';

export const getUserAdHostPayOutAddress = async (url, token) => {
  try {
    const response = await fetch(
      `${url}/ad-host-pay-out/user-pay-out-address`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(inputData),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const addUserAdHostPayOutAddress = async (url, token, coin, address, memo, name) => {
  try {
    const response = await fetch(
      `${url}/ad-host-pay-out/add-user-pay-out-address`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coin: coin,
          address: address,
          memo: memo,
          name: name,
        }),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const deleteUserAdHostPayOutAddress = async (url, token, id) => {
  try {
    const response = await fetch(
      `${url}/ad-host-pay-out/delete-user-pay-out-address?id=${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({
        //   coin: coin,
        //   address: address,
        //   memo: memo,
        //   name: name,
        // }),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const setUserAdHostPayOutUseAddressId = async (url, token, id) => {
  try {
    const response = await fetch(
      `${url}/ad-host-pay-out/set-use-address-id`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const updateUserPayOutUseAddressName = async (url, token, id, name) => {
  try {
    const response = await fetch(
      `${url}/ad-host-pay-out/update-user-pay-out-address-name`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          name: name,
        }),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// export const displayCoinList = [
//   {
//     name: 'xlm',
//     displayCoinName: 'XLM',
//     logo: XlmLogo,
//   },
//   {
//     name: 'xrp',
//     displayCoinName: 'XRP',
//     logo: XrpLogo,
//   },
//   {
//     name: 'polygon:usdt',
//     displayCoinName: 'USDT (polygon network)',
//     logo: PolygonUSDTLogo,
//   },
// ];

export const createDisplayCoinName = (coin) => {

  const displayCoin = displayCoinList.find(dCoin => {
    return coin === dCoin.coin;
  });

  // console.log(displayCoin);

  if (displayCoin) {
    return displayCoin.displayName;
  }

  // if (coin === 'xlm') {
  //   displayCoinName = 'XLM';
  // }

  // if (coin === 'xrp') {
  //   displayCoinName = 'XRP';
  // }

  // if (coin === 'polygon:usdt') {
  //   displayCoinName = 'USDT (polygon network)'
  // }

  // return displayCoinName;
};

export const createDisplayCoinLogoImage = (coin, logoStyle) => {
  
  const displayCoin = displayCoinList.find(dCoin => {
    return coin === dCoin.coin;
  });

  // console.log(displayCoin)
  if (displayCoin) {
    const logoBody = (
      <img
        style={logoStyle}
        src={displayCoin.logo}
        alt="logo"
      />
    );

    return logoBody;
  }
  
  // let logoBody;

  // if (coin === 'xlm') {
  //   logoBody = (
  //     <img
  //       style={logoStyle}
  //       src={XlmLogo}
  //       alt="logo"
  //     />
  //   );
  // }

  // if (coin === 'xrp') {
  //   logoBody = (
  //     <img
  //       style={logoStyle}
  //       src={XrpLogo}
  //       alt="logo"
  //     />
  //   );;
  // }

  // if (coin === 'polygon:usdt') {
  //   logoBody = (
  //     <img
  //       style={logoStyle}
  //       src={PolygonUSDTLogo}
  //       alt="logo"
  //     />
  //   );
  // }

  // return logoBody;
};