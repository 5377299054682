import { Fragment } from "react";

import AdminPayOutAddressList from "../../../components/Admin/AdminPayOutAddress/AdminPayOutAddressList";

function AdminPayoutAddressPage(props) {

  return (
    <Fragment>
      <AdminPayOutAddressList />
    </Fragment>
  );
}

export default AdminPayoutAddressPage;
