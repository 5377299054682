import { Fragment } from 'react';
// import { useTranslation } from "next-i18next";
// import i18next from 'i18next';

// import Loader from '../Loader/Loader';
import { marks } from '../../images/marks';

import classes from './Modal.module.css'

function Modal(props) {
  // console.log('modal-props', props);
  const { onCancel } = props;
  // const { t } = useTranslation("common");

  function cancelHandler() {
    props.onCancel();

    if (props.setSelectAction) {
      props.setSelectAction('');
    }
  }

  function confirmHandler() {
    if (props.selectAction === 'DELETE') {
      console.log('DELETE clicked');
      props.onDelete(props.roomData);
    }
    else {
      props.onConfirm(props.roomData);
    }    
  }

  // let buttonName = 'Confirm';
  // let buttonName = 'Make Reservation';
  // let buttonName = i18next.t("roomFormText7")
  // if (props.selectAction === 'DELETE') {
  //   // buttonName = 'Delete'
  //   buttonName = i18next.t("generalText3")
  // }
  // if (props.selectAction === 'EDIT') {
  //   // buttonName = 'Edit'
  //   buttonName = i18next.t("generalText5")
  // }

  return (
    <Fragment>
      <div className={classes.modal}>
      <div className={classes.modalCloseMark}>
          <span className={classes.modalCloseMarkButton} 
            onClick={onCancel}
          >
            {marks.closeMark}
          </span>
        </div>
        <span>{props.children}</span>
        {/* <div>modal-content</div> */}
      </div>
      {/* <button className={classes.modalCancelButton} 
        onClick={cancelHandler}
        disabled={props.isLoading}
      >
        {i18next.t("generalText1")}
      </button>
      <button className={classes.modalConfirmButton} 
        onClick={confirmHandler}
        disabled={props.isLoading}
      >
        {props.isLoading ? 'Loading...' : buttonName}
      </button>
      <div className={classes.modalLoader}>{props.isLoading && <Loader />}</div>
      <div className={classes.resultMessage}>{props.createMessage}</div>
      <div className={classes.resultMessage}>{props.error}</div> */}
    </Fragment>
  );
}

export default Modal;