import axios from 'axios';

export const adHostFilesUpload = (url, token, selectedFiles, host, adType, dispatch) => {
  return new Promise((resolve, reject) => {
    const data = new FormData(); 

    // console.log(selectedFiles);
    for (const file of selectedFiles) {
      data.append('videos', file);
    }

    data.append('host', host);
    data.append('adType', adType);
    // data.append('files', selectedFiles);

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    // axios.post(url + "/bucket/objects", data, {  
    //   headers: headers
    //   // receive two    parameter endpoint url ,form data
    // })
    axios.request({
      method: 'post',
      url: url + "/video-with-ad",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        if (p.loaded/p.total === 1) {
          message = 'Uploaded, please wait until storing files finish...'
        }

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: message,
          message: '',
        });
      }
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  })

};


export const updateVideoWIthAdThumbnail = (url, token, selectedFile, adPlaceId, adType, dispatch) => {
  return new Promise((resolve, reject) => {
    const data = new FormData(); 

    // console.log(selectedFiles);

    data.append('image', selectedFile);
    data.append('adPlaceId', adPlaceId);
    data.append('adType', adType);
    // data.append('files', selectedFiles);

    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    // axios.post(url + "/bucket/object", data, { 
    // axios.post(url + "/bucket/objects", data, {  
    //   headers: headers
    //   // receive two    parameter endpoint url ,form data
    // })
    axios.request({
      method: 'put',
      url: url + "/video-with-ad/thumbnail",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        if (p.loaded/p.total === 1) {
          message = 'Uploaded, please wait until storing files finish...'
        }

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: message,
          message: '',
        });
      }
    })
    .then(res => { // then print response status
      // console.log(res);
      resolve(res);
    }).catch(err => {
      console.log(err);
      reject(err);
    });
  })

};