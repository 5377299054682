import { useEffect, useState, Fragment } from "react";

// import AdHostDataCode from "./AdHostDataCode/AdHostDataCode";
// import Backdrop from '../../Backdrop/Backdrop';
// import Modal from "../../Modal/Modal";
import Loader from "../../../Loader/Loader";
import VideoControl from "./VideoControl/VideoControl";
// import VideoWithAdVideos from "./VideoWithAdVideos";
// import UpdateThumbnail from "../../CreateVideoWithAd/UpdateThumbnail";
import VideoWithAdBanner from "./VideoWithAdBanner/VideoWithAdBanner";


import ThumbnailDisplay from "./ThumbnailDisplay/ThumbnailDisplay";
// import RefTest from "../../../vidtest/RefTest";

import { useStore } from "../../../../hook-store/store";

import { getAdHostData } from "../../../../utils/ad-host/ad-host-util";

// import { NODEURL } from "../../../App";
import { NODEURL } from "../../../../App";

import Black2s from './black-bg-2s.mp4';
import classes from './VideoWithAdDetail.module.css';

function VideoWithAdDetail(props) {
  const { 
    data, 
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const { storeSelectedAdHostData } = store.adHostStore;
  const { 
    videoWithAdWidth,
    videoWithAdHeight,
    showBorder,
    videoMuteState,
   } = store.videoWithAdStore;
  // const isAuth = store.uiStore.isAuth;

  const [adHostData, setAdHostData] = useState();
  const [showVideoWithAdDetail, setShowVideoWithAdDetail] = useState(true);
  const [showVideoPreview, setShowVideoPreview] = useState(true);
  const [showBannerControl, setShowBannerControl] = useState(false);
  const [videoPlayEnd, setVideoPlayEnd] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [heightInput, setHeightInput] = useState('');
  const [widthInput, setWidthInput] = useState('');
  // const [vContainerHeight, setVContainerHeight] = useState(200);
  // const [vContainerWidth, setVContainerWidth] = useState(400);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data && showVideoPreview) {
      getAdHostDataHandler(
        NODEURL,
        localStorage.getItem('token'),
        data.adPlaceId,
        data.adType,
      );
    }
  },[data, showVideoPreview]);

  useEffect(() => {
    if (storeSelectedAdHostData && storeSelectedAdHostData.adPlaceId !== data.adPlaceId) {
      // setShowVideoPreview(false);
      // setShowBannerControl(false);
    }
  },[storeSelectedAdHostData]);

  useEffect(() => {
    if (videoPlayEnd) {
      setVideoPlayEnd(false);
    }
  },[videoPlayEnd]);


  useEffect(() => {
    //// initialize store value to default when leave page
    return () => {
      dispatch('SET_VIDEOMUTESTATE', false);
      dispatch('SET_SHOWBORDER', true);
      dispatch('SET_VIDEOWITHADWIDTH', 400);
      dispatch('SET_VIDEOWITHADHEIGHT', 200);
      dispatch('SET_ALLOWFULLSCREEN', false);
    }
  },[]);
  // useEffect(() => {
  //   if (adHostData) {
  //     const getMeta = (url, cb) => {
  //       const img = new Image();
  //       img.onload = () => cb(null, img);
  //       img.onerror = (err) => cb(err);
  //       img.src = url;
  //     };
      
  //     // Use like:
  //     getMeta(adHostData.thumbnailUrl, (err, img) => {
  //       console.log('image meta', img);
  //       console.log(img.naturalWidth, img.naturalHeight);
  //     });
  //   }
  // },[adHostData]);


  const showVideoWithAdDetailHadler = (value) => {
    setShowVideoWithAdDetail(value);
  };

  const showVideoPreviewHadler = (value) => {
    setShowVideoPreview(value);
    dispatch('SET_STORESELECTEDADHOSTDATA', data);

    setShowBannerControl(false);
    if (value === true) {
      setShowBannerControl(false);
    }

    if (data.adPlaceId !== storeSelectedAdHostData.adPlaceId) {
      setShowVideoPreview(true);
    }
  };




  const showBannerControlHandler = (value) => {
    setShowBannerControl(value);
    dispatch('SET_STORESELECTEDADHOSTDATA', data);

    if (value === true) {
      setShowVideoPreview(false);
    }
  }

  const getAdHostDataHandler = async (url, token, adPlaceId, adType) => {
    try {
      setIsLoading(true);

      const resData = await getAdHostData(url, token, adPlaceId, adType);

      if (resData.data) {        
        setAdHostData(resData.data);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const createSampleVideoItems = (adHostData) => {
    const items = [
      { 
        type: 'pre-ad',
        adElementId: '',
        canSkip: false,
        skipMinSec: null,
        // vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
        vSrc: `${Black2s}`,
        thumbnailUrl: '',
        linkUrl: '',
      },
      // { 
      //   type: 'ad',
      //   adElementId: 'id-of-ad1',
      //   canSkip: false,
      //   skipMinSec: null,
      //   vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
      //   thumbnailUrl: '',
      //   linkUrl: 'https://adnetwork.spaceeight.net',
      // },
      {
        type: 'ad',
        adElementId: 'id-of-ad2',
        canSkip: true,
        skipMinSec: 3,
        vSrc: "http://www.w3schools.com/html/mov_bbb.mp4",
        thumbnailUrl: '',
        linkUrl: 'https://adnetwork.spaceeight.net',
      },
    ];

    items.push({
      type: '',
      adElementId: '',
      canSkip: false,
      skipMinSec: null,
      vSrc: adHostData.uploadVideoUrl,
      thumbnailUrl: adHostData.thumbnailUrl,
      linkUrl: '',
    });

    // console.log('items', items);

    return items;

  };

  let isSelectedData = false;

  if (storeSelectedAdHostData && storeSelectedAdHostData.adPlaceId === data.adPlaceId) {
    isSelectedData = true;
  }

  let previewStyle = { display: 'block' };
  let bannerControlStyle = { display: 'none' };

  if (isSelectedData && showBannerControl
  ) {
    bannerControlStyle = { display: 'block'};
    previewStyle = { display: 'block'};
  }

  // console.log(adHostData);

  // let videoWithAdVideosBody
  
  // if (videoPlayEnd) {
  //   videoWithAdVideosBody = null;
  // }

  // if (data && adHostData && !videoPlayEnd) {
  //   videoWithAdVideosBody = (
  //     <VideoWithAdVideos
  //       items={createSampleVideoItems(adHostData)}
  //       setVideoPlayEnd={setVideoPlayEnd}
  //       isPlaying={isPlaying}
  //       setIsPlaying={setIsPlaying}
  //       adHostData={adHostData}
  //     />
  //   );
  // }

  let videoMute = 0;

  if (videoMuteState) {
    videoMute = 1;
  }


  let videoWithAdDetailBody;

  if (adHostData) {

    videoWithAdDetailBody = (
      <div className={classes.videoWithAdDetail}
        style={{
          ...previewStyle,
          // border: "1px solid red",
          // background:"whitesmoke",
        }}
      >
        {/* <div>video-with-ad-content</div> */}
  
        {/* {adHostData && showVideoWithAdDetail && ( */}
        {isSelectedData && showVideoPreview && (
          <div>
            <iframe id="localhost:3000-1678953234980" title="title"
              style={{
                // width:"300px",
                maxWidth:"100%",
                maxHeight:"100vh",
                width: `${videoWithAdWidth}px`,
                // height: "200px",
                height: `${videoWithAdHeight}px`,
                border:  showBorder ? "1px solid red" : 'none', 
              }}
              // style={{maxWidth:"98%", maxHeight:"95vh", width:"800px", height:"500px", border:"1px solid red"}}
              src={`${window.location.origin}/adhost-preview?adPlaceId=${adHostData.adPlaceId}&adType=${adHostData.adType}&mute=${videoMute}`}
            />
            {/* <div style={{
              // width:"300px",
              maxWidth:"100%",
              maxHeight:"100vh",
              width: `${videoWithAdWidth}px`,
              // height: "200px",
              height: `${videoWithAdHeight}px`,
              border:  showBorder ? "1px solid red" : 'none', 
              // margin: "0 auto",
            }}
            >
              {videoWithAdVideosBody}
            </div> */}
  
            <div>
              <VideoControl 
                data={data}
              />
            </div>
  
            <ThumbnailDisplay 
              adHostData={adHostData}
              setAdHostData={setAdHostData}
            />
          </div>
        )}
  
          <div style={bannerControlStyle}>
              <VideoWithAdBanner 
                adHostData={adHostData}
                setAdHostData={setAdHostData}
              />
          </div>
  
            {isSelectedData && showVideoPreview && !showBannerControl && (
              <div className={classes.videoWithAdDetailPreviewSwitch}>
                <div>
                  <button className="btnSmallBase"
                    onClick={() => {
                      if (showBannerControl) {
                        showBannerControlHandler(false);
                      } else {
                        showBannerControlHandler(true);
                      }
                    }}
                  >
                    Switch to banner control
                  </button>
                </div>
                <div>
                  <button className="btnSmallBase"
                    onClick={() => {
                      setAdHostData(null);
                      getAdHostDataHandler(
                        NODEURL,
                        localStorage.getItem('token'),
                        data.adPlaceId,
                        data.adType,
                      );
                    }}
                  >
                    Reload video
                  </button>
                </div>
              </div>
            )}
          
          {isSelectedData && !showVideoPreview && showBannerControl && (
            <div className={classes.videoWithAdDetailPreviewSwitch}>
              <button className="btnSmallBase"
                onClick={() => {
                  if (showVideoPreview) {
                    showVideoPreviewHadler(false);
                  } else {
                    showVideoPreviewHadler(true);
                  }
                }}
              >
                Go back to video control
              </button>
            </div>
          )}
          
      </div>
    );
  }



  return (
    <Fragment>
      {isLoading && (<Loader />)}

        {videoWithAdDetailBody}
      </Fragment>
  );
}

export default VideoWithAdDetail;
