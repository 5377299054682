import React, { Fragment } from 'react';
// import { withI18n } from 'react-i18next';

// import RightContents from '../GroupRightElements/RightContents';
// import TopBarContents from '../GroupTopElements/TopBarContents';
// import GrayImage from '../../../images2/light-gray-square-300.jpg';
import GrayImage from '../../../images/light-gray-square-300.jpg';
import remeetImage1 from '../../../images/webinar-100.png';
// import remeetImage2 from '../../../images2/remeet-crop2-100.png';

import { ADNETWORKLINK_URL } from '../../../App';

import classes from './AdItems.module.css';
// import "../FeedEdit.css";
// import "./ImagePreviewContents.css"

const AdItems = (props) =>
{
	// console.log("AdItems.js-props", props);
	const { ad, adType, activeList } = props;

	let body300x65;

	if (ad && activeList && activeList.length > 0)
	{
		body300x65 = (
			<a className={classes.adLink}
				// href={ad.linkUrl}
				href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
				target='_blank' rel='noopener noreferrer'
			>
				<div className='body300x65Container'
					// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
				>
					<div className='body300x65Text'>
						<div className='body300x65TextContents'>
							<div className='body300x65TextTitle'>
								{ad.title}
							</div>
							<div className='body300x65TextDescription'>
								{ad.description}
							</div>
						</div>
					</div>
					{/* <div> */}
						<img
							className='body300x65Image'
							//  src={ad.adImageUrl}
							src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
							alt='300x65 image'
						/>
					{/* </div> */}
				</div>
			</a>
		);

			//// use updated image
			if (ad.ad600x130ImageUrl) {
				body300x65 = (
					<a className="body300x65Container"
						// className={classes.adLink}
						// href={ad.linkUrl}
						href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
						target='_blank' rel='noopener noreferrer'
					>
						<img
							className="body300x65bgImage"
							src={ad.ad600x130ImageUrl}
							alt="300x65 image"
						/>
					</a>
				);
			}
	}
	else
	{
		// some fallback

		body300x65 = (
			<div>
				<a
					style={{ color: 'white', textDecoration: 'none' }}
					// href='https://remeet.watakura.xyz/your-room-from-above-link'
					// target='_blank'
					// rel='noopener noreferrer'
				>
					<div className='body300x65Container'
					// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
					>
						<div className='body300x65Text'>
							<div className='body300x65TextContents'>
								<div className='body300x65TextTitle'>
									REMEET Web Meeting & Talk
								</div>
								<div className='body300x65TextDescription'>
									Simple Web Talk & Meeting with chat, file and screen sharing..
								</div>
							</div>
						</div>
						<img
							className='body300x65Image'
							src={remeetImage1}
							alt='300x65 image'
						/>
					</div>
				</a>
			</div>
		);

	}

	let body300x300;

	if (ad && activeList && activeList.length > 0)
	{
		body300x300 = (
			<a className={classes.adLink}
				// href={ad.linkUrl}
				href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
				target='_blank' rel='noopener noreferrer'
			>
				<div className='body300x300Container'
					// style={{display: 'flex',
					// flexDirection: 'column',
					// alignItems: 'center'}}
				>
					{/* <div> */}
						<img
							className='body300x300Image'
							//  src={ad.adImageUrl}
							src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
							alt='300x300 image'
						/>
					{/* </div> */}
					<div className='body300x300TextContainer'
					// style={{width:'275px', color:'#333', backgroundColor:"white"}}
					>
						<span className='body300x300TextTitle'>
							{ad.title}
						</span>
						<span className='body300x300TextDescription'>
							{ad.description}
						</span>
					</div>
				</div>
			</a>
		);
	}
	else
	{
		// some fallback
		body300x300 = (
			<div>
				<a
					style={{ color: 'white', textDecoration: 'none' }}
					// href='https://remeet.watakura.xyz/your-room-from-above-link'
					// target='_blank'
					// rel='noopener noreferrer'
				>
				<div className='body300x300Container'
					// style={{display: 'flex',
					// flexDirection: 'column',
					// alignItems: 'center'}}
				>
					{/* <div> */}
					<img
							className='body300x300Image'
							src={remeetImage1}
							alt='300x300 image'
						/>
					{/* </div> */}
					<div className='body300x300TextContainer'
						// style={{width:'275px', color:'#333', backgroundColor:"white"}}
					>
						<span className='body300x300TextTitle'>
							REMEET Web Meeting & Talk
						</span>
						<span className='body300x300TextDescription'>
						Simple Web Talk & Meeting with chat, file and screen sharing..
						</span>
					</div>
				</div>
				</a>
			</div>
		);
	}

	let body150x500;

	if (ad && activeList && activeList.length > 0)
	{
		body150x500 = (
			<a className={classes.adLink}
				// href={ad.linkUrl}
				href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
				target='_blank' rel='noopener noreferrer'
			>
				<div className='body150x500Container'
					// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
				>
					<div className='body150x500Text'>
						<div className='body150x500TextContents'>
							<div className='body150x500TextTitle'>
								{ad.title}
							</div>
							<div className='body150x500TextDescription'>
								{ad.description}
							</div>
						</div>
					</div>
					<img
						className='body150x500Image'
						src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
						alt='150x500 image'
					/>
				</div>
			</a>
		);
		
		//// use updated image
		if (ad.ad150x500ImageUrl) {
			body150x500 = (
					<a className="body150x500Container"
						// className={classes.adLink}
						// href={ad.linkUrl}
						href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
						target='_blank' rel='noopener noreferrer'
					>
						<img
							className="body150x500bgImage"
							src={ad.ad150x500ImageUrl}
							alt="150x500 image"
						/>
					
					</a>
			);
		}
	}
	else
	{
		// some fallback
		body150x500 = (
			<div>
				<a
					style={{ color: 'white', textDecoration: 'none' }}
					// href='https://remeet.watakura.xyz/your-room-from-above-link'
					// target='_blank'
					// rel='noopener noreferrer'
				>
					<div className='body150x500Container'
						// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
					>
						<div className='body150x500Text'>
							<div className='body150x500TextContents'>
								<div className='body150x500TextTitle'>
									REMEET Web Meeting & Talk
								</div>
								<div className='body150x500TextDescription'>
									Simple Web Talk & Meeting with chat, file and screen sharing..
								</div>
							</div>
						</div>
						<img
							className='body150x500Image'
							src={remeetImage1}
							alt='150x500 image'
						/>
					</div>
				</a>
			</div>
		);
	}

	let body450x90;

	if (ad && activeList && activeList.length > 0)
	{
		body450x90 = (
			<a className={classes.adLink}
				// href={ad.linkUrl}
				href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
				target='_blank' rel='noopener noreferrer'
			>
				<div className='body450x90Container'
				// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
				>
					<div className='body450x90Text'>
						<div className='body450x90TextContents'>
							<div className='body450x90TextTitle'>
								{ad.title}
							</div>
							<div className='body450x90TextDescription'>
								{ad.description}
							</div>
						</div>
					</div>
					<img
						className='body450x90Image'
						src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
						alt='450x90 image'
					/>
				</div>
			</a>
		);


		if (ad.ad600x130ImageUrl) {
			body450x90 = (
				<a className="body450x90Container"
					// className={classes.adLink}
					// href={ad.linkUrl}
					href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
					target='_blank' rel='noopener noreferrer'
				>
					<img 
						className="body450x90bgImage"
						// style={{width: "450px", height:"90px"}}
						src={ad.ad600x130ImageUrl}
						alt="450x90 image"
					/>
				</a>

			);
		}
	}
	else
	{
		// some fallback
		body450x90 = (
			<div>
				<a
					style={{ color: 'white', textDecoration: 'none' }}
					// href='https://remeet.watakura.xyz/your-room-from-above-link'
					// target='_blank'
					// rel='noopener noreferrer'
				>
					<div className='body450x90Container'
						// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
					>
						<div className='body450x90Text'>
							<div className='body450x90TextContents'>
								<div className='body450x90TextTitle'>
									REMEET Web Meeting & Talk
								</div>
								<div className='body450x90TextDescription'>
									Simple Web Talk & Meeting with chat, file and screen sharing..
								</div>
							</div>
						</div>
						<img
							className='body450x90Image'
							src={remeetImage1}
							alt='450x90 image'
						/>
					</div>
				</a>
			</div>
		);
	}


	let body600x130;

	if (ad && activeList && activeList.length > 0)
	{
		body600x130 = (
			<a className={classes.adLink}
				// href={ad.linkUrl}
				href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
				target='_blank' rel='noopener noreferrer'
			>
				<div className='body600x130Container'
				// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
				>
					<div className='body600x130Text'>
						<div className='body600x130TextContents'>
							<div className='body600x130TextTitle'>
								{ad.title}
							</div>
							<div className='body600x130TextDescription'>
								{ad.description}
							</div>
						</div>
					</div>
					<img
						className='body600x130Image'
						src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
						alt='600x130 image'
					/>
				</div>
			</a>
		);

		//// use updated image
		if (ad.ad600x130ImageUrl) {
			body600x130 = (
				<a className="body600x130Container"
					// className={classes.adLink}
					// href={ad.linkUrl}
					href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
					target='_blank' rel='noopener noreferrer'
				>  
					<img
						className="body600x130bgImage"
						src={ad.ad600x130ImageUrl}
						alt="600x130 image"
					/>
				</a>
			);
		}
	}
	else
	{
		// some fallback
		body600x130 = (
			<div>
				<a
					style={{ color: 'white', textDecoration: 'none' }}
					// href='https://remeet.watakura.xyz/your-room-from-above-link'
					// target='_blank'
					// rel='noopener noreferrer'
				>
					<div className='body600x130Container'
					// style={{ color:'#333', backgroundColor:"rgb(245, 245, 245)" }}
					>
						<div className='body600x130Text'>
							<div className='body600x130TextContents'>
								<div className='body600x130TextTitle'>
									REMEET Web Meeting & Talk
								</div>
								<div className='body600x130TextDescription'>
									Simple Web Talk & Meeting with chat, file and screen sharing..
								</div>
							</div>
						</div>
						<img
							className='body600x130Image'
							src={remeetImage1}
							alt='600x130 image'
						/>
					</div>
				</a>
			</div>
		);
	}

	let bodyInPosts;

	// if (ad && activeList && activeList.length > 0)
	// {
	// 	bodyInPosts = (
	// 		<a className={classes.adLink}
	// 			// href={ad.linkUrl}
	// 			href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
	// 			target='_blank' rel='noopener noreferrer'
	// 		>
	// 			<div className='bodyInPostContainer'>
	// 				<div className='bodyInPostContents'>
	// 					<img
	// 						className='bodyInPostImageContainer'
	// 						// src={ad.adImageUrl}
	// 						src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
	// 						alt='inPosts-image'
	// 					/>
	// 					<div className='bodyInPostTextContainer'>
	// 						<div className='bodyInPostTextTitle'>
	// 							{ad.title}
	// 						</div>
	// 						<div className='bodyInPostTextDescription'>
	// 							{ad.description}
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</a>
	// 	);

	// 	// //// use updated image
	// 	// if (ad.ad600x130ImageUrl) {
	// 	// 	bodyInPosts = (
	// 	// 		<a className={classes.adLink}
	// 	// 			// href={ad.linkUrl}
	// 	// 			href={`${ADNETWORKLINK_URL}?altk=${ad.token}`}
	// 	// 			target='_blank' rel='noopener noreferrer'
	// 	// 		>
	// 	// 			<div className="bodyInPostContainer">    
	// 	// 				<img
	// 	// 					src={ad.ad600x130ImageUrl}
	// 	// 					alt="inPosts-image"
	// 	// 				/>
	// 	// 			</div>
	// 	// 		</a>
	// 	// 	);
	// 	// }
		
	// }
	// else
	// {
	// 	// some fallback
	// 	bodyInPosts = (
	// 		<a className={classes.groupTalkRightElementLink}
	// 			href='https://remeet.watakura.xyz/your-room-from-above-link'
	// 			target='_blank'
	// 			rel='noopener noreferrer'
	// 		>
	// 			<div className={classes.groupTalkTopBarElementContainer}>
	// 				<div className={classes.groupTalkTopBarElement}>
	// 					<TopBarContents />
	// 				</div>
	// 			</div>
	// 		</a>
	// 	);
	// }

	let adItemsBody;

	if (adType === '300x65')
	{
		adItemsBody = (
			body300x65
			// <div 
			// // className={classes.topAdElementContainer}
			// >
			// 	{body300x65}
			// </div>
		);
	}

	if (adType === '300x300')
	{
		adItemsBody = (
			body300x300
			// <div>
			// 	{body300x300}
			// </div>
			// // <div className={classes.rightAdsContainer}>
			// // 	<div className={classes.rightAdsItem}>
			// // 		{body300x300}
			// // 	</div>
			// // </div>
		);
	}

	if (adType === '150x500')
	{
		adItemsBody = (
			body150x500
			// <div 
			// // className={classes.right150x500Container}
			// >
			// 	{body150x500}
			// 	{/* <div className={classes.rightAdsItem}>
			// 	</div> */}
			// </div>
		);
	}

	if (adType === '450x90')
	{
		adItemsBody = (
			body450x90
			// <div 
			// 	// className={classes.top450x90AdElementContainer}
			// >
			// 	{body450x90}
			// </div>
		);
	}

	if (adType === '600x130')
	{
		adItemsBody = (
			body600x130
			// <div 
			// 	// className={classes.top600x130AdElementContainer}
			// >
			// 	{body600x130}
			// </div>
		);
	}

	if (adType === 'inPosts')
	{
		adItemsBody = (
			<div className={classes.inPostsAdElementContainer}>
				{bodyInPosts}
			</div>
		);
	}

	if (adType === 'feedList' && activeList.length > 0)
	{
		adItemsBody = (
			<div className={classes.inPostsAdElementContainer}>
				{bodyInPosts}
			</div>
		);
	}

	return <Fragment>{adItemsBody}</Fragment>;
};

export default AdItems;
