export const generateBase64FromImage = imageFile => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onload = e => {
      console.log(e.target);
      return resolve(e.target.result);
    }
    reader.onerror = err => {
      // console.log(err);
      return reject(err);
    }
  });

  reader.readAsDataURL(imageFile);
  return promise;
};