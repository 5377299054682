import { useEffect, useState, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AdHostDataCode from "./AdHostDataCode/AdHostDataCode";
import Backdrop from '../../Backdrop/Backdrop';
import Modal from "../../Modal/Modal";
import VideoWithAdDetail from "../SingleAdHost/VideoWithAdDetail/VideoWithAdDetail";

import { useStore } from "../../../hook-store/store";

import { NODEURL } from "../../../App";

import classes from './UserAdHostListItem.module.css';

function UserAdHostListItem(props) {
  const { 
    data, 
    deleteAdHostDataHandler, 
    // selectedAdHostData,
    // setSelectedAdHostData,
    // thumbnailUrlList,
    isLoading 
  } = props;
  // console.log('UserAdHostListItem.js-props', props);

  const [t] = useTranslation('translation');

  const location = useLocation();

  let isInListPage = false;

  if (location.pathname === '/' || location.pathname === '/ad-host-list') {
    isInListPage = true;
  }

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();

  const { 
    thumbnailUrlList,
   } = store.adHostStore;
  

  const [selectedAdHostData, setSelectedAdHostData] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPageElement, setShowPageElement] = useState(false);

  const showElementForPageHandler = (data) => {

    if (showPageElement) {
      setShowPageElement(false);
      setSelectedAdHostData(null);
    } else {
      setShowPageElement(true);
      setSelectedAdHostData(data);
    }
  
  }

  let thumbnailUrl;

  if (thumbnailUrlList && thumbnailUrlList.length > 0) {
    const urlData = thumbnailUrlList.find(ele => {
      return ele.adPlaceId === data.adPlaceId;
    });

    if (urlData) {
      thumbnailUrl = urlData.thumbnailUrl;
    }
  }

  let userAdHostDataListItemBody;

  if (data) {
    userAdHostDataListItemBody = (
      <div className={classes.adhostListItemContainer}>
        <div className={classes.adhostListItemElement}>
          adPlaceId: <strong>{data.adPlaceId}</strong>
        </div>
        <div className={classes.adhostListItemElement}>
          Host: <strong>{data.host}</strong>
        </div>
        <div className={classes.adhostListItemElement}>
          {t('userAdHostList05', 'Ad type')}:
          <strong>{data.adType}</strong>
          </div>
        <div className={classes.adhostListItemElement}>
        {t('userAdHostList06', 'Creation time')}: <strong>{new Date(data.createdAt).toLocaleString()}</strong>
        </div>
        
        {data.adStatus && (
          <div className={classes.adhostListItemElement}>
            {t('userAdHostList07', 'Ad Status')}: <strong>{data.adStatus}</strong>
          </div>
        )}

        {data && isInListPage && (
          <div>
            <Link to={`/ad-host-list/${data._id}`}>
              <span onClick={() => { dispatch('SET_STORESELECTEDADHOSTDATA', data) }}>
                Ad host 
                {' '}
                {t('userAdHostList09', 'data detail')}
              </span>
            </Link>
          </div>
        )}
        {data && !isInListPage && (
          <div>
            <Link to={`/ad-host-list`}>
              {t('userAdHostList08', 'Go back to list')}
            </Link>
          </div>
        )}
        {!isInListPage && data.adType === 'videoWithAd' && (
          <VideoWithAdDetail 
            data={data}
          />
        )}

        {thumbnailUrl && (
          <Link to={`/ad-host-list/${data._id}`}>
            <img 
              style={{ height:"50px"}}
              src={thumbnailUrl}
              alt="thumbnail"
            />
          </Link>
        )}

        <div className={classes.adhostListItemActions}>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => { 
              setShowDeleteConfirm(true);
            }}
          >
            {t('userAdHostList10', 'Delete data')}
          </button>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => { 
              showElementForPageHandler(data)
            }}
          >
            {t('userAdHostList11', 'Display code for page')}
          </button>
        </div>

        {showDeleteConfirm && (
          <div>
            <Backdrop 
              onCancel={() => { 
                if (!isLoading) {
                  setShowDeleteConfirm(false); 
                  }
                }}
            />
            <Modal               
              onCancel={() => { 
                if (!isLoading) {
                  setShowDeleteConfirm(false); 
                }
              }}
            >
              <div>
                <div>
                {t('userAdHostList10', 'Delete data')}?
                </div>
                <div className={classes.adhostListItemElement}>
                  <button className="btnBase"
                    disabled={isLoading}
                    onClick={() => { 
                      setShowDeleteConfirm(false);
                    }}
                  > 
                    {t('userAdHostList12', 'Cancel')}
                  </button>
                  <button className="btnBase"
                    disabled={isLoading}
                    onClick={() => { 
                      deleteAdHostDataHandler(
                        NODEURL,
                        localStorage.getItem('token'),
                        data.adType,
                        data.adPlaceId,
                      );
                    }}
                  >
                    {t('userAdHostList10', 'Delete data')}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
        
        {showPageElement && (
          <AdHostDataCode 
            selectedAdHostData={selectedAdHostData}
          />
        )}

      </div>
    )
  }



  return (
    <Fragment>
      {userAdHostDataListItemBody}
    </Fragment>
  );
}

export default UserAdHostListItem;
