// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import VideoAdItems from '../AdElements/AdItems/VideoAdItems';

import { useStore } from '../../hook-store/store';

import { exampleAdPlaceId } from '../../App';
// import { marks } from '../../utils/marks';
import classes from './AboutPageText.module.css';

// import adImage1 from '../../images/kura-storage-ad-image-1.png';
// import adImage2 from '../../images/kura-storage-ad-image-2.png';


const AboutPageTextContent = props =>  {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  const [showMoreAds, setShowMoreAds] = useState(false);


  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  const adnetworkLinkEl = (
    <a className={classes.adLink}
      // href={ad.linkUrl}
      href="https://adnetwork.spaceeight.net"
      target='_blank' rel='noopener noreferrer'
    >
      Adnetwork
    </a>
  );

  let aboutPageTextBody;

  aboutPageTextBody = (
    <div>
      {/* {isAuth && (
        <div>
          <Link to="/ad-host-list">Go to list page</Link>
        </div>
      )} */}
      <div className={classes.aboutPageTextTitle}>
        Adnetwork Ad host
      </div>
      <p>
        Adnetwork Ad host 
        {' '}
        {t('aboutPage01', 'enables you to display ads in your websites.')}
      </p>
      <p>
      {t('aboutPage02', 'As types of ad, there are Banner Ad, and Video with Ad.')}
        {' '}
      </p>
      <p>
        <ul className={classes.aboutPageTextList}>
          <li className={classes.aboutPageTextListItem}>
            <strong>{t('aboutPage03', 'Banner Ad')}:</strong> 
            {' '}
            {t('aboutPage04', 'Banner ad with image or text and image')}
          </li>
          {/* <li>video-ad: video-ad</li> */}
          <li className={classes.aboutPageTextListItem}>
            <strong>{t('aboutPage05', 'Video with Ad')}:</strong>
            {' '} 
            {t('aboutPage06', "Video ad (before your site's video played), banner ad (when video is playing), video upload is required")}
          </li>
        </ul>
      </p>
      <p>
      {t('aboutPage07', 'Incomes from ads arise when viewers of your website visit the page of displayed ad.')}
        {' '}
        {t('aboutPage08', 'The incomes are shared with you and')}
        {' '}Ad host.
        {' '} 
        {t('aboutPage09', 'Majority of incomes (about 70%) will be paid out.')}
       </p>
      <p> 
        {t('aboutPage10', 'In the case of banner ad, it is possible to get pay out when viewers of your website click ad and visit the page of the ad.')}
        {' '}
        <br/>
        {t('aboutPage11', 'In the case of video with ad, viewer played ads for longer period of time, incomes from ads arise.')}
        {' '}
        (<Link to="/pay-out-about">
        {t('aboutPage12', 'go to payout description page')}
        </Link>)
      </p>
      <p>
      {t('aboutPage13', 'By inserting HTML code in your web site, ads will be started to display.')}
      </p>

      <p>
      {t('aboutPage14', "If you need to create and display your website's ad to reach your targets, you can create ads at")}
        {' '}
        {adnetworkLinkEl}.
        {' '}
        {t('aboutPage15', 'It is possible to create image ad, video ad, text ad at')}
        {' '}
        {adnetworkLinkEl}
      </p>

      {/* <div className={classes.aboutPageImageContainer}>
        <img className={classes.aboutPageImage}
          src={adImage1}
        />
        <img className={classes.aboutPageImage}
          src={adImage2}
        />
      </div> */}

    </div>
  );

  return (
    <Fragment>
      {aboutPageTextBody}
    </Fragment>
  );
}
export default AboutPageTextContent;

