import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import Notification from '../Notification/Notification';

import { useStore } from '../../hook-store/store';

import classes from './Layout.module.css';

// import { Link } from 'react-router-dom';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

function Layout(props) {
  // console.log('Layout.js-props', props);

  const location = useLocation();
  // console.log(location);

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  const notification = store.uiStore.notification;

  // if (!store.roomConfig) {
  //   return <GetConfig />
  // }
  let layoutBody;

  layoutBody = (    
    <div className={classes.AppContainer}>

      <Header />

      <main className={classes.main}>{props.children}</main>
      
      <Footer />

      {notification && (
        <div>
          <Notification 
            status={notification.status}
            title={notification.title}
            message={notification.message}
          />
        </div>
      )}
    </div>
  );

  if (location.pathname === '/adhost-preview') {
    layoutBody = (
      <main className={classes.main}>{props.children}</main>
    );
  }

  return (
    <Fragment>
      {layoutBody}
    </Fragment>
  );
}

export default Layout;
