// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AdminUserItem from './AdminUserItem';
import AdminUserSearch from './AdminUserSearch';
import Loader from '../../Loader/Loader';
// import AboutPageContent from '../../components/AboutPageContent/AboutPageContent';
import { useStore } from '../../../hook-store/store';
import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';

import { NODEURL } from '../../../App';
const AdminTopPageContent = props =>  {

  const  navigate = useNavigate();

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    usersForAdmin,  
    adminSelectedUser,
  } = store.adminStore;
  // const { isAuth } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (usersForAdmin.length === 0) {
      adminGetUsersHandler();
    }
  },[]);

  const adminGetUsersHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await getUsersForAdmin(
        NODEURL,
        localStorage.getItem('token'),
      );

      if (resData) {
        // console.log(resData)
        dispatch('SET_USERS_FORADMIN', resData);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const moveToUserAdPageHandler = (userObj) => {
    dispatch('SET_ADMIN_SELECTEDUSER', userObj);
    
    if (userObj) {
      navigate('/admin/ad-host-list');
      // history.push('/admin/ad-elements');
    }
  };

  const selectUserHandler = (userObj) => {
    dispatch('SET_ADMIN_SELECTEDUSER', userObj);
  };

  let adminUserListBody;

  if (usersForAdmin.length > 0) {
    adminUserListBody = (
      <div>

        <Link to="/admin/ad-host-list">
          <button>go-to-ad-host-list</button>
        </Link>

        {adminSelectedUser && (
          <div 
            title={JSON.stringify(adminSelectedUser)}
            style={{border:"1px solid green", padding:"0.5rem"}}>
            selected-userId: {adminSelectedUser.userId} 
            {' '}
            <br/>
            {adminSelectedUser.email}
            {' '}
            <button onClick={() => {moveToUserAdPageHandler(adminSelectedUser); }}>
              user's-ads
            </button>

            <Link to="/admin/ad-host-result">
              <button>ad-host-result-page</button>
            </Link>

            <Link to="/admin/pay-out-result">
              <button>pay-out-result-page</button>
            </Link>

            <Link to="/admin/pay-out-address">
              <button>pay-out-address-page</button>
            </Link>

            <button onClick={() => {selectUserHandler(null); }}>
              reset-user-select
            </button>


          </div>
        )}



        <ul>
          {usersForAdmin.map(user => {
            return (
              <ul>
                <li key={user.userId}>
                  <AdminUserItem 
                    user={user}
                    moveToUserAdPageHandler={moveToUserAdPageHandler}
                    selectUserHandler={selectUserHandler}
                  />
                </li>
              </ul>
            )
          })}
        </ul>
      </div>
    );
  }
  
  return (
    <Fragment>
      <AdminUserSearch />
      <div style={{paddingTop:"3rem"}}>admin-top-page-content</div>
      
      <div>{adminUserListBody}</div>

      {isLoading && <Loader />}
    </Fragment>
  );
}
export default AdminTopPageContent;

