import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap.css';
// import  _ from 'lodash';

// import Backdrop from "../Backdrop/Backdrop";
// import Loader from "../Loader/Loader";
// import Modal from "../Modal/Modal";

import { useStore } from "../../hook-store/store";
import { 
  createDisplayCoinName,
  createDisplayCoinLogoImage,
} from "../../utils/pay-out-address/pay-out-address-util";

import { NODEURL } from "../../App";

import { marks } from "../../images/marks";

import classes from './PayOutResultListItem.module.css';

function PayOutResultListItem(props) {
  const { payOut } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { userPayOutResults } = store.payoutStore;

  const [addressClicked, setAddressClicked] = useState(false);
  const [txidClicked, setTxidClicked] = useState(false);
  // const [showAddAddress, setShowAddAddress] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const addressClickeHandler = () => {
    if (!addressClicked) {
      setAddressClicked(true);
  
      setTimeout(() => {
        setAddressClicked(false);
      },3000);
    }
  };

  const txidClickeHandler = () => {
    if (!txidClicked) {
      setTxidClicked(true);
  
      setTimeout(() => {
        setTxidClicked(false);
      },3000);
    }
  };

  const displayCoinName = createDisplayCoinName(payOut.coin);
  
  const logoStyle = {height:"1rem", width: "1rem"};
  const logoBody = createDisplayCoinLogoImage(payOut.coin, logoStyle);

  let payOutResultListItemBody;

  if (payOut) {
    payOutResultListItemBody = (
      <div className={classes.payOutResultListItem}>
        {/* {payout._id} */}
        {/* <div>
          amount-USD: {payOut.amountUSD.toFixed(7)}
        </div> */}
        <div className={classes.payOutResultListItemContent}>
          {t('payOutResult02', 'Send amount')}: 
          <br/>
          <strong>
            {payOut.coinAmount} {displayCoinName} {logoBody} ({payOut.amountUSD.toFixed(7)} usd)
          </strong>
        </div>
        <div className={classes.payOutResultListItemContent}>
          {t('payOutResult03', 'Address')}: 
          <br/>
          <span className={classes.payOutResultListItemAddress}>
            <strong >
              {payOut.address.address}
            </strong>
            {' '}
              <span 
                onClick={() => {
                    navigator.clipboard.writeText(payOut.address.address);
                    addressClickeHandler();
                  }}
                >
                {marks.cloneMark}
              </span>
              {addressClicked && (
                <Tooltip
                  placement="top"
                  overlay={
                    <div style={{ maxHeight: 150, maxWidth: 300, }}>
                      copied
                    </div>
                  }
                  align={{ offset: [-10, -5] }}
                  visible={addressClicked}
                  defaultVisible={true}
                >          
                  <span></span>
                </Tooltip>
              )}
          </span>
        </div>
        <div className={classes.payOutResultListItemContent}>
          {t('payOutResult04', 'Transaction id')}: 
          <br/>
          <span className={classes.payOutResultListItemTxid}>
            <strong>
              {payOut.txid}
            </strong>
            {' '}
              <span 
                onClick={() => {
                    navigator.clipboard.writeText(payOut.txid);
                    txidClickeHandler();
                  }}
                >
                {marks.cloneMark}
              </span>
              {txidClicked && (
                <Tooltip
                  placement="top"
                  overlay={
                    <div style={{ maxHeight: 150, maxWidth: 300, }}>
                      copied
                    </div>
                  }
                  align={{ offset: [-10, -5] }}
                  visible={txidClicked}
                  defaultVisible={true}
                >          
                  <span></span>
                </Tooltip>
              )}
          </span>
        </div>
        <div className={classes.payOutResultListItemContent}>
          {t('payOutResult05', 'Date')}: 
          <br/>
          <strong>
            {new Date(payOut.createdAt).toLocaleDateString()}
          </strong>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <div>{payOutResultListItemBody}</div>
    </Fragment>
  );
}

export default PayOutResultListItem;
