import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap.css';

import Backdrop from "../../Backdrop/Backdrop";
import Loader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import PayOutAddressNameUpdate from "./PayOutAddressNameUpdate";

import { useStore } from "../../../hook-store/store";

import { 
  deleteUserAdHostPayOutAddress,
  setUserAdHostPayOutUseAddressId,
  createDisplayCoinName,
  createDisplayCoinLogoImage,
} from "../../../utils/pay-out-address/pay-out-address-util";

import { NODEURL } from "../../../App";

import { marks } from "../../../images/marks";
import XlmLogo from '../../../images/logos/xlm-logo.png';
import XrpLogo from '../../../images/logos/xrp-logo.png';
import PolygonUSDTLogo from '../../../images/logos/polygon-usdt-logo.webp';

import classes from './PayOutAddressListItem.module.css';

function PayOutAddressListItem(props) {
  const { 
    address,
    isUseAddress,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { userPayOutAddress } = store.payoutStore;

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [addressClicked, setAddressClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const addressClickeHandler = () => {
    if (!addressClicked) {
      setAddressClicked(true);
  
      setTimeout(() => {
        setAddressClicked(false);
      },3000);
    }
  };

  const deleteUserPayOutAddressHandler = async (id) => {
    try {
      setIsLoading(true);

      const resData = await deleteUserAdHostPayOutAddress(
        NODEURL,
        localStorage.getItem('token'),
        id,
      );
  
      console.log(resData);
      
      if (resData.data) {
        dispatch('SET_USERPAYOUTADDRESS', resData.data);
        setShowDeleteConfirm(false);

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'deleted',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const setPayoutUseAddressIdHandler = async (id) => {
    try {
      setIsLoading(true);

      const resData = await setUserAdHostPayOutUseAddressId(
        NODEURL,
        localStorage.getItem('token'),
        id,
      );
  
      console.log(resData);
      
      if (resData.data) {
        dispatch('SET_USERPAYOUTADDRESS', resData.data);

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'updated',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const logoStyle = {height:"1rem", width: "1rem"};
  let logoBody;
  let memoTitle;
  let displayCoinName;

  if (address.coin === 'xlm') {
    logoBody = (
      createDisplayCoinLogoImage(address.coin, logoStyle)
    );

    memoTitle = 'memo';
    displayCoinName = createDisplayCoinName(address.coin);
  }

  if (address.coin === 'xrp') {
    logoBody = (
      createDisplayCoinLogoImage(address.coin, logoStyle)
    );

    memoTitle = 'destination tag';
    displayCoinName = createDisplayCoinName(address.coin);
  }

  if (address.coin === 'polygon:usdt') {
    logoBody = (
      createDisplayCoinLogoImage(address.coin, logoStyle)
    );
    
    memoTitle = '';
    displayCoinName = createDisplayCoinName(address.coin);

  }


  let payOutAddressListItemBody;

  if (address) {
    payOutAddressListItemBody = (
      <div>
        {/* <div>adress-id: {address.id}</div> */}
        <div className={classes.payOutAddressListItemCoin}>
          <span>
            {t('payOutAddress04', 'Coin name')}
          </span>
          <span className={classes.payOutAddressListItemCoinName}>
            <strong>{displayCoinName} {logoBody}</strong>
          </span>
        </div>

        <div className={classes.payOutAddressListItemContent}>
          <div>
            {t('payOutAddress10', 'Address')}
            {' '}
            <strong>
              {address.address}
            </strong>
            {' '}
            <span 
              onClick={() => {
                  navigator.clipboard.writeText(address.address);
                  addressClickeHandler();
                }}
              >
              {marks.cloneMark}
            </span>
            {addressClicked && (
              <Tooltip
                placement="top"
                overlay={
                  <div style={{ maxHeight: 150, maxWidth: 300, }}>
                    copied
                  </div>
                }
                align={{ offset: [-10, -5] }}
                visible={addressClicked}
                defaultVisible={true}
              >          
                <span></span>
              </Tooltip>
            )}
          </div> 
        </div>

        {memoTitle && (
          <div className={classes.payOutAddressListItemContent}>
            {memoTitle}: <strong>{address.memo}</strong>
          </div>
        )}

        <div className={classes.payOutAddressListItemContent}>
          {t('payOutAddress11', 'Add date')}
          {' '}
          <strong>
            {new Date(address.createTime).toLocaleDateString()}
          </strong>
        </div>

        {address.name && (
            <div className={classes.payOutAddressListItemContent}>
              {t('payOutAddress12', 'Nickname')} <strong>{address.name}</strong>
          </div>
        )}

        {isUseAddress && (
          <div className={classes.payOutAddressListItemContent}>
            <strong>{t('payOutAddress13', 'Payout address')}</strong>
          </div>
        )}
        {!isUseAddress && (
          <div className={classes.payOutAddressListItemContent}>
            <button className="btnSmallBase"
              disabled={isLoading}
              onClick={() => {
                setPayoutUseAddressIdHandler(address.id);
              }}
            >
              {t('payOutAddress14', 'Use this address')}
            </button>
          </div>
        )}

        <div className={classes.payOutAddressListItemEditButtons}>
          <PayOutAddressNameUpdate 
            address={address}
          />
          
          <div className={classes.payOutAddressListItemDelete}>
            <button className="btnSmallBase"
              disabled={isLoading}
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
            >
              {t('payOutAddress15', 'Delete')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {isLoading && (<Loader />)}
      <div className={classes.payOutAddressListItem}>
        {payOutAddressListItemBody}
      </div>

      {showDeleteConfirm && (
        <div>
          <Backdrop 
            onCancel={() => { setShowDeleteConfirm(false); }}
          />
          <Modal
             onCancel={() => { setShowDeleteConfirm(false); }}
             isLoading={isLoading}
          >
            <div>
              <div>{t('payOutAddress15', 'Delete')}?</div>
              <div className={classes.payOutAddressListItemDeleteButtons}>
                <button className="btnBase"
                  disabled={isLoading}
                  onClick={() => {
                    setShowDeleteConfirm(false);
                  }}
                >
                  {t('payOutAddress08', 'Cancel')}
                </button>
                <button className="btnBase"
                  disabled={isLoading}
                  onClick={() => {
                    deleteUserPayOutAddressHandler(address.id);
                  }}
                >
                  {t('payOutAddress15', 'Delete')}
                </button>
              </div>
              {isLoading && (<Loader />)}
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

export default PayOutAddressListItem;
