// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AdminAccountStatus from './AdminAccountStatus';
import Loader from '../../Loader/Loader';
// import AboutPageContent from '../../components/AboutPageContent/AboutPageContent';
import { useStore } from '../../../hook-store/store';
// import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';

import { NODEURL } from '../../../App';
const AdminUserItem = props =>  {

  const { 
    user,
    moveToUserAdPageHandler,
    selectUserHandler,
   } = props;

  const  navigate = useNavigate();

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    usersForAdmin,  
    adminSelectedUser,
  } = store.adminStore;
  // const { isAuth } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);



  let adminUserItemBody;

  if (user) {
    adminUserItemBody = (
      <div>
        
        <hr/>

        <div>userId: {user.userId}</div>
        <div>email: {user.email}</div>
        <div>accountStatus: {user.accountStatus}</div>
        <button onClick={() => {moveToUserAdPageHandler(user); }}>user's ads</button>
        <button onClick={() => {selectUserHandler(user); }}>select-user</button>
        
        {adminSelectedUser && adminSelectedUser.userId === user.userId && (
          <span>
            <Link to="/admin/ad-host-result">
              <button>ad-host-result-page</button>
            </Link>

            <Link to="/admin/pay-out-result">
              <button>pay-out-result-page</button>
            </Link>
            
            <Link to="/admin/pay-out-address">
              <button>pay-out-address-page</button>
            </Link>
          </span>
        )}
        
        <div>
          <AdminAccountStatus 
            userId={user.userId}
          />
        </div>
        {/* <button 
          onClick={() => { 
            setShowUpdateAccountStatus(!showUpdateAccountStatus); 
            setUserIdForUpdate(user.userId);
          }}
        >
          update-account-status
        </button>
    
        {showUpdateAccountStatus && userIdForUpdate === user.userId && (
          <AdminAccountStatus 
            userId={user.userId}
          />
        )} */}
        
      </div>
    );
  }
  
  return (
    <Fragment>
      <div>{adminUserItemBody}</div>
      {isLoading && <Loader />}
    </Fragment>
  );
}
export default AdminUserItem;

