import { useEffect, useState, Fragment } from "react";

import Loader from '../../../../Loader/Loader';

import { useStore } from "../../../../../hook-store/store";

import { generateBase64FromImage } from "../../../../../utils/ad-host/file";
import { adHostFilesUpload, updateVideoWIthAdThumbnail } from '../../../../../utils/ad-host/file-upload';

import { NODEURL } from "../../../../../App";

import classes from './UpdateThumbnail.module.css';

function UpdateThumbnail(props) {
  const { 
    adType, 
    adPlaceId, 
    setAdHostData,
    setShowThumbnailUpdate,
   } = props;
  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;
  const adHostDataList = store.adHostStore.adHostDataList;

  // const [urlData, setUrlData] = useState();
  // const [hostInput, setHostInput] = useState('');
  // const [adTypeInput, setAdTypeInput] = useState('');
  // const [adPlaceIdInput, setAdPlaceIdInput] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  // const [videoPreview, setVideoPreview] = useState();
  const [imagePreview, setImagePreview] = useState();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputData = {
    // host: hostInput,
    adPlaceId: adPlaceId,
    adType: adType,
    selectedFiles: selectedFiles,
    // adPlaceId: adPlaceIdInput,
  };

  // console.log(inputData);

  // const isValidInputs = () => {
  //   if (hostInput && adTypeInput && selectedFiles.length > 0) {
  //     return true;
  //   }
  //   return false;
  // }

  // console.log('iputData', inputData);

  // useEffect(() => {
  //   if (isAuth) {
  //     getUserAdHostDataListHandler(
  //       NODEURL,
  //       localStorage.getItem('token')
  //     );
  //   }

  // }, [isAuth]);

  // const showConfirmHandler = (value) => {
  //   setShowConfirm(value);
  // }

  // const urlInputChangeHandler = (event) => {
  //   const isValidUrl = isValidHttpUrl(event.target.value);

  //   if (isValidUrl) {
  //     const inputUrl = new URL(event.target.value);
  //     // const host = inputUrl.host;
  //     // console.log(inputUrl);
  //     setHostInput(inputUrl.host);
  //     setUrlData(inputUrl);
  //   }
  // };


  // const adTypeInputChangeHandler = (event) => {
  //   setAdTypeInput(event.target.value);
  // };

  // const adPlaceIdInputChangeHandler = (event) => {
  //   setAdPlaceIdInput(event.target.value);
  // };


  // function isValidHttpUrl(string) {
  //   let url;
    
  //   try {
  //     url = new URL(string);
  //   } catch (_) {
  //     return false;  
  //   }
  
  //   return url.protocol === "http:" || url.protocol === "https:";
  // }

  const selectFileHndler = async (event) => {
    try {
      console.log(event.target.files);
      setSelectedFiles(event.target.files);
  
      const preview = await generateBase64FromImage(event.target.files[0]);
  
      setImagePreview(preview);

    } catch(err) {
      console.log(err);
    }
    
  };



  const updateThumbnailHandler = async (url, token, inputData) => {
    try {
      setIsLoading(true);

      const result = await updateVideoWIthAdThumbnail(
        url,
        token,
        inputData.selectedFiles[0],
        inputData.adPlaceId,
        inputData.adType,
        dispatch,
      );
      
      console.log(result, result.data);

      if (result.data && result.data.data) {

        const dbAdHostData = result.data.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);

        const storeDataList = adHostDataList;
        // const dataIndex = storeDataList.findIndex(ele => {
        //   return ele.adPlaceId === dbAdHostData.adPlaceId;
        // });

        // storeDataList[dataIndex] = dbAdHostData; 
        
        // dispatch('SET_ADHOST_DATALIST', storeDataList);

        setAdHostData(dbAdHostData);
        setShowThumbnailUpdate(false);
        // setShowVideoWithAdCreate(false);
        setIsLoading(false);

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'Ad host data updated',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);

        return result.data;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }

    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host data update failed',
        message: '',
      }
    );
      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };

  let updateTHumbnailBody;

  updateTHumbnailBody = (
    <div>
      <div className={classes.updateThumbnailInput}>
        <input 
          type='file'
          onChange={selectFileHndler}
        />
      
        {imagePreview && (
          <div>
            <img className={classes.updateThumbnailPreview}
              src={imagePreview}
              alt='preview'
            />
          </div>
        )}
      </div>

      <div className={classes.updateThumbnailButtons}>
        <button className="btnBase"
          disabled={isLoading || !imagePreview}
          onClick={() => {
            updateThumbnailHandler(
              NODEURL,
              localStorage.getItem('token'),
              inputData,
            );
          }}
        >
          Update
        </button>
      </div>

    </div>
  )


  return (<Fragment>
      <div>
        Update Thumbnail Form
        {updateTHumbnailBody}
        {isLoading && ( <Loader />)}
      </div>

    </Fragment>
  );
}

export default UpdateThumbnail;
