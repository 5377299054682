import React from 'react';

import { useStore } from '../../../../hook-store/store';

import classes from './ToggleNavButton.module.css';

const ToggleNavButton = props => {

  const [store, dispatch] = useStore();
  // console.log('store in ToggleNavButton', store);
  const { showToggleNav } = store.uiStore;
  
  const { 
    pageNotifyNewNotfiyNum,
  } = store.pageNotificationStore;

  return (
    <div className={classes.mobileToggle} 
      onClick={() => { dispatch('SET_SHOWTOGGLENAV', !showToggleNav); }}
    >
        <span className={classes.mobileToggle__bar} />
        <span className={classes.mobileToggle__bar} />
        <span className={classes.mobileToggle__bar} />

        {pageNotifyNewNotfiyNum > 0 && (
          <span className={classes.toggleNavButtonNotifyNum}>
            {pageNotifyNewNotfiyNum}
          </span>
        )}
    </div>
  )
};

export default ToggleNavButton;
