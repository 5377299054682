import { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';

import Loader from '../../Loader/Loader';

import { useStore } from "../../../hook-store/store";

import { generateBase64FromImage } from "../../../utils/ad-host/file";
import { adHostFilesUpload } from '../../../utils/ad-host/file-upload';

import { NODEURL } from "../../../App";

import classes from './CreateVideoWithAd.module.css';

function CreateVideoWithAd(props) {
  const { setShowVideoWithAdCreate } = props;

  const [t] = useTranslation('translation');

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;
  const adHostDataList = store.adHostStore.adHostDataList;

  const [urlData, setUrlData] = useState();
  const [hostInput, setHostInput] = useState('');
  const [adTypeInput, setAdTypeInput] = useState('videoWithAd');
  // const [adPlaceIdInput, setAdPlaceIdInput] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [videoPreview, setVideoPreview] = useState();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputData = {
    host: hostInput,
    adType: adTypeInput,
    selectedFiles: selectedFiles,
    // adPlaceId: adPlaceIdInput,
  };

  const isValidInputs = () => {
    if (hostInput && adTypeInput && selectedFiles.length > 0) {
      return true;
    }
    return false;
  }

  // console.log('iputData', inputData);

  // useEffect(() => {
  //   if (isAuth) {
  //     getUserAdHostDataListHandler(
  //       NODEURL,
  //       localStorage.getItem('token')
  //     );
  //   }

  // }, [isAuth]);

  const showConfirmHandler = (value) => {
    setShowConfirm(value);
  }

  const urlInputChangeHandler = (event) => {
    const isValidUrl = isValidHttpUrl(event.target.value);

    if (isValidUrl) {
      const inputUrl = new URL(event.target.value);
      // const host = inputUrl.host;
      // console.log(inputUrl);
      setHostInput(inputUrl.host);
      setUrlData(inputUrl);
    }
  };


  const adTypeInputChangeHandler = (event) => {
    setAdTypeInput(event.target.value);
  };

  // const adPlaceIdInputChangeHandler = (event) => {
  //   setAdPlaceIdInput(event.target.value);
  // };


  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const selectFileHndler = async (event) => {
    try {
      console.log(event.target.files);
      setSelectedFiles(event.target.files);
  
      const preview = await generateBase64FromImage(event.target.files[0]);
  
      setVideoPreview(preview);

    } catch(err) {
      console.log(err);
    }
    
  };



  const createVideoWithAdDataHandler = async (url, token, inputData) => {
    try {
      setIsLoading(true);

      const result = await adHostFilesUpload(
        url,
        token,
        inputData.selectedFiles,
        inputData.host,
        inputData.adType,
        dispatch,
      );
      
      console.log(result, result.data);

      if (result.data && result.data.data) {

        const dbAdHostData = result.data.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);
        const addedList = [dbAdHostData].concat(adHostDataList);
        dispatch('SET_ADHOST_DATALIST', addedList);

        setShowVideoWithAdCreate(false);
        setIsLoading(false);

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'Ad host data created',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);

        return result.data;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }

    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host data creation failed',
        message: '',
      }
    );
      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };


  return (<Fragment>
      {/* <div>create-video-with-ad-component</div> */}
      {!showConfirm && (
        <div>
          <div className={classes.createAdHostTitle}>
            {t('createAdHost10', 'Video with Ad Creation Form')}
          </div>

          <div className={classes.createAdHostInput}>
            <label>
              Site URL
            </label>
            <div>
              <input className="inputBase"
                type='text' 
                onChange={urlInputChangeHandler}
                placeholder={'example: https://site.example.com'}
                // autoFocus
              />
            </div>
          </div>
          <div className={classes.createAdHostInput}>
            <strong>{urlData && urlData.origin}</strong>
          </div>

          <div className={classes.createAdHostInput}>
            <label>
            {t('createAdHost11', 'Select a video file')} (mp4 or webm)
            </label>
            <div className={classes.createAdHostFileInput}>
              <input 
                type="file"
                accept="video/webm,video/mp4"
                onChange={selectFileHndler}
              />
            </div>
            {videoPreview && (
              <div>
                <video 
                  className={classes.createAdHostPreviewVideo}
                  src={videoPreview}
                  controls
                />
              </div>
            )}
          </div>

          {/* <div className={classes.createAdHostInput}>
            <label>adType-input</label>
            <div>
              <select className="selectBase"
                value={adTypeInput}
                onChange={adTypeInputChangeHandler}
              > 
                <option value={''}>
                  select-type
                </option>
                {['videoWithAd'].map(size => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
          <div className={classes.createAdHostAction}>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                showConfirmHandler(true);
              }}
            >
              {t('createAdHost06', 'Create')}
            </button>
          </div>
        </div>
      )}

      {showConfirm && (
        <div>
          <div className={classes.createAdHostTitle}>
          {t('createAdHost07', 'Please Confirm Your Inputs')}
          </div>

          {urlData && (
            <div className={classes.createAdHostInput}>
              Site URL: <strong>{urlData.origin}</strong>
              <div> 
                host: {hostInput} (host value is used to store data)
              </div>
            </div>
          )}

          <div>
            <div>{t('createAdHost12', 'Selected video')}</div>
            <video 
              className={classes.createAdHostPreviewVideo}
              src={videoPreview}
              controls
            />
          </div>

          <div className={classes.createAdHostInput}>
          {t('createAdHost08', 'ad type')}: 
            {' '}
            {adTypeInput && adTypeInput === 'videoWithAd' && (
              <strong>{t('createAdHost13', 'Video with Ad')}</strong>
            )}
          </div>

          <div className={classes.createAdHostAction}>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                showConfirmHandler(false);
              }}
            >
              {t('createAdHost09', 'Go back')}
            </button>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                // createAdHostDataHandler(
                //   NODEURL,
                //   localStorage.getItem('token'),
                //   inputData,
                // );
                createVideoWithAdDataHandler(
                  NODEURL,
                  localStorage.getItem('token'),
                  inputData,
                );
              }}
            >
              {t('createAdHost06', 'Create')}
            </button>
          </div>
        </div>
      )}

      {/* {isLoading && <Loader />} */}
      {isLoading && (
        <div className="centerLoader">
          <Loader />
        </div>
      )}


    </Fragment>
  );
}

export default CreateVideoWithAd;
