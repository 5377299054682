import { useEffect, useState, Fragment } from "react";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { useStore } from '../../../hook-store/store';

import classes from './AdminUserSearch.module.css';

const searchBoxStyle = {
    height: "44px",
    height: "2rem",
    border: "1px solid #dfe1e5",
    // border: "1px solid gray",
    // borderRadius: "24px",
    borderRadius: "6px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    // fontSize: "16px",
    fontSize: "1rem",
    fontFamily: "Arial",
    iconColor: "grey",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    // clearIconMargin: '3px 14px 0 0',
    clearIconMargin: '3px 5px 0 0',
    // searchIconMargin: '0 0 0 16px',
    searchIconMargin: '0 0 0 5px',
};

function AdminUserSearch(props) {
  // console.log('ReactSearchAutoComp.js-props', props);
  const { } = props;

  const [store, dispatch] = useStore();

  const { usersForAdmin } = store.adminStore;
  // console.log(store);
  const list = usersForAdmin.map((element, index) => {
    return {
      ...element,
      id: index,
      // adPlaceId: element.adPlaceId,
      // host: element.host,
      // adType: element.adType,
      // createdAt: element.createdAt,
    }
  });

  // console.log(list);

  // const items = [
  //   {
  //     id: 0,
  //     name: 'Cobol',
  //     type: 'co',
  //   },
  //   {
  //     id: 1,
  //     name: 'JavaScript',
  //     type: 'js'
  //   },
  //   {
  //     id: 2,
  //     name: 'Basic',
  //     type: 'ba',
  //   },
  //   {
  //     id: 3,
  //     name: 'PHP',
  //     type: 'ph',
  //   },
  //   {
  //     id: 4,
  //     name: 'Java',
  //     type: 'jav',
  //   }
  // ];

  // const items = listForSuggest;
  // const [adHostData, setAdHostData] = useState();

  useEffect(() => {
  }, []);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    // console.log(string, results)
    if (!string) {
      // setSearchSelectedItem(null);
      // dispatch('SET_SEARCH_SELECTEDITEM', null);
    }
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log('selected item: ', item);
    // setSearchSelectedItem(item);
    // dispatch('SET_SEARCH_SELECTEDITEM', item);
    dispatch('SET_ADMIN_SELECTEDUSER', item);
  }

  const handleOnFocus = () => {
    console.log('Focused')
    // console.log(items);
  }

  const handleOnClear = () => {
    console.log('Cleared')
    // console.log(items);
    // setSearchSelectedItem(null);
    // dispatch('SET_SEARCH_SELECTEDITEM', null);
  }

  const formatResult = (item) => {
    return (
      <>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
        {/* <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span> */}
        {/* <span style={{ display: 'block', textAlign: 'left' }}>adPlaceId: {item.adPlaceId}</span> */}
        <span style={{ display: 'block', textAlign: 'left',paddingBottom: "0.25rem" }}>userId: <strong>{item.userId}</strong></span>
        <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem" }}>email: <strong>{item.email}</strong></span>
        {/* <span style={{ display: 'block', textAlign: 'left',paddingBottom: "0.5rem" }}>type: <strong>{item.adType}</strong></span> */}
      </>
    )
  }


  return (
    <Fragment>
        <div className={classes.adHostSearch}
          // style={{ width: 400 }}
        >
          <ReactSearchAutocomplete
            // items={items}
            items={list}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            // autoFocus
            formatResult={formatResult}

            fuseOptions={{ keys: ["userId", "email"] }}
            resultStringKeyName="id"

            placeholder="search userId, email"
            styling={searchBoxStyle}
          />
        </div>
    </Fragment>
  );
}

export default AdminUserSearch;
