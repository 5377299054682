import { Fragment } from "react";

import PayOutResultList from "../../components/PayOutResult/PayOutResultList";

function PayOutResultPage(props) {

  return (
    <Fragment>
      <PayOutResultList />
    </Fragment>
  );
}

export default PayOutResultPage;
