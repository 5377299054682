import { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from '../../Backdrop/Backdrop';
import Loader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import CreateAdHost from "../CreateAdHost/CreateAdHost";
import CreateVideoWithAd from "../CreateVideoWithAd/CreateVideoWithAd";
import UserAdHostListItem from "./UserAdHostListItem";
// import UserAdHostSearch from "./UserAdHostSearch";

import { useStore } from "../../../hook-store/store";
import { getVideoWithAdThumbnailUrls } from "../../../utils/ad-host/ad-host-util";

import { NODEURL } from "../../../App";


import classes from './UserAdHostList.module.css';


// import ReactSearchAutoComp from "../../ReactSearchAutoComp";

function UserAdHostList(props) {

  const [t] = useTranslation('translation');

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;
  // const adHostDataList = store.adHostStore.adHostDataList;
  // const searchSelectedItem = store.adHostStore.searchSelectedItem;
  const { 
    adHostDataList,
    searchSelectedItem,
    thumbnailUrlList,
    selectedAdHostData,
  } = store.adHostStore;
  // const [selectedAdHostData, setSelectedAdHostData] = useState();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showVideoWithAdCreate, setShowVideoWithAdCreate] = useState(false);
  // const [searchSelectedItem, setSearchSelectedItem] = useState();
  const [createSelect, setCreateSelect] = useState('');

  // const [thumbnailUrlList, setThumbnailUrlList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);


  const adSelectList = [
    {
      name: 'banner-ad',
      displayName: `${t('userAdHostList03', 'Banner Ad')}`,
    },
    {
      name: 'video-with-ad',
      displayName: `${t('userAdHostList04', 'Video with Ad')}`,
    },
  ];

  useEffect(() => {
    if (isAuth && adHostDataList.length === 0) {
      getUserAdHostDataListHandler(
        NODEURL,
        localStorage.getItem('token')
      );
    }

  }, [isAuth]);

  // useEffect(() => {
  //   if (adHostDataList.length > 0) {
  //     const isVideoWithAd = adHostDataList.find(ele => {
  //       return ele.adType === 'videoWithAd';
  //     });

  //     if (isVideoWithAd) {
  //       getThumbnailUrlsHandler();
  //     }
  //   }
  // },[adHostDataList]);
  
  // useEffect(() => {
  //   console.log('searchSelectedItem', searchSelectedItem);
  // },[searchSelectedItem]);


  const showCreateModalHandler = (value) => {
    if (!isLoading) {
      setShowCreateModal(value);
    }
  };


  const deleteAdHostDataHandler = async (url, token, adType, adPlaceId) => {
    try {
      setIsLoading(true);
      // const adType = adType;
      // const adPlaceId = adPlaceId;

      let deleteUrl = `${url}/user-ad-host?adType=${adType}&adPlaceId=${adPlaceId}`;

      if (adType === 'videoWithAd') {
        deleteUrl = `${url}/video-with-ad?adType=${adType}&adPlaceId=${adPlaceId}`;
      }

      const response = await fetch(
        // `${url}/user-ad-host?adType=${adType}&adPlaceId=${adPlaceId}`,
        deleteUrl,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(inputData),
        }
      );

      const resData = await response.json();

      if (response.ok) {

        // eslint-disable-next-line no-console
        console.log(resData, response);

        const dbAdHostData = resData.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);
        

        const deletedList = adHostDataList.filter(element => {
          return element.adPlaceId !== adPlaceId;
        });

        dispatch('SET_ADHOST_DATALIST', deletedList);

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'Ad host data deleted',
          message: '',
        }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);

        setIsLoading(false);

        return resData;

        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong", resData, response);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host data deletion failed',
        message: '',
      });

      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };

  const getUserAdHostDataListHandler = async (url, token) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${url}/user-ad-host/list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(inputData),
        }
      );

      const resData = await response.json();

      console.log(resData, response);

      if (response.ok) {

        const dbAdHostDataList = resData.data;
        console.log('dbAdHostDataList', dbAdHostDataList);
        // setAdHostDataList(dbAdHostDataList);
        dispatch('SET_ADHOST_DATALIST', dbAdHostDataList);
        // setAdHostData(dbAdHostData);
        
        setIsLoading(false);
        return resData;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setIsLoading(false);
      // reject(err);
    }
  };


  const getThumbnailUrlsHandler = async () => {
    try {
      const resData = await getVideoWithAdThumbnailUrls(
        NODEURL,
        localStorage.getItem('token'),
      )

      if (resData && resData.data) {
        console.log(resData);

        if (resData.data.length > 0) {
          // setThumbnailUrlList(resData.data);
          dispatch('SET_THUMBNAILURLLIST', resData.data)
        }
      }

    } catch(err) {
      console.log(err);
    }
  } 



  let userAdHostDataListBody = (<div>no adhost data</div>);

  if (adHostDataList.length > 0) {
    userAdHostDataListBody = (
      <ul>
        {adHostDataList.map(data => {
          return (
            <li key={data._id}>
              <UserAdHostListItem 
                data={data}
                deleteAdHostDataHandler={deleteAdHostDataHandler}
                // selectedAdHostData={selectedAdHostData}
                // setSelectedAdHostData={setSelectedAdHostData}
                thumbnailUrlList={thumbnailUrlList}
                isLoading={isLoading}
              />
            </li>
          )
        })}
      </ul>
    );
  }

  let searchResultBody;

  if (searchSelectedItem) {
    searchResultBody = (
      <div>
        {/* <div>search-select-item</div> */}
        <ul>
          {[searchSelectedItem].map(data => {
            return (
              <li key={data.id}>
                <UserAdHostListItem 
                  data={data}
                  deleteAdHostDataHandler={deleteAdHostDataHandler}
                  // selectedAdHostData={selectedAdHostData}
                  // setSelectedAdHostData={setSelectedAdHostData}
                  thumbnailUrlList={thumbnailUrlList}
                  isLoading={isLoading}
                />
              </li>
            )
          })}
        </ul>
      </div>
    );
  }



  return (
    <Fragment>
      {/* <button onClick={() => {
        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'notify',
          message: '',
        })
      }}>
        show-notify-test
      </button> */}

      <div className={classes.adhostListContainer}>
        <div className={classes.adhostListCreate}>
          <label>
            <strong>
              {t('userAdHostList01', 'Create New Ad Host Data for Your Page')}
            </strong>
          </label>
            <div>
              <select className="selectBase"
                value={createSelect}
                onChange={(event) => {
                  // console.log(event.target.value);
                  setCreateSelect(event.target.value);
                  
                  if (event.target.value === 'banner-ad') {
                    showCreateModalHandler(true);
                  }
                  if (event.target.value === 'video-with-ad') {
                    setShowVideoWithAdCreate(true);
                  }
                }}
              > 
                <option value={''}>
                  {t('userAdHostList02', 'select type')}
                </option>
                {adSelectList.map((select, index) => (
                  <option key={select.name} value={select.name}>
                    {select.displayName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        {/* <div className={classes.adhostListCreate}>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => { showCreateModalHandler(true); }}
          >
            show-adhost-create-modal
          </button>
        </div>

        <div className={classes.adhostListCreate}>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => { setShowVideoWithAdCreate(true); }}
          >
            show-video-with-ad-create-modal
          </button>
        </div> */}

        {/* <UserAdHostSearch
          setSearchSelectedItem={setSearchSelectedItem}
        /> */}

        {isLoading && (
          <div>
            <Backdrop />
            <span className="centerLoader">
              <Loader />
            </span>
          </div>
        )}
        {searchResultBody}
        {/* <div>adhost-data-list</div> */}
        {userAdHostDataListBody}
      </div>

      {showCreateModal && (
        <div>
          <Backdrop 
            onCancel={() => {
              showCreateModalHandler(false); 
              setCreateSelect('');
            }}
          />
          <Modal 
            onCancel={() => {
              showCreateModalHandler(false); 
              setCreateSelect('');
            }}
            isLoading={isLoading} 
          >
            <CreateAdHost
              showCreateModalHandler={showCreateModalHandler}
            />
          </Modal>
        </div>
      )}

      {showVideoWithAdCreate && (
        <div>
          <Backdrop 
            onCancel={() => {
              setShowVideoWithAdCreate(false); 
              setCreateSelect('');
            }}
          />
          <Modal 
            onCancel={() => {
              setShowVideoWithAdCreate(false); 
              setCreateSelect('');
            }}
            isLoading={isLoading} 
          >
            <CreateVideoWithAd
              setShowVideoWithAdCreate={setShowVideoWithAdCreate}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

export default UserAdHostList;
