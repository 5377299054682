import { Fragment } from 'react';

import MainNav from '../../Navigation/MainNav/MainNav';

import { useStore } from '../../../hook-store/store';

import classes from './Header.module.css';

function Header(props) {
  // console.log('Layout.js-props', props);

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);

  // if (!store.roomConfig) {
  //   return <GetConfig />
  // }

  return (
    <Fragment>
      <div className={classes.header}>
        header-content, nav, ...
        <MainNav />
      </div>
    </Fragment>
  );
}

export default Header;
