import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

// import 'tui-grid/dist/tui-grid.css';
import Grid from '@toast-ui/react-grid';
import './tui-grid.css';
import TuiGrid from 'tui-grid';

import AdHostResutSearch from './AdHostResultSearch';
import { useStore } from '../../hook-store/store';

import classes from './AdHostResultTable.module.css';


TuiGrid.applyTheme('striped');




function AdHostResutTable() {

  const [t] = useTranslation('translation');

  // const chartRef = useRef(null);
  const gridRef = React.createRef();

  const [store, dispatch] = useStore();
  const { 
    listForTable, 
    resultSearchSelectId,
    isResultSearchResults,
    totalResult,
    duration,
  } = store.adHostResultStore;
  const { showToggleNav } = store.uiStore;


  //// initialize duration and selected id when leave page
  useEffect(() => {

    return () => {
      dispatch('SET_DURATION', 10);
      dispatch('SET_RESULTSEARCHSELECTID', '');
    }
  },[]);




  let columnForTable = [
    // { name: 'id', header: 'ID', sortingType: 'desc', sortable: true},
    { name: 'date', header: `${t('adReport01', 'date')}`, sortingType: 'desc', sortable: true, whiteSpace: 'normal', },
    { name: 'displayNum', header: `${t('adReport02', 'display')}`, sortable: true, whiteSpace: 'normal', },
    { name: 'visitNum', header: `${t('adReport03', 'visit or view')}`, sortable: true, whiteSpace: 'normal', },
    { name: 'vRate', header: `${t('adReport04', 'visit or view rate')}`, sortable: true, whiteSpace: 'normal', },
    { name: 'payOut', header: `${t('adReport05', 'payout')}`, sortable: true, whiteSpace: 'normal', },
  ];

  if (window.innerWidth < 480) {
    columnForTable = [
      // { name: 'id', header: 'ID', sortingType: 'desc', sortable: true},
      { name: 'date', header: `${t('adReport01', 'date')}`, sortingType: 'desc', sortable: true, whiteSpace: 'normal', },
      { name: 'displayNum', header: `${t('adReport02', 'display')}`, whiteSpace: 'normal', },
      { name: 'visitNum', header: `${t('adReport03', 'visit or view')}`, whiteSpace: 'normal',},
      // { name: 'vRate', header: 'visit rate' },
      { name: 'payOut', header: `${t('adReport05', 'payout')}`, whiteSpace: 'normal', },
    ];
  }

  // useEffect(() => {
  //   const gridContainerEl = document.getElementsByClassName('tui-grid-container');
  //   if (gridContainerEl[0]) {
  //     gridContainerEl[0].style.maxWidth = '90%';
  //     gridContainerEl[0].style.width = '90%';
  //     console.log(gridContainerEl);
  //   }
  // },[listForTable]);


  // const handleClickButton = () => {
  //   console.log('type:', chartRef.current.getInstance().showSeriesDataLabel());
  // };

  const handleAppendRow = () => {
    gridRef.current.getInstance().appendRow({});
  };

  const handleClickButton = () => {
    gridRef.current.getRootElement().classList.add('my-grid-root');
    console.log(gridRef.current.getRootElement());
  };

  const handleClick = (event) => {
    console.log('click!!, event', event);
  }

  const handleExport = (event) => {
    const grid = gridRef.current.getInstance();

    let fileName = 'adhost-report';

    if (resultSearchSelectId) {
      fileName = `adhost-report-${resultSearchSelectId}`;
    }

    const exportOptions = {
      // includeHiddenColumns: true,
      // onlySelected: true,
      fileName: fileName,
    }

    grid.export('csv', exportOptions);
    // grid.export('csv');
  }


  return (
    <div style={showToggleNav ? { display:'none'} : null}>
      {/* <div className={classes.adHostResultTablePayOutAbout}>
        <Link to="/pay-out-about">about-payout</Link>
      </div> */}
      
      <div>
        <AdHostResutSearch/>
      </div>
 
      <select className={classes.adHostResultTableSelect}
        // value={pageSize}
        onChange={e => {
          dispatch('SET_DURATION', Number(e.target.value));
          // setSelectedAdElement(e.target.value);
        }}
      >
        <option value={10}>
          {/* {t('adReport.text05', 'Select Duration')} */}
          {t('adReport06', 'Duration')}
        </option>
        {[10, 30, 90, 365].map((duration, index) => (
          <option key={index} value={duration}>
            {duration} {t('adReport07', 'days')}
            {/* {t('general.text47', 'days')} */}
          </option>
        ))}
        {resultSearchSelectId && (
          <option value={0}>
            {t('adReport08', 'from start')}
            {/* {t('adReport.text12', 'from start date')} */}
          </option>
        )}
      </select>

      <div className={classes.adHostResultTableSelect}>
        {resultSearchSelectId && (
          <div> 
            adPlaceId: <strong>{resultSearchSelectId}</strong>
          </div>
        )}
        {duration > 0 && (
          <div>
            {t('adReport06', 'Duration')}: <strong>{duration} days</strong>
          </div>
        )}
      </div>

      <div className={classes.adHostResultTableSummary}>
        <span>
          {t('adReport09', 'total display')}: {totalResult.displayNum.toFixed()}
        </span>
        <span>
          {t('adReport10', 'toatal visit or view')}: {totalResult.visitNum.toFixed()}
        </span>
        {totalResult.visitNum/totalResult.displayNum >= 0 && (
          <span>
            {t('adReport11', 'visit or view rate')}: {(totalResult.visitNum/totalResult.displayNum*100).toFixed(1)}%
          </span>
        )}
        <span>
          {t('adReport12', 'total payout')}: {totalResult.payOut.toFixed(2)}
        </span>
      </div>


      {listForTable.length > 0 && (
        <div
          style={isResultSearchResults ? { display:'none'} : null}
        >
        <Grid
          ref={gridRef}
          // data={dataList}
          data={listForTable}
          columns={columnForTable}
          // rowHeight={25}
          // bodyHeight={100}
          rowHeight={20}
          bodyHeight={400}
          heightResizable={true}
          // rowHeaders={['rowNum']}
          // oneTimeBindingProps={['data', 'columns']}
          onClick={handleClick}
          pageOptions={{
            useClient: true,
            perPage: 100
          }}
          // scrollY={false}
          scrollY={true}
          scrollX={true}
          // summary={{
          //   height: 40,
          //   position: 'bottom', // or 'top'
          //   columnContent: {
          //     num1: {
          //       template: function(valueMap) {
          //         return `MAX: ${valueMap.max}<br>MIN: ${valueMap.min}<br>row-num: ${valueMap.cnt}`;
          //       }
          //     },
          //     num2: {
          //       template: function(valueMap) {
          //         return `TOTAL: ${valueMap.sum} <br>AVG: ${valueMap.avg.toFixed(2)}`;
          //       }
          //     }
          //   }
          // }}
        />
        </div>
      )}

      <div className={classes.adHostResultExportButton}>
        <button className='btnSmallBase' 
          onClick={handleExport}
        >
          CSV file download
        </button>
      </div>
      {/* <button onClick={handleAppendRow}>Append Row</button>
      <button onClick={handleClickButton}>Click!</button>
      <button onClick={handleExport}>Click-export</button> */}

    </div>
  );
}

export default AdHostResutTable;