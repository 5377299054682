import { Fragment, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Backdrop from '../../Backdrop/Backdrop';
import ToggleNavButton from '../../Navigation/ToggleNav/ToggleNavButton/ToggleNavButton';
import ToggleNav from '../../Navigation/ToggleNav/ToggleNav/ToggleNav';

import UserAdHostSearch from '../../AdHost/UserAdHostList/UserAdHostSearch';

import { useStore } from '../../../hook-store/store';

import classes from './MainNav.module.css';

function MainNav(props) {
  // console.log('Layout.js-props', props);

  const location = useLocation();

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);
  const { showToggleNav, isAuth } = store.uiStore;
  // const gLoading = store.uiStore.gLoading;

  // useEffect(() => {
  //   // console.log(location);
  //   // ga('send', 'pageview');
  // }, [location]);

  let userAdHostPath = false;

  if (isAuth && 
      (location.pathname === '/ad-host-list' || location.pathname === '/ad-host-list') 
  ) {
    userAdHostPath = true;
  }

  return (
    <Fragment>
      <div className={classes.mainNav}>

        <div className={classes.mainNavLogo}>
          <Link to="/">
            <span className={classes.mainNavLogoContent}>ANAH</span>
          </Link>
        </div>

        {userAdHostPath && (
          <UserAdHostSearch />
        )}

        <ToggleNav />
        <ToggleNavButton /> 
        {showToggleNav && (
          <Backdrop 
            zIndex={'190'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          //  backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              dispatch('SET_SHOWTOGGLENAV', false); 
            }}
          />
        )}
        
      </div>
    </Fragment>
  );
}

export default MainNav;
