// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import _ from 'lodash';
// import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";
// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";

import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

// import Backdrop from "../Backdrop/Backdrop";
import PageNotificationItem from "./PageNotificationItem";
// import SmallModal from "../Modal/SmallModal";
// import TransBackdrop from "../Backdrop/TransBackdrop";

import { useStore } from "../../hook-store/store";
import { 
  getPageNotification,
  createTestPageNotification,
  updatePageNotificationLastOpenTime,
  updatePageNotificationReadState,
 } from "../../utils/page-notification/page-notification";

// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
// import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { NODEURL } from "../../App";
// import { marks } from "../../images/icon/marks";
import './PageNotification.css';
// import classes from './AdminAdElements.module.css';

const PageNotification = (props) => {
  const { isAuth } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    userPageNotification,
    pageNotifyNewNotfiyNum,
    pageNotifyLocalLastGetTime,
  } = store.pageNotificationStore;
  // console.log('store in AdminAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  // const [newNotfiyNum, setNewNotifyNum] = useState(0);
  // const [localLastGetTime, setLocalLastGetTime] = useState(0);

  // const [suggestId, setSuggestId] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (isAuth) {
    }
    getPageNotificationHandler();

    updateLastOpenTimeHandler();

    dispatch('SET_PAGENOTIFY_LOCALLASTOPENTIME', Date.now());

  },[isAuth]);

  // useEffect(() => {
  //   if (userPageNotification) {
  //       const notifyList = userPageNotification.pageNotificationList;
        
  //       const newNotifyList = notifyList.filter(element => {
  //         return element.creationTime > userPageNotification.lastOpenTime;
  //       });
    
    
  //       if (newNotifyList.length > 0) {
  //         // setNewNotifyNum(newNotifyList.length);
  //         dispatch('SET_PAGENOTIFY_NEWNOTIFYNUM', newNotifyList.length);
  //       }
  //   }
  // },[userPageNotification]);

  // const showNotificationHandler = () => {
  //   if (!showNotification) {
  //     updateLastOpenTimeHandler();

  //     if (pageNotifyLocalLastGetTime < (Date.now() - 1000*60*5) ) {
  //         getPageNotificationHandler();
  //       }
  //   }

  //   // setNewNotifyNum(0);
  //   dispatch('SET_PAGENOTIFY_NEWNOTIFYNUM', 0);

  //   setShowNotification(!showNotification);
  // };


  const getPageNotificationHandler = async () => {
    try {
      setIsLoading(true);
      const resData = await getPageNotification(NODEURL, localStorage.getItem('token'));
      // console.log(adList);

      console.log(resData);
      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);

        // setLocalLastGetTime(Date.now());
        dispatch('SET_PAGENOTIFY_LOCALLASTGETNUM', Date.now());

      } else {
        dispatch('SET_USERPAGENOTIFICATION', null);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const createTestNotifyHandler = async () => {
    try {
      setIsLoading(true);
      const resData = await createTestPageNotification(NODEURL, localStorage.getItem('token'));
      // console.log(adList);

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const updateLastOpenTimeHandler = async () => {
    try {
      // setIsLoading(true);
      const resData = await updatePageNotificationLastOpenTime(
        NODEURL, 
        localStorage.getItem('token')
      );

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }
      // console.log(adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const updateReadStateHandler = async (id, readState) => {
    try {
      setIsLoading(true);
      const resData = await updatePageNotificationReadState(
        NODEURL, 
        localStorage.getItem('token'),
        id,
        readState,
      );

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }
      // console.log(adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };



  let pageNotificationListBody;

  if (showNotification && userPageNotification && userPageNotification.pageNotificationList.length > 0) {
    
    const displayList = _.orderBy(userPageNotification.pageNotificationList, 'creationTime', 'desc');
    
    pageNotificationListBody = (
      <div>
        <ul className="pageNotificationList">
            {displayList.map(notify => {
              return (
                <li key={notify.id}>
                  <PageNotificationItem 
                    notify={notify}
                    // showNotificationHandler={showNotificationHandler}
                  />
                </li>
              );
            })}
          </ul>
        {/* <TransBackdrop
          onClick={showNotificationHandler}
        />
        <SmallModal style="pageNotificationModal">
          {isLoading && (<Loader />)}

          <ul className="pageNotificationList">
            {displayList.map(notify => {
              return (
                <li key={notify.id}>
                  <PageNotificationItem 
                    notify={notify}
                    showNotificationHandler={showNotificationHandler}
                  />
                </li>
              );
            })}
          </ul>
        </SmallModal> */}
      </div>
    );
  }


  // if (!isAuth || !userPageNotification) {
  //   return null;
  // }

  return (
    <Fragment>
      <div className="pageNotification">

        {/* <div>page-notification</div> */}
        {/* <button onClick={createTestNotifyHandler}>
          create-test-page-notify
        </button> */}

        {/* <span className="pageNotificationTitle"
          onClick={showNotificationHandler}
        >
          <span >Notify</span>
          {pageNotifyNewNotfiyNum > 0 && (
            <span className="pageNotificationTitleNum">
              {pageNotifyNewNotfiyNum}
            </span>
          )}
        </span> */}
        {/* {userPageNotification && (
          <div>last-open-time: {new Date(userPageNotification.lastOpenTime).toLocaleString()}</div>
        )}
        <div>new-notify-num: {newNotfiyNum}</div> */}
        <div>{pageNotificationListBody}</div>
      </div>
    </Fragment>
  );
};

export default PageNotification;
