import { useEffect, useState, Fragment } from "react";

import Backdrop from "../../../../Backdrop/Backdrop";
import Modal from "../../../../Modal/Modal";
import Loader from "../../../../Loader/Loader";

import { useStore } from "../../../../../hook-store/store";

import {
  getVideoWithAdBannerAds,
  deleteVideoWithAdBanner,
} from "../../../../../utils/ad-host/video-with-ad-banner-util";

import { toHHMMSS } from "../../../../../utils/ad-host/video-with-ad-util";

import { NODEURL } from "../../../../../App";

import classes from "./VideoWithAdBannerItem.module.css";

function VideoWithAdBannerItem(props) {
  const {
    adHostData,
    bAd,
    setBannerAds,
    // setAdHostData,
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  // const isAuth = store.uiStore.isAuth;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const deleteBannerHandler = async (adPlaceId, adType, start, end) => {
    try {
      setIsLoading(true);

      const resData = await deleteVideoWithAdBanner(
        NODEURL,
        localStorage.getItem("token"),
        adPlaceId,
        adType,
        start,
        end
      );

      if (resData.data) {
        // setAdHostData(resData.data);
        setBannerAds(resData.data);

        dispatch("SHOW_NOTIFICATION", {
          status: "",
          title: "Ad host banner data deleted",
          message: "",
        });

        setTimeout(() => {
          dispatch("CLEAR_NOTIFICATION");
        }, 1000 * 5);

        setShowDeleteModal(false);
        setIsLoading(false);

      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch("SHOW_NOTIFICATION", {
        status: "",
        title: "Ad host banner data deletion failed",
        message: "",
      });

      setIsLoading(false);
    }
  };


  
  let videoWithAdBannerItemBody;

  if (bAd && adHostData) {
    videoWithAdBannerItemBody = (
      <div className={classes.videoWithAdBannerItem}>
        <span>
          start-end: {toHHMMSS(bAd.start)} - {toHHMMSS(bAd.end)}
        </span>
        <button
          className="btnSmallBase"
          disabled={isLoading}
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          Delete
        </button>

      </div>
    );
  }

  return (
    <Fragment>
      {isLoading && <Loader />}
      <div>{videoWithAdBannerItemBody}</div>

      {showDeleteModal && (
          <div>
            <Backdrop 
              onCancel={() => { setShowDeleteModal(false); }}
            />
            <Modal
              onCancel={() => { setShowDeleteModal(false); }}
            >
              <div>Delete?</div>
              <div className={classes.videoWithAdBannerItemButtons}>
                <button
                  className="btnBase"
                  disabled={isLoading}
                  onClick={() => { setShowDeleteModal(false); }}
                >
                  Cancel
                </button>
                <button
                  className="btnBase"
                  disabled={isLoading}
                  onClick={() => {
                    deleteBannerHandler(
                      adHostData.adPlaceId,
                      adHostData.adType,
                      bAd.start,
                      bAd.end
                    );
                  }}
                
                >
                  Delete
                </button>
              </div>

              {isLoading && (<Loader />)}

            </Modal>
          </div>
        )}
    </Fragment>
  );
}

export default VideoWithAdBannerItem;
