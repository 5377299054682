
import { useEffect, useState, Fragment } from "react";

import Backdrop from "../../../../Backdrop/Backdrop";
import Modal from "../../../../Modal/Modal";
import Loader from "../../../../Loader/Loader";
// import VideoWithAdVideos from "./VideoWithAdVideos";
import UpdateThumbnail from "./UpdateThumbnail";
// import RefTest from "../../../vidtest/RefTest";

import { useStore } from "../../../../../hook-store/store";

// import { getAdHostData } from "../../../../utils/ad-host/ad-host-util";

// import { NODEURL } from "../../../App";
import { NODEURL } from "../../../../../App";

// import Black2s from './black-bg-2s.mp4';
import classes from './ThumbnailDisplay.module.css';

function ThumbnailDisplay(props) {
  const { 
    adHostData,
    setAdHostData,
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  // const isAuth = store.uiStore.isAuth;

  // const [adHostData, setAdHostData] = useState();
  const [showThumbnailUpdate, setShowThumbnailUpdate] = useState(false);
  const [showDeleteThumbnail, setShowDeleteThumbnail] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const deleteThumbnailHandler = async (url, token, adType, adPlaceId) => {
    try {
      setIsLoading(true);
      // const adType = adType;
      // const adPlaceId = adPlaceId;
  
      const deleteUrl = `${url}/video-with-ad/thumbnail?adType=${adType}&adPlaceId=${adPlaceId}`;
      
      const response = await fetch(deleteUrl,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(inputData),
        }
      );
  
      const resData = await response.json();
  
      if (response.ok) {
  
        // eslint-disable-next-line no-console
        console.log(resData, response);
  
        const dbAdHostData = resData.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);
        
        if (dbAdHostData) {
          setAdHostData(dbAdHostData);
        }

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'Ad host thumbnail data deleted',
          message: '',
        }
        );
  
        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);
  
        setShowDeleteThumbnail(false);

        setIsLoading(false);
  
        return resData;
  
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong", resData, response);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host thumbnail data deletion failed',
        message: '',
      });
  
      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };



  let isUploadThumbnail = false;
  let uploadThumbnailBody;

  if (adHostData.thumbnailPath !== adHostData.defaultThumbnailPath) {
    isUploadThumbnail = true;
  }

  if (isUploadThumbnail) {
    uploadThumbnailBody = (
      <div>
        <img className={classes.thumbnailDisplayImage}
          src={adHostData.thumbnailUrl}
          alt='thumbnail'
        />
        <div className={classes.thumbnailDisplayButtons}>
          <button className="btnSmallBase"
            disabled={isLoading}
            onClick={ () => { setShowThumbnailUpdate(true); }}
          >
            Update
          </button>
          <button className="btnSmallBase"
            disabled={isLoading || !isUploadThumbnail}
            onClick={() => {
              setShowDeleteThumbnail(true);
            }}
          >
            Delete
          </button>
        </div>
  
        {showDeleteThumbnail && (
          <div>
            <Backdrop 
              onCancel={() => { setShowDeleteThumbnail(false); }}
            />
            <Modal
              onCancel={() => { setShowDeleteThumbnail(false); }}
              isLoading={isLoading} 
            >

                <div>
                  Delete?
                </div>
                <div className={classes.thumbnailDisplayButtons}>
                  <button className="btnBase"
                    disabled={isLoading || !isUploadThumbnail}
                    onClick={() => {
                      setShowDeleteThumbnail(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btnBase"
                    disabled={isLoading || !isUploadThumbnail}
                    onClick={() => {
                      deleteThumbnailHandler(
                        NODEURL,
                        localStorage.getItem('token'),
                        adHostData.adType,
                        adHostData.adPlaceId,
                      );
                    }}
                  >
                    Delete
                  </button>
                </div>
  
                {isLoading && (<Loader />)}
            </Modal>
          </div>
        )}
  
      </div>
    );
  }


  let thumbnailDisplayBody;

  if (adHostData) {
    thumbnailDisplayBody = (
      <div className={classes.thumbnailDisplay}>
        <section>
          <div className={classes.thumbnailDisplayTitle}>
            <strong>Thumbnail image</strong>
          </div>

          <div>
            {uploadThumbnailBody}
          </div>

          <div className={classes.thumbnailDisplayDefault}>
            <div>Default thumbnail</div>
            <img className={classes.thumbnailDisplayDefaultImage}
              src={adHostData.defaultThumbnailUrl}
              alt='thumbnail'
            />
          </div>
        </section>
        
        <section>
          {!isUploadThumbnail && (
            <button className="btnSmallBase"
              disabled={isLoading}
              onClick={ () => { setShowThumbnailUpdate(true); }}
            >
              Update
            </button>
          )}
          
          {showThumbnailUpdate && (
            <div>
              <Backdrop 
                onCancel={() => { setShowThumbnailUpdate(false); }}
              />
              <Modal
                onCancel={() => { setShowThumbnailUpdate(false); }}
                isLoading={isLoading} 
              >
                <UpdateThumbnail 
                  adPlaceId={adHostData.adPlaceId}
                  adType={adHostData.adType}
                  setAdHostData={setAdHostData}
                  setShowThumbnailUpdate={setShowThumbnailUpdate}
                />
              </Modal>
            </div>
          )}
        </section>

      </div>
    );
  }


  return (
    <Fragment>
      {thumbnailDisplayBody}
    </Fragment>
  );
}

export default ThumbnailDisplay;

