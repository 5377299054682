// import _ from 'lodash';

import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_VIDEOWITHADWIDTH: (state, payload) => {
      return {
        // ...state,
        videoWithAdStore: {
          ...state.videoWithAdStore,
          videoWithAdWidth: payload,
        }
      };
    },
    SET_VIDEOWITHADHEIGHT: (state, payload) => {
      return {
        // ...state,
        videoWithAdStore: {
          ...state.videoWithAdStore,
          videoWithAdHeight: payload,
        }
      };
    },
    SET_VIDEOMUTESTATE: (state, payload) => {
      return {
        // ...state,
        videoWithAdStore: {
          ...state.videoWithAdStore,
          videoMuteState: payload,
        }
      };
    },
    SET_ALLOWFULLSCREEN: (state, payload) => {
      return {
        // ...state,
        videoWithAdStore: {
          ...state.videoWithAdStore,
          allowFullScreen: payload,
        }
      };
    },
    SET_SHOWBORDER: (state, payload) => {
      return {
        // ...state,
        videoWithAdStore: {
          ...state.videoWithAdStore,
          showBorder: payload,
        }
      };
    },
  };

  initStore(actions, {
    videoWithAdStore: {
      videoWithAdWidth: 400,
      videoWithAdHeight: 200,
      videoMuteState: false,
      allowFullScreen: false,
      showBorder: true,
    }
  });
};

export default configureStore;
