export const adminGetUserAdHostPayOutAddress = async (url, token, userId) => {
  try {
    const response = await fetch(
      `${url}/admin-ad-host-pay-out/user-pay-out-address?userId=${userId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(inputData),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};