import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Loader from "../Loader/Loader";

import { useStore } from "../../hook-store/store";

import XlmLogo from '../../images/logos/xlm-logo.png';
import XrpLogo from '../../images/logos/xrp-logo.png';
import PolygonUSDTLogo from '../../images/logos/polygon-usdt-logo.webp';

import classes from './PayOutAbout.module.css';

function PayOutAbout(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  // const { userPayoutAddress } = store.payoutStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
  },[]);




  let payOutAboutBody;

  payOutAboutBody = (
    <div className={classes.payOutAbout}>
      <p className={classes.payOutAboutTitle}>
        <strong>
          
          {t('aboutPayOut01', 'About Payout')}
        </strong>
      </p>
      <p>
      {t('aboutPayOut02', 'Income from displyed and visited Ads in your web site will be paid when total payout amount reached xxx value at the end of month.')}
      </p>
      <p>
      {t('aboutPayOut03', 'For the purpose of global smooth and low cost payment, this site use cryptocurrency for the payment method.')}
      </p>
      <p>
      {t('aboutPayOut04', 'Currently supported currencies are')} 
        {' '}
        XRP <img src={XrpLogo} style={{height:"0.9rem"}}/> (Ripple), 
        {' '}
        XLM <img src={XlmLogo} style={{height:"0.9rem"}}/> (Stellar),
        {' '} 
        USDT <img src={PolygonUSDTLogo} style={{height:"0.9rem"}}/> (polygon network, part of Ethereum).
      </p>
      <p>
      {t('aboutPayOut05', 'To recieve payment, it is required to add wallet address of supported currencies in payout address page.')}
      </p>

      {isAuth && (
        <p>
          <Link to='/pay-out-address'>
          {t('aboutPayOut06', 'payout address page')}
          </Link>
        </p>
      )}
      
    </div>
  );
 
  return (
    <Fragment>
      {isLoading && (<Loader />)}
      <div>{payOutAboutBody}</div>

    </Fragment>
  );
}

export default PayOutAbout;
