import { useEffect, useState, Fragment } from "react";

// import Backdrop from '../../Backdrop/Backdrop';
// import Loader from "../../Loader/Loader";
// import Modal from "../../Modal/Modal";
// import CreateAdHost from "../CreateAdHost/CreateAdHost";
// import CreateVideoWithAd from "../CreateVideoWithAd/CreateVideoWithAd";
// import UserAdHostListItem from "./UserAdHostListItem";
// import UserAdHostSearch from "./UserAdHostSearch";

import { useStore } from "../../hook-store/store";
import { getVideoWithAdThumbnailUrls } from "../../utils/ad-host/ad-host-util";

import { NODEURL } from "../../App";

// import classes from './UserAdHostList.module.css';


// import ReactSearchAutoComp from "../../ReactSearchAutoComp";

function GetUserAdHostList(props) {

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;
  // const adHostDataList = store.adHostStore.adHostDataList;
  // const searchSelectedItem = store.adHostStore.searchSelectedItem;
  const { 
    adHostDataList,
    // searchSelectedItem,
    // thumbnailUrlList,
    // selectedAdHostData,
  } = store.adHostStore;
  // const [selectedAdHostData, setSelectedAdHostData] = useState();
  // const [showCreateModal, setShowCreateModal] = useState(false);
  // const [showVideoWithAdCreate, setShowVideoWithAdCreate] = useState(false);
  // const [searchSelectedItem, setSearchSelectedItem] = useState();
  // const [createSelect, setCreateSelect] = useState('');

  // const [thumbnailUrlList, setThumbnailUrlList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuth) {
      getUserAdHostDataListHandler(
        NODEURL,
        localStorage.getItem('token')
      );
    }

  }, [isAuth]);

  useEffect(() => {
    if (adHostDataList.length > 0) {
      const isVideoWithAd = adHostDataList.find(ele => {
        return ele.adType === 'videoWithAd';
      });

      if (isVideoWithAd) {
        getThumbnailUrlsHandler();
      }
    }
  },[adHostDataList]);
  // useEffect(() => {
  //   console.log('searchSelectedItem', searchSelectedItem);
  // },[searchSelectedItem]);




  const getUserAdHostDataListHandler = async (url, token) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${url}/user-ad-host/list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(inputData),
        }
      );

      const resData = await response.json();

      console.log(resData, response);

      if (response.ok) {

        const dbAdHostDataList = resData.data;
        console.log('dbAdHostDataList', dbAdHostDataList);
        // setAdHostDataList(dbAdHostDataList);
        dispatch('SET_ADHOST_DATALIST', dbAdHostDataList);
        // setAdHostData(dbAdHostData);
        
        setIsLoading(false);
        return resData;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setIsLoading(false);
      // reject(err);
    }
  };


  const getThumbnailUrlsHandler = async () => {
    try {
      const resData = await getVideoWithAdThumbnailUrls(
        NODEURL,
        localStorage.getItem('token'),
      )

      if (resData && resData.data) {
        console.log(resData);

        if (resData.data.length > 0) {
          // setThumbnailUrlList(resData.data);
          dispatch('SET_THUMBNAILURLLIST', resData.data)
        }
      }

    } catch(err) {
      console.log(err);
    }
  } 




  return (
    <Fragment>
    </Fragment>
  );
}

export default GetUserAdHostList;
