import React, { Fragment, useEffect, useState } from "react";

import { useStore } from "../../hook-store/store";

import { getUserData } from "../../utils/user/user";
import { NODEURL } from "../../App";

const GetUserData = (props) => {
  // const { } = props;

  const [store, dispatch] = useStore();

  const { isAuth } = store.uiStore;

  // const [userData, setUserData] = useState();

  useEffect(() => {
    if (isAuth) {
      getUserDataHandler(NODEURL, localStorage.getItem('token'));
    } else {
      dispatch('SET_USERDATA', null);
      dispatch('SET_ISADMIN', false);
    }
  }, [isAuth]);

  // useEffect(() => {
  //   if (!isAuth) {
  //     dispatch('SET_USERDATA', null);
  //     dispatch('SET_ISADMIN', false);
  //   }
  // },[isAuth]);

  const getUserDataHandler = async(url, token) => {
    try {
      const result = await getUserData(url, token);

      console.log(result);

      if (result.data) {
        // setUserData(result.data);
        dispatch('SET_USERDATA', result.data);

        if (result.data.status === 'admin') {
          dispatch('SET_ISADMIN', true);
        }
      }
    } catch(err) {
      console.log(err);
    }
  };

  return <Fragment></Fragment>;
};

export default GetUserData;
