import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SHOW_NOTIFICATION: (state, payload) => {
      const notifyContent = {
        status: payload.status,
        title: payload.title,
        message: payload.message,
      };

      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          notification: notifyContent, 
        }
        // i18n: state.i18next,
      };
    },
    CLEAR_NOTIFICATION: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          notification: null,
        }
       };
    },
    SET_WINDOW_VALUES: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          windowValues: payload,
        }
      }
    },
    SET_ISAUTH: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAuth: payload,
        }
      }
    },
    SET_SHOWTOGGLENAV: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          showToggleNav: payload,
        }
      }
    },
    SET_ISADMIN: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAdmin: payload,
        }
      }
    },
    SET_USERDATA: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          userData: payload,
        }
      }
    },
    SET_LANGUAGE_NAME_LIST: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          languageNameList: payload,
        }
      }
    },
    SET_USERNAMEDATA: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          userNameData: payload,
        }
      }
    },
    SET_GLOADING: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          gLoading: payload,
        }
      }
    }, 
  };

  initStore(actions, {
    uiStore: {
      notification: null,
      windowValues: null,
  
      isAuth: false,
      showToggleNav: false,

      isAdmin: false,
      userData: null,

      languageNameList: [],
      userNameData: null,
      gLoading: false,
    }
  });
};

export default configureStore;
