import React from 'react';
import { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
// import 'tui-grid/dist/tui-grid.css';
import Grid from '@toast-ui/react-grid';
import './tui-grid.css';
import TuiGrid from 'tui-grid';

import AdminAdHostResutSearch from './AdminAdHostResultSearch';
import { useStore } from '../../../hook-store/store';

import classes from './AdminAdHostResultTable.module.css';


TuiGrid.applyTheme('striped');




function AdHostResutTable() {

  // const chartRef = useRef(null);
  const gridRef = React.createRef();

  const [store, dispatch] = useStore();
  // const { 
  //   listForTable, 
  //   resultSearchSelectId,
  //   isResultSearchResults,
  //   totalResult,
  // } = store.adHostResultStore;

  const { 
    adminListForTable, 
    adminResultSearchSelectId,
    adminIsResultSearchResults,
    adminTotalResult,

    adminSelectedUser,
  } = store.adminStore;

  const { showToggleNav } = store.uiStore;

  const [perPage, setPerPage] = useState(5);

  // const dataList = listForTable.map((data, index) => {
  //   return {
  //     ...data,
  //     id: index,
  //   }
  // });

  let columnForTable = [
    // { name: 'id', header: 'ID', sortingType: 'desc', sortable: true},
    { name: 'date', header: 'date', sortingType: 'desc', sortable: true },
    { name: 'displayNum', header: 'display' },
    { name: 'visitNum', header: 'visit' },
    { name: 'vRate', header: 'visit rate' },
    { name: 'payOut', header: 'payout' },
  ];

  if (window.innerWidth < 480) {
    columnForTable = [
      // { name: 'id', header: 'ID', sortingType: 'desc', sortable: true},
      { name: 'date', header: 'date', sortingType: 'desc', sortable: true },
      { name: 'displayNum', header: 'display' },
      { name: 'visitNum', header: 'visit' },
      // { name: 'vRate', header: 'visit rate' },
      { name: 'payOut', header: 'payout' },
    ];
  }

  // useEffect(() => {
  //   const gridContainerEl = document.getElementsByClassName('tui-grid-container');
  //   if (gridContainerEl[0]) {
  //     gridContainerEl[0].style.maxWidth = '90%';
  //     gridContainerEl[0].style.width = '90%';
  //     console.log(gridContainerEl);
  //   }
  // },[listForTable]);


  // const handleClickButton = () => {
  //   console.log('type:', chartRef.current.getInstance().showSeriesDataLabel());
  // };

  const handleAppendRow = () => {
    gridRef.current.getInstance().appendRow({});
  };

  const handleClickButton = () => {
    gridRef.current.getRootElement().classList.add('my-grid-root');
    console.log(gridRef.current.getRootElement());
  };

  const handleClick = (event) => {
    console.log('click!!, event', event);
  }

  const handleExport = (event) => {
    const grid = gridRef.current.getInstance();
    grid.export('csv');
    // grid.export('csv');
  }


  return (
    <div style={showToggleNav ? { display:'none'} : null}>
      {/* <div className={classes.adHostResultTablePayOutAbout}>
        <Link to="/pay-out-about">about-payout</Link>
      </div> */}
      <div
        title={JSON.stringify(adminSelectedUser)}
      >
        admin-ad-host-result-table,{' '}
        userId: {adminSelectedUser && adminSelectedUser.userId}
        <Link to="/admin/top-page"><button>admin-top-page</button></Link>
      </div>
      
      <div>
        <AdminAdHostResutSearch/>
      </div>
      <div>selected-adPlaceId: {adminResultSearchSelectId}</div>
 
      <select className={classes.adHostResultTableSelect}
        // value={pageSize}
        onChange={e => {
          dispatch('SET_ADMINDURATION', Number(e.target.value));
          // setSelectedAdElement(e.target.value);
        }}
      >
        <option value={10}>
          {/* {t('adReport.text05', 'Select Duration')} */}
          Duration
        </option>
        {[10, 30, 90, 365].map((duration, index) => (
          <option key={index} value={duration}>
            {duration} days
            {/* {t('general.text47', 'days')} */}
          </option>
        ))}
        {adminResultSearchSelectId && (
          <option value={0}>
            from start
            {/* {t('adReport.text12', 'from start date')} */}
          </option>
        )}
      </select>

      <div className={classes.adHostResultTableSummary}>
        <span>
          total display: {adminTotalResult.displayNum.toFixed()}
        </span>
        <span>
          toatal visit: {adminTotalResult.visitNum.toFixed()}
        </span>
        <span>
          visit rate: {(adminTotalResult.visitNum/adminTotalResult.displayNum*100).toFixed(1)}%
        </span>
        <span>
          total payout: {adminTotalResult.payOut.toFixed(2)}
        </span>
      </div>


      {adminListForTable.length > 0 && (
        <div
          style={adminIsResultSearchResults ? { display:'none'} : null}
        >
        <Grid
          ref={gridRef}
          // data={dataList}
          data={adminListForTable}
          columns={columnForTable}
          // rowHeight={25}
          // bodyHeight={100}
          rowHeight={20}
          bodyHeight={400}
          heightResizable={true}
          // rowHeaders={['rowNum']}
          // oneTimeBindingProps={['data', 'columns']}
          onClick={handleClick}
          pageOptions={{
            useClient: true,
            perPage: 50
          }}
          scrollY={false}
          scrollX={true}
          // summary={{
          //   height: 40,
          //   position: 'bottom', // or 'top'
          //   columnContent: {
          //     num1: {
          //       template: function(valueMap) {
          //         return `MAX: ${valueMap.max}<br>MIN: ${valueMap.min}<br>row-num: ${valueMap.cnt}`;
          //       }
          //     },
          //     num2: {
          //       template: function(valueMap) {
          //         return `TOTAL: ${valueMap.sum} <br>AVG: ${valueMap.avg.toFixed(2)}`;
          //       }
          //     }
          //   }
          // }}
        />
        </div>
      )}

      {/* <button onClick={handleAppendRow}>Append Row</button>
      <button onClick={handleClickButton}>Click!</button>
      <button onClick={handleExport}>Click-export</button> */}

    </div>
  );
}

export default AdHostResutTable;