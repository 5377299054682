// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import AdminAdHostFilter from './AdminAdHostFilter/AdminAdHostFilter';
import AdminAdHostListItem from './AdminAdHostListItem/AdminAdHostListItem';
import AdminAdHostSearch from './AdminAdHostSearch/AdminAdHostSerach';
import Loader from '../../Loader/Loader';
import Paginator from '../../Paginator/Paginator';

import { useStore } from '../../../hook-store/store';
// import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';
import { getUserAdHostsForAdmin, getAdHostsForAdmin } from '../../../utils/admin/admin-ad-host-util';
import { NODEURL } from '../../../App';

const AdminAdHostList = props =>  {
  
  const perPagePost = 20;
  
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    usersForAdmin,  
    adminSelectedUser,
    adHostsForAdmin
  } = store.adminStore;
  // const { isAuth } = store.uiStore;

  const [pageNum, setPageNum] = useState(1);
  const [listFilter, setListFilter] = useState({
    type: '',
    value: '',
  });
  const [isReverse, setIsReverse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (adminSelectedUser) {
      adminGetAdHostsHandler(adminSelectedUser.userId);
    } else {
      adminGetAdHostsHandler(null);
    }
    
    // adminGetUsersHandler();
  },[]);

  const adminGetAdHostsHandler = async (userId) => {
    try {
      setIsLoading(true);
      
      let resData;

      if (userId) {
        resData = await getUserAdHostsForAdmin(
          NODEURL,
          localStorage.getItem('token'),
          userId,
        );

      } else {
        resData = await getAdHostsForAdmin(
          NODEURL,
          localStorage.getItem('token'),
        );
      }


      if (resData) {
        console.log(resData)
        dispatch('SET_ADHOSTS_FORADMIN', resData);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const setAdHostFilterHandler = (type, value, reverse) => {
    setListFilter({
      type: type,
      value: value,
    });
  };

  const createFilteredList = (filter) => {
    let filteredList = adHostsForAdmin;

    if (filter.type && filter.type === 'adType') {
      if (filter.value) {
        filteredList = adHostsForAdmin.filter(adHost => {
          return adHost.adType === filter.value;
        });
      }
    }

    if (filter.type && filter.type === 'host') {
      if (filter.value) {
        filteredList = adHostsForAdmin.filter(adHost => {
          return adHost.host === filter.value;
        });
      }
    }

    if (filter.type && filter.type === 'adStatus') {
      if (filter.value) {
        filteredList = adHostsForAdmin.filter(adHost => {
          return adHost.adStatus === filter.value;
        });
      }
    }

    if (filter.type && filter.type === 'createdAt') {
      if (filter.value === '90d') {
        filteredList = adHostsForAdmin.filter(adHost => {
          return new Date(adHost.createdAt).getTime() >= Date.now() - 1000*60*60*24*90;
        });
      }

      if (filter.value === '180d') {
        filteredList = adHostsForAdmin.filter(adHost => {
          return new Date(adHost.createdAt).getTime() >= Date.now() - 1000*60*60*24*180;
        });
      }

      if (filter.value === '365d') {
        filteredList = adHostsForAdmin.filter(adHost => {
          return new Date(adHost.createdAt).getTime() >= Date.now() - 1000*60*60*24*365;
        });
      }
    }

    return filteredList;
  };

  
  console.log(listFilter);
  console.log(isReverse);

  let adminAdHostsBody;

  if (adHostsForAdmin && adHostsForAdmin.length === 0) {
    adminAdHostsBody = <div>no ads</div>
  }

  if (adHostsForAdmin && adHostsForAdmin.length > 0) {

      const start = perPagePost * (pageNum - 1);
      const end = perPagePost * pageNum;

      let displayList = createFilteredList(listFilter);

      if (isReverse) {
        displayList = _.reverse(displayList);
      }

      adminAdHostsBody = (
        <ul>
          {displayList.slice(start, end).map(data => {
            return (
              <li key={data.adPlaceId}>
                <AdminAdHostListItem 
                  data={data}
                />

                {/* <AdminAdElementsItem
                  ad={ad}
                  startEditAdHandler={startEditAdHandler}
                /> */}
              </li>
            );
          })}
        </ul>
      );

  }
    
  
  return (
    <Fragment>
      <AdminAdHostSearch />
      <div style={{paddingTop:"3rem"}}>
        <div 
          title={JSON.stringify(adminSelectedUser)}
        >
          admin-ad-host-list
          userId: {adminSelectedUser && adminSelectedUser.userId}
        </div>
        <Link to="/admin/top-page"><button>admin-top-page</button></Link>
        {isLoading && <Loader />}

        <div>
          <div>filters-for-list</div>
          <AdminAdHostFilter 
            listFilter={listFilter}
            setAdHostFilterHandler={setAdHostFilterHandler}
            setIsReverse={setIsReverse}
            isReverse={isReverse}
          />
        </div>
        
        <div>{adminAdHostsBody}</div>
        <Paginator
          onPrevious={() => { setPageNum(pageNum - 1); }}
          onNext={() => { setPageNum(pageNum + 1); }}
          lastPage={Math.ceil(adHostsForAdmin.length / perPagePost)}
          currentPage={pageNum}
        >
                {/* {feedPost2} */}
        </Paginator>
      </div>
    </Fragment>
  );
}

export default AdminAdHostList;

