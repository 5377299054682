import { Fragment } from "react";

import NotPageFound from "../../components/NotPageFound/NotPageFound";
function NotFoundPage(props) {

  return (
    <Fragment>
      {/* <div>not-found-page</div> */}
      <NotPageFound />
    </Fragment>
  );
}

export default NotFoundPage;
