// const React = window.React = require('react');
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AboutPageTextContent from './AboutPageText';
import AdItems from '../AdElements/AdItems/AdItems';
import AuthRequired from '../Auth/AuthRequired';
// import VideoAdItems from '../AdElements/AdItems/VideoAdItems';

import { useStore } from '../../hook-store/store';

import { exampleAdPlaceId } from '../../App';
// import { marks } from '../../utils/marks';
import classes from './AboutPageContent.module.css';

// import adImage1 from '../../images/kura-storage-ad-image-1.png';
// import adImage2 from '../../images/kura-storage-ad-image-2.png';


const AboutPageContent = props =>  {

  const [t] = useTranslation('translation');

  const adExampleRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams, searchParams.get('scrollRef'));

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  const [showMoreAds, setShowMoreAds] = useState(false);


  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    const scrollRef = searchParams.get('scrollRef');

    if (scrollRef === 'adExample') {
      scrollToAdExample();
    }
  },[]);


  const scrollToAdExample = () => adExampleRef.current.scrollIntoView();

  const adnetworkLinkEl = (
    <a className={classes.adLink}
      // href={ad.linkUrl}
      href="https://adnetwork.spaceeight.net"
      target='_blank' rel='noopener noreferrer'
    >
      Adnetwork
    </a>
  );

  let aboutPageBody;

  aboutPageBody = (
    <div className={classes.aboutPage}>
      <AboutPageTextContent />

      <p ref={adExampleRef}>
        <strong>{t('aboutPage16', 'Ad examples')}</strong>
      </p>
      
      <div>{t('aboutPage17', 'Banner ads')}</div>

      {window.innerWidth > 480 && (
        <span>
          600x130
          <AdItems adType={'600x130'} />
        </span>
      )}
      <span>
        300x65
        <AdItems adType={'300x65'} />
      </span>
      {/* video300-ad
      <VideoAdItems
        adType={'video300'}
        activeList={[]}
      /> */}

      <div>
        <div>{t('aboutPage18', 'Video with ad')}</div>

        <iframe id={exampleAdPlaceId} title="video with ad example"
          style={{
            maxWidth:"100%",
            maxHeight:"100vh",
            // width: `${videoWithAdWidth}px`,
            width: "400px",
            // height: `${videoWithAdHeight}px`,
            height: "250px",
            // border:  showBorder ? "1px solid red" : 'none',
            border: '0.1px solid gray', 
          }}
          // style={{maxWidth:"98%", maxHeight:"95vh", width:"800px", height:"500px", border:"1px solid red"}}
          src={`${window.location.origin}/adhost-preview?adPlaceId=${exampleAdPlaceId}&adType=videoWithAd&mute=1`}
        />
      </div>
      
      <div>
        <button
          className='btnSmallBase'
          onClick={() => {
            setShowMoreAds(!showMoreAds);
          }}
        >
          {t('aboutPage19', 'Display more')}
        </button>
      </div>
      {showMoreAds && (
        <div>
          <span>
            300x300
            <AdItems adType={'300x300'} />
          </span>
          <span>
            150x500
            <AdItems adType={'150x500'} />
          </span>
          <span>
            450x90
            <AdItems adType={'450x90'} />
          </span>
        </div>
      )}

      {/* <div className={classes.aboutPageImageContainer}>
        <img className={classes.aboutPageImage}
          src={adImage1}
        />
        <img className={classes.aboutPageImage}
          src={adImage2}
        />
      </div> */}

    </div>
  );

  return (
    <Fragment>
      {aboutPageBody}
    </Fragment>
  );
}
export default AboutPageContent;

