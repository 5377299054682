import { Fragment } from "react";

import PayOutAddressList from "../../components/PayOutAddress/PayOutAddressList/PayOutAddressList";

function PayoutAddressPage(props) {

  return (
    <Fragment>
      <PayOutAddressList />
    </Fragment>
  );
}

export default PayoutAddressPage;
