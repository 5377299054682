
import { useEffect, useState, Fragment } from "react";

// import Backdrop from "../../../Backdrop/Backdrop";
// import Modal from "../../../Modal/Modal";
// import Loader from "../../../Loader/Loader";
// import RefTest from "../../../vidtest/RefTest";
// import { useStore } from "../../../../hook-store/store";
import VideoWithAdDisplayAdHost from "../../../../AdElements/AdElementDisplay/VideoWithAdDisplayAdHost";

import { useStore } from "../../../../../hook-store/store";
import { getAdHostData } from "../../../../../utils/ad-host/ad-host-util";

import { NODEURL } from "../../../../../App";
// import { NODEURL } from "../../../App";
// import { NODEURL } from "../../../../App";

// import Black2s from './black-bg-2s.mp4';
// import classes from './UserAdHostListItem.module.css';

const curUrl = new URL(window.location.href);
const adPlaceId = curUrl.searchParams.get('adPlaceId');
const adType = curUrl.searchParams.get('adType');
// // const adHostData = curUrl.searchParams.get('adHostData');
// const uploadVideoUrl = curUrl.searchParams.get('uploadVideoUrl');
// console.log('adPlaceId', adPlaceId, uploadVideoUrl);

function VideoWithAdPreview(props) {

  // const { } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  // const isAuth = store.uiStore.isAuth;
  const [adHostData, setAdHostData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAdHostDataHandler(
      NODEURL,
      localStorage.getItem('token'),
      adPlaceId,
      adType,
    );
  },[]);

  const getAdHostDataHandler = async (url, token, adPlaceId, adType) => {
    try {
      setIsLoading(true);

      const resData = await getAdHostData(url, token, adPlaceId, adType);

      if (resData.data) {
        // dispatch('SET_ADHOSTDATAFORPREVIEW', resData.data);
        
        setAdHostData(resData.data);

      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let videoWithAdPreviewBody;

  if (adHostData) {
    videoWithAdPreviewBody = (
      <VideoWithAdDisplayAdHost
        adType='videoWithAd'
        adPlaceId={adPlaceId}
        adHostData={adHostData}
      />
    );
  }
  
  return (
    <Fragment>
      {videoWithAdPreviewBody}
    </Fragment>
  );
}

export default VideoWithAdPreview;

