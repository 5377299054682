
export const getVideoWithAdBannerAds = async (
  url, 
  token, 
  adPlaceId, 
  adType,
) => {
  try {
    const response = await fetch(
      `${url}/video-with-ad-banner?adPlaceId=${adPlaceId}&adType=${adType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addVideoWithAdBanner = async (
  url, 
  token, 
  adPlaceId, 
  adType,
  start,
  end,
) => {
  try {
    const response = await fetch(
      `${url}/video-with-ad-banner`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adPlaceId: adPlaceId,
          adType: adType,
          start: start,
          end: end,
        }),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteVideoWithAdBanner = async (
  url, 
  token, 
  adPlaceId, 
  adType,
  start,
  end,
) => {
  try {
    const response = await fetch(
      `${url}/video-with-ad-banner?adPlaceId=${adPlaceId}&adType=${adType}&start=${start}&end=${end}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(),
      }
    );

    const resData = await response.json();

    console.log(resData, response);

    if (!response.ok) {
      throw new Error("something wrong");
    } 

    return resData;
      
  } catch (err) {
    console.log(err);
    throw err;
  }
};