import { useEffect, useState, Fragment } from "react";
import { DateTime } from "luxon";

import Loader from "../Loader/Loader";

import { useStore } from "../../hook-store/store";

import { NODEURL } from "../../App";


// import classes from './UserAdHostList.module.css';



// import ReactSearchAutoComp from "../../ReactSearchAutoComp";

function CreateResultData(props) {

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const { 
    adHostResultDataList,
    selectedAdPlaceId,
    resultSearchSelectId,
    duration,
   } = store.adHostResultStore;

  // const isAuth = store.uiStore.isAuth;
  // const adHostDataList = store.adHostStore.adHostDataList;
  // const searchSelectedItem = store.adHostStore.searchSelectedItem;

 
  // const [searchSelectedItem, setSearchSelectedItem] = useState();

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    getUserAdHostResults(
      NODEURL,
      localStorage.getItem('token')
    );
  }, []);


  useEffect(() => {

    if (adHostResultDataList.length > 0) {

      createListForTable(resultSearchSelectId, duration);

    }

  },[adHostResultDataList, resultSearchSelectId, duration]);


  const getUserAdHostResults = async (url, token) => {
    try {
      setIsLoading(true);

      const response = await fetch(url + `/ad-host-result/user-ad-host-results`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });
      console.log('response', response);

      //// handle ok case
      if (response.ok) {
        const resData = await response.json();
        console.log('resData', resData);
        // // setUserAdResults(resData.userAdResults);
        dispatch('SET_ADHOSTRESULT_DATALIST', resData.data);

        setIsLoading(false);
        return resData;
      } 

      // //// handle not ok case
      // else {

      //   console.log('fetching data failed, error response returned');
      //   const errorData = await response.json();
      //   console.log('errorData', errorData);
      // }

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };


  const createListForTable = (adPlaceId, duration) => {

    const dayArray = createDayArray(adPlaceId, duration, adHostResultDataList);

    // return dayArray;

    // let adResult = userAdResults[0];
    // console.log(transFormedArray);

    let totalDisplay = 0;
    let totalVisit = 0;
    let totalCharge = 0;
    let totalPayOut = 0;

    if (!adPlaceId || adPlaceId === 'all') {
      const daySumArray = createDaySumArray(dayArray, adHostResultDataList);
      // console.log('daySumArray', daySumArray);
      
      const decimalList = [];
      for (const dayResult of daySumArray) {
        decimalList.push({
          ...dayResult,
          charge: Number(dayResult.charge.toFixed(2)),
          vRate: Number(dayResult.vRate.toFixed(1)),
          payOut: Number(dayResult.payOut.toFixed(4)),
        });
      }

      dispatch('SET_LIST_FORTABLE', decimalList);

      for (const dayResult of daySumArray) {
        totalDisplay = totalDisplay + dayResult.displayNum;
        totalVisit = totalVisit + dayResult.visitNum;
        totalCharge = totalCharge + dayResult.charge;
        totalPayOut = totalPayOut + dayResult.payOut;
      }

      // console.log('luxon total', totalDisplay, totalVisit, totalCharge);
      dispatch('SET_TOTAL_RESULT', { 
        visitNum: totalVisit,
        displayNum: totalDisplay,
        charge: totalCharge,
        payOut: totalPayOut,
      });

    } else {

      const adResult = adHostResultDataList.filter(result => {
          return result.adPlaceId === adPlaceId;
        })[0];
      // console.log(userAdResults, adResult);

      const adVisitDataList = adResult.dayVisit
      // console.log('luxon array',dayArray, adVisitDataList, userAdResults[0]);
      // console.log(userAdResults);

      const transFormedArray = createTransFormedArray(dayArray, adVisitDataList, adResult.adElementId);
      
      const decimalList = [];
      
      for (const dayResult of transFormedArray) {
        decimalList.push({
          ...dayResult,
          charge: Number(dayResult.charge.toFixed(2)),
          vRate: Number(dayResult.vRate.toFixed(1)),
          payOut: Number(dayResult.payOut.toFixed(4)),
        });
      }

      dispatch('SET_LIST_FORTABLE', decimalList);

      for (const dayResult of transFormedArray) {
        totalDisplay = totalDisplay + dayResult.displayNum;
        totalVisit = totalVisit + dayResult.visitNum;
        totalCharge = totalCharge + dayResult.charge;
        totalPayOut = totalPayOut + dayResult.payOut;
      }

      // console.log('luxon total', totalDisplay, totalVisit, totalCharge);
      dispatch('SET_TOTAL_RESULT', { 
        visitNum: totalVisit,
        displayNum: totalDisplay,
        charge: totalCharge,
        payOut: totalPayOut,
      });
    }
  };


  const createDayArray = (adPlaceId, duration, userAdResults) => {

    const adElId = adPlaceId && adPlaceId !== 'all' ? adPlaceId : '';
    
    const nowDateElements = new Date().toISOString().split('T')[0].split('-');
    const nowYear = parseInt(nowDateElements[0]);
    const nowMonth = parseInt(nowDateElements[1]);
    const nowDay = parseInt(nowDateElements[2]);
    const dayArray = [];

    let dayDuration = 20;

    if (duration) {
      dayDuration = duration;
    }

    if (adElId && duration === 0) {
      const userAdResult = userAdResults.find(result => {
        return result.adPlaceId === adElId;
      });

      console.log('luxon', adElId, duration, userAdResult);

      if (userAdResult) {
        // const oriStart = userAdResult.adElement.oriStart;
        const oriStart = userAdResult.createdAt;
        const oriDate = new Date(oriStart).toISOString();
  
        const dateOriStart = DateTime.fromISO(oriDate);
        const dateNow = DateTime.fromISO(new Date().toISOString());
  
        const diff = dateNow.diff(dateOriStart, ["years", "months", "days", "hours"])
        const diff2 = dateNow.diff(dateOriStart, ["days"])
        
        dayDuration = Math.round(diff2.toObject().days + 5);

        console.log('luxo oriDate, nowDate', new Date(oriStart).toISOString(), new Date().toISOString());
        console.log('luxon diff', diff.toObject(), diff2.toObject(), Math.round(diff2.toObject().days + 7), oriDate);
        console.log('luxon diff dayDuration', dayDuration);
      
      }
    }



    for (let i=0; i<dayDuration; i++) {
      const add = DateTime.local(nowYear, nowMonth, nowDay).plus({ days: -i }).toISODate();
      dayArray.push(add);
    }

    return dayArray;
  };


  const createTransFormedArray = (dayArray, adVisitDataList, adElementId) => {
    const transFormedArray = [];
    
    // console.log('adVisitDataList', adVisitDataList);

    for (const day of dayArray) {
      const dayVisitData = adVisitDataList.find(visitData => {
        return visitData.date === day;
      });
      // console.log(dayVisitData);

      if (dayVisitData) {

        let vRate = 0;
        if (dayVisitData.visitNum > 0 && dayVisitData.displayNum > 0) {
          const pctNum = dayVisitData.visitNum / dayVisitData.displayNum * 100
          // vRate = Number(pctNum.toFixed(1));
          vRate = pctNum;
        }

        transFormedArray.push({
          date: day,
          visitNum: dayVisitData.visitNum || 0,
          displayNum: dayVisitData.displayNum || 0,
          vRate: vRate,
          charge: dayVisitData.charge || 0,
          payOut: dayVisitData.payOut || 0,
          // adElementId: adElementId,
          // // adElementIdNum: 1,
          // adElementIdList: [adElementId],
        });
      } else {
        transFormedArray.push({
          date: day,
          visitNum: 0,
          displayNum: 0,
          vRate: 0,
          charge: 0,
          payOut: 0,
          // adElementId: adElementId,
          // // adElementIdNum: 1,
          // adElementIdList: [adElementId],
        });
      }
    }

    console.log('transFormedArray', transFormedArray);

    return transFormedArray;

  };


  const createDaySumArray = (dayArray, userAdResults) => {
    const combinedArray = [];
    const sumArray = [];

    // const adElementIdList = [];

    // for (const userAdResult of userAdResults) {
    //   adElementIdList.push(userAdResult.adElementId);
    // }

    for (const userAdResult of userAdResults) {
      const list = createTransFormedArray(dayArray, userAdResult.dayVisit, userAdResult.adElementId);
      combinedArray.push(list);
    }
    console.log('combinedArray', combinedArray);


    for (let i=0; i<dayArray.length; i++) {
      
      let totalVisit = 0;
      let totalDisplay = 0;
      let totalCharge = 0;
      let totalPayOut = 0;

      for (let j=0; j<combinedArray.length; j++) {
        // console.log(combinedArray[j][i]);
        totalVisit = totalVisit + combinedArray[j][i].visitNum;
        totalDisplay = totalDisplay + combinedArray[j][i].displayNum;
        totalCharge = totalCharge + combinedArray[j][i].charge;
        totalPayOut = totalPayOut + combinedArray[j][i].payOut;
      }
      // console.log(totalVisit, totalDisplay);
      
      let vRate = 0;

      if (totalVisit > 0 && totalDisplay > 0) {
        const pctNum = totalVisit / totalDisplay * 100
        // vRate = Number(pctNum.toFixed(1));
        vRate = pctNum;
      }

      sumArray.push({
        date: dayArray[i],
        visitNum: totalVisit,
        displayNum: totalDisplay,
        vRate: vRate,
        charge: totalCharge,
        payOut: totalPayOut,
        // // adElementIdNum: combinedArray.length,
        // adElementId: '',
        // adElementIdList: adElementIdList,
      });
    }

    // console.log(dayArray);
    console.log(sumArray);

    return sumArray;
  };


  return (
    <Fragment>
      {isLoading && <Loader />}
    </Fragment>
  );
}

export default CreateResultData;
