export const getUsersForAdmin = async (url, token) => {
  try {
      const result = await fetch(url + '/admin-user/users', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const adminUpdateUserAccountStatus = async (url, token, accountStatus, userId) => {
  try {
      const result = await fetch(url + '/admin-user/user-account-status', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accountStatus: accountStatus,
          userId: userId,
        }),
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;

  } catch(err) {
    console.log(err);
    throw err;
  }
};