// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdminCreateResultData from '../../../components/Admin/AdminAdHostResult/AdminCreateResultData';
import AdminAdHostResultTable from '../../../components/Admin/AdminAdHostResult/AdminAdHostResultTable';

import { useStore } from '../../../hook-store/store';

import classes from './AdminAdHostResultPage.module.css';

const AdminAdHostResultPage = props =>  {

  const [t] = useTranslation('translation');

  // const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
      <AdminCreateResultData />  
      <div className={classes.adHostResultTableContainer}
        // style={{paddingBottom:"300px", zIndex: '10'}}
      >
        <AdminAdHostResultTable />    
      </div>
    </Fragment>
  );
}
export default AdminAdHostResultPage;

