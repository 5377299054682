// import React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

import { logoutHandler } from '../../../../utils/user/user';
// import { marks } from '../../../../utils/marks';
import { marks } from '../../../../images/marks';
import { loginPageLink, signupPageLink } from '../../../../App';

import './ToggleNavItems.css';



const ToggleNavItems = props => {
  // console.log('ToggleNavItems-props', props);

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  const { pageNotifyNewNotfiyNum } = store.pageNotificationStore;


  const navItems = [
    { 
      id: 'ad-host-list', 
      // text: 'feed',
      text: `Ad host ${t('navText01','data list')}`, 
      link: '/ad-host-list', 
      auth: true
    },
    { 
      id: 'ad-host-result', 
      // text: 'feed',
      text: `Ad host ${t('navText02','result')}`, 
      link: '/ad-host-result', 
      auth: true
    },
    { 
      id: 'pay-out-address', 
      // text: 'feed',
      text: `${t('navText03','Payout address')}`, 
      link: '/pay-out-address', 
      auth: true
    },
    { 
      id: 'pay-out-result', 
      // text: 'feed',
      text: `${t('navText04','Payout result')}`, 
      link: '/pay-out-result', 
      auth: true
    },
    { 
      id: 'userinfo', 
      // text: 'feed',
      text: `${t('navText05','User info')}`, 
      link: '/userinfo', 
      auth: true
    },
    { 
      id: 'page-notification', 
      // text: 'feed',
      text: `${t('navText06','Notification')}`,
      link: '/page-notification', 
      auth: true
    },
    { 
      id: 'login', 
      // text: 'feed',
      text: `${t('navText07','Login')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'signup', 
      // text: 'feed',
      text: `${t('navText08','Signup')}`, 
      link: '/feed/posts', 
      auth: false
    },
    { 
      id: 'logout', 
      // text: 'feed',
      text: `${t('navText09','Logout')}`, 
      link: '/feed/posts', 
      auth: true
    },
  ];


  const logoutConfirm = () => {
    if (window.confirm("Is it no problem to logout?")) {
      console.log('confirm true');
      // props.onLogout();

      dispatch('SET_ISAUTH', false);
      logoutHandler(); 
    }
  }

  let toggleNavItemsBody;

  toggleNavItemsBody = (
    <ul>
      {navItems.map(item => {
        if (!isAuth && item.id === 'login') {
          return (
            <div key={item.id} className="toggleNavItem">
              <span>
                <a href={loginPageLink} >
                  <span className='toggleNavItemIcon'>
                    {marks.signInMrak}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </a>
              </span>
            </div>
          );
        }

        if (!isAuth && item.id === 'signup') {
          return (
            <div key={item.id} className="toggleNavItem">
              <span>
                <a href={signupPageLink} >
                <span className='toggleNavItemIcon'>
                  {marks.userPlusMrak}
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </a>
              </span>
            </div>
          );
        }

        if (isAuth && item.id === 'ad-host-list') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                    // dispatch('SET_DISPLAYTABLE', '');
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.listUlMark} */}
                    {/* {marks.tableMark}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'ad-host-result') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.userMrak} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }        

        if (isAuth && item.id === 'pay-out-address') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMrak}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }        

        if (isAuth && item.id === 'pay-out-result') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {/* {marks.userMrak}  */}
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'userinfo') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.userMrak} 
                  </span> 
                  <span className='toggleNavItemText'>
                    {item.text}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'page-notification') {
          return (
              <div key={item.id} className="toggleNavItem">
                <Link to={item.link} 
                  onClick={() => {
                    dispatch('SET_SHOWTOGGLENAV', false);
                  }}
                >
                  <span className='toggleNavItemIcon'>
                    {marks.userMrak} 
                  </span> 
                  <span className='toggleNavItemText' style={{position:"relative"}}>
                    {item.text}

                    {pageNotifyNewNotfiyNum > 0 && (
                      <span className="toggleNavItemNotifyNum">
                        {pageNotifyNewNotfiyNum}
                      </span>
                    )}
                  </span>
                </Link>
              </div>
          );
        }

        if (isAuth && item.id === 'logout') {
          return (
              <div key={item.id} className="toggleNavItem">
                <span
                  onClick={() => { logoutConfirm(); }} 
                >
                <span className='toggleNavItemIcon'>
                  {marks.signOutMrak} 
                </span> 
                <span className='toggleNavItemText'>
                  {item.text}
                </span>
                </span>
              </div>
          );
        }

        else {
          return null;
        }

        // return (
        //   <div  className="toggleNavItem">
        //     <span>
        //     <span className='toggleNavItemIcon'>
        //       {item.text}
        //       {/* {marks.userMrak}  */}
        //       </span> 
        //     </span>
        //   </div>
        // );
      })}
    </ul>
  );

  return (
  <Fragment>
    {toggleNavItemsBody}
  </Fragment>
  );

  }
export default ToggleNavItems;
