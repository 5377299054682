import { useEffect, useState, Fragment } from "react";

// import Loader from "../../../Loader/Loader";

import { useStore } from "../../../../../hook-store/store";

// import { NODEURL } from "../../../../App";

import classes from './VideoControl.module.css';

function VideoControl(props) {
  const { 
    data, 
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const { 
    videoWithAdWidth,
    videoWithAdHeight,
    videoMuteState,
    showBorder,
   } = store.videoWithAdStore;
  // const isAuth = store.uiStore.isAuth;

  const [heightInput, setHeightInput] = useState('');
  const [widthInput, setWidthInput] = useState('');
  // const [vContainerHeight, setVContainerHeight] = useState(200);
  // const [vContainerWidth, setVContainerWidth] = useState(400);

  const [isLoading, setIsLoading] = useState(false);


  const muteControlHandler = () => {
    if (videoMuteState) {
      dispatch('SET_VIDEOMUTESTATE', false);
    } else {
      dispatch('SET_VIDEOMUTESTATE', true);
    }
  };

  const showBorderHandler = () => {
    if (showBorder) {
      dispatch('SET_SHOWBORDER', false);
    } else {
      dispatch('SET_SHOWBORDER', true);
    }
  };

  let videoControlBody
  
  videoControlBody = (
    <div className={classes.videoControl}>
        <div className={classes.videoControlTitle}>
          Video control
        </div>

      <div className={classes.videoControlItemSize}>
        <div className={classes.videoControlItem}>
          <label>width:</label>
          {' '}
          <strong>
            {videoWithAdWidth}px
          </strong>
          <div className={classes.videoControlItemSizeInput}>
            <input 
              className="inputBase"
              style={{maxWidth: "10rem"}}
              type='text'
              placeholder='number'
              onChange={(event) => { 
                setWidthInput(event.target.value)
              }}
            />
            <button
              className="btnSmallBase"
              onClick={() => {
                if (Number(widthInput) > 0) {
                  dispatch('SET_VIDEOWITHADWIDTH', Number(widthInput));
                }
              }}
            >
              Set width
            </button>
          </div>
        </div>
        
        <div className={classes.videoControlItem}>
          <label>height:</label>
          {' '}
          <strong>
            {videoWithAdHeight}px
          </strong>
          <div className={classes.videoControlItemSizeInput}>
            <input 
              className="inputBase"
              style={{maxWidth: "10rem"}}
              type='text'
              placeholder='number'
              onChange={(event) => { 
                setHeightInput(event.target.value)
              }}
            />
            <button
              className="btnSmallBase"
              onClick={() => {
                if (Number(heightInput) > 0) {
                  dispatch('SET_VIDEOWITHADHEIGHT', Number(heightInput));
                }
              }}
            >
              Set height
            </button>
          </div>
        </div>
      </div>
      
      <div className={classes.videoControlItemSize}>
        <div className={classes.videoControlItem}>
          <div>
            Default mute state:
          </div>
          <div className={classes.videoControlItemSizeInput}>
            {videoMuteState && <span>mute</span>}
            {!videoMuteState && <span>not mute</span>}
            <button
              className="btnSmallBase"
              onClick={muteControlHandler}
            >
              Change mute
            </button>
          </div>
        </div>

        <div className={classes.videoControlItem}>
          <div>
            Border:
          </div>
          <div className={classes.videoControlItemSizeInput}>
            {showBorder && <span>display border</span>}
            {!showBorder && <span>no border</span>}
            <button
              className="btnSmallBase"
              onClick={showBorderHandler}
            >
              Change border
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {videoControlBody}
    </Fragment>
  );
}

export default VideoControl;
