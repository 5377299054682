import { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap.css';

import Loader from '../../Loader/Loader';

import { useStore } from "../../../hook-store/store";

import { NODEURL } from "../../../App";

import { marks } from "../../../images/marks";
import classes from './CreateAdHost.module.css';
import { size } from "lodash";


function CreateAdHost(props) {
  const { showCreateModalHandler } = props;

  const [t] = useTranslation('translation');

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;
  const adHostDataList = store.adHostStore.adHostDataList;

  const [urlData, setUrlData] = useState();
  const [hostInput, setHostInput] = useState('');
  const [adTypeInput, setAdTypeInput] = useState('');
  // const [adPlaceIdInput, setAdPlaceIdInput] = useState('');

  const [showConfirm, setShowConfirm] = useState(false);
  const [sizeInfoClicked, setSizeInfoClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputData = {
    host: hostInput,
    adType: adTypeInput,
    // adPlaceId: adPlaceIdInput,
  };


  const adTypeList = [
    {
      adType: '300x65',
      displayName: '300x65',
    },
    {
      adType: '450x90',
      displayName: '450x90',
    },  
    {
      adType: '600x130',
      displayName: '600x130',
    },  
    {
      adType: '150x500',
      displayName: '150x500',
    },  
    {
      adType: '300x300',
      displayName: '300x300',
    },
  ];
  

  const isValidInputs = () => {
    if (hostInput && adTypeInput) {
      return true;
    }
    return false;
  }

  // console.log('iputData', inputData);

  // useEffect(() => {
  //   if (isAuth) {
  //     getUserAdHostDataListHandler(
  //       NODEURL,
  //       localStorage.getItem('token')
  //     );
  //   }

  // }, [isAuth]);

  const showConfirmHandler = (value) => {
    setShowConfirm(value);
  }

  const urlInputChangeHandler = (event) => {
    const isValidUrl = isValidHttpUrl(event.target.value);

    if (isValidUrl) {
      const inputUrl = new URL(event.target.value);
      // const host = inputUrl.host;
      // console.log(inputUrl);
      setHostInput(inputUrl.host);
      setUrlData(inputUrl);
    }
  };


  const adTypeInputChangeHandler = (event) => {
    setAdTypeInput(event.target.value);
  };

  // const sizeInfoClickHandler = () => {
  //   if (!sizeInfoClicked) {
  //     setSizeInfoClicked(true);
  
  //     setTimeout(() => {
  //       setSizeInfoClicked(false);
  //     },5000);
  //   }
  // }
  // const adPlaceIdInputChangeHandler = (event) => {
  //   setAdPlaceIdInput(event.target.value);
  // };


  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const createAdHostDataHandler = async (url, token, inputData) => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${url}/user-ad-host`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        }
      );

      const resData = await response.json();

      if (response.ok) {

        // eslint-disable-next-line no-console
        console.log(resData, response);

        const dbAdHostData = resData.data;
        console.log('dbAdHostData', dbAdHostData);
        // setAdHostData(dbAdHostData);
        const addedList = [dbAdHostData].concat(adHostDataList);
        dispatch('SET_ADHOST_DATALIST', addedList);

        showCreateModalHandler(false);
        setIsLoading(false);

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'Ad host data created',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);

        return resData;
        // resolve(resData);
      } else {
        // reject({ message: 'get ads failed' });
        throw new Error("something wrong", resData, response);
      }

    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'Ad host data creation failed',
        message: '',
      }
    );
      setIsLoading(false);
      throw err;
      // reject(err);
    }
  };


  return (<Fragment>

      {!showConfirm && (
        <div>
          <div className={classes.createAdHostTitle}> 
            {t('createAdHost01', 'Banner Ad Creation Form')}
          </div>

          <div className={classes.createAdHostInput}>
            <label>
              Site URL
            </label>
            <div>
              <input className="inputBase"
                type='text' 
                onChange={urlInputChangeHandler}
                placeholder={'example: https://site.example.com'}
                // autoFocus
              />
            </div>
          </div>
          <div className={classes.createAdHostInput}>
            <strong>{urlData && urlData.origin}</strong>
          </div>

          <div className={classes.createAdHostInput}>
            <label>
              {t('createAdHost02', 'Banner Ad size')}
              {' '}
              <span
                onClick={() => { setSizeInfoClicked(!sizeInfoClicked); }}
              >
                {marks.questionCicleOMark}
              </span>
                {sizeInfoClicked && (
                  <Tooltip
                    placement="top"
                    overlay={
                      <div style={{ maxHeight: 150, maxWidth: 400, fontSize: "1rem" }}>
                        <div className={classes.createAdHostTooltipClose}>
                          <span className={classes.createAdHostTooltipCloseButton}
                            onClick={() => { setSizeInfoClicked(false); }}
                          >
                            {marks.closeMark}
                          </span>
                        </div>
                        <div>
                          {t('createAdHost03', 'Banner size in pixel value')},
                          <div>
                            <Link to="/?scrollRef=adExample" target="_blank" style={{color:"white"}}>
                            {t('createAdHost04', 'Display examples')} {marks.externalLinkMark}
                              
                            </Link>
                          </div>
                        </div>
                      </div>
                    }
                    align={{ offset: [-10, -5] }}
                    visible={sizeInfoClicked}
                    defaultVisible={true}
                  >          
                    <span></span>
                  </Tooltip>
                )}
            </label>
            <div>
              <select className="selectBase"
                value={adTypeInput}
                onChange={adTypeInputChangeHandler}
              > 
                <option value={''}>
                {t('createAdHost05', 'select size (width x height)')}
                </option>
                {adTypeList.map(element => (
                  <option key={element.adType} value={element.adType}>
                    {element.displayName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={classes.createAdHostInput}>
            <strong>{adTypeInput && adTypeInput}</strong>
          </div>

          <div className={classes.createAdHostAction}>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                showConfirmHandler(true);
              }}
            >
              {t('createAdHost06', 'Create')}
            </button>
          </div>
        </div>
      )}

      {showConfirm && (
        <div>
          <div className={classes.createAdHostTitle}>
            {t('createAdHost07', 'Please confirm your inputs')}
          </div>

          {urlData && (
            <div className={classes.createAdHostInput}>
              Site URL: <strong>{urlData.origin}</strong>
              <div> 
                host: {hostInput} (host value is used to store data)
              </div>
            </div>
          )}
          <div className={classes.createAdHostInput}>
          {t('createAdHost08', 'Ad type')}: <strong>{adTypeInput}</strong>
          </div>

          <div className={classes.createAdHostAction}>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                showConfirmHandler(false);
              }}
            >
              {t('createAdHost09', 'Go back')}
            </button>
            <button className="btnBase"
              disabled={isLoading || !isValidInputs()}
              onClick={() => {
                createAdHostDataHandler(
                  NODEURL,
                  localStorage.getItem('token'),
                  inputData,
                );
              }}
            >
              {t('createAdHost06', 'Create')}
            </button>
          </div>
        </div>
      )}

      {/* {isLoading && <Loader />} */}
      {isLoading && (
        <div className="centerLoader">
          <Loader />
        </div>
      )}


    </Fragment>
  );
}

export default CreateAdHost;
