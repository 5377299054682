
export const getAdHostsForAdmin = async (url, token) => {
  try {
      const result = await fetch(url + '/admin-ad-host/ad-hosts', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getUserAdHostsForAdmin = async (url, token, userId) => {
  try {
      const result = await fetch(url + `/admin-ad-host/user-ad-hosts?userId=${userId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;

  } catch(err) {
    console.log(err);
    throw err;
  }
};

// export const isAdminUser = (userData) => {
//   if (userData && userData.status && userData.status === 'admin') {
//     return true;
//   } else {
//     return false;
//   }
// };

export const adminUpdateAdStatus = async (url, token, adPlaceId, adStatus, adStatusDetail) => {
  try {
      const result = await fetch(url + '/admin-ad-host/update-ad-status', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          adPlaceId: adPlaceId,
          adStatus: adStatus,
          reason: adStatusDetail.reason,
          comment: adStatusDetail.comment,
        }),
      });

      if (!result.ok) {
        throw new Error('error occured');
      }
      
      const resData = await result.json();
      console.log(resData, result);
      return resData.data;

  } catch(err) {
    console.log(err);
    throw err;
  }
};