// import logo from './logo.svg';
// import './App.css';
import { Fragment, useEffect } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// import AuthButtons from "./components/Auth/AuthButtons";
import AdminAdHostListPage from "./pages/Admin/AdminAdHostListPage/AdminAdHostListPage";
import AdminAdHostResultPage from "./pages/Admin/AdminAdHostResultPage/AdminAdHostResultPage";
import AdminPayOutAddressPage from "./pages/Admin/AdminPayOutAddressPage/AdminPayOutAddressPage";
import AdminPayOutResultPage from "./pages/Admin/AdminPayOutResultPage/AdminPayOutResultPage";
import AdminTopPage from "./pages/Admin/AdminTopPage/AdminTopPage";
import AuthCheck from "./components/Auth/AuthCheck";
import AboutPage from "./pages/AboutPage/AboutPage";
import AdHostListPage from "./pages/AdHostListPage/AdHostListPage";
// import CreateAdHostPage from "./pages/CreateAdHostPage/CreateAdHostPage";
import Contact from './pages/Contact/Contact';
import GetAuth from "./components/Auth/GetAuth";
import Layout from "./components/Layout/Layout";
// import LinkButtons from "./components/AdHost/LinkButtons";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import UserInfoPage from "./pages/UserInfoPage/UserInfoPage";
import AdHostResultPage from "./pages/AdHostResultPage/AdHostResultPage";
import PageNotificationPage from "./pages/PageNotificationPage/PageNotificationPage";
import PayOutAboutPage from "./pages/PayOutAboutPage/PayOutAboutPage";
import PayOutAddressPage from "./pages/PayOutAddressPage/PayOutAddressPage";
import PayOutResultPage from "./pages/PayOutResultPage/PayOutResultPage";
import SingleAdHost from "./components/AdHost/SingleAdHost/SingleAdHost";
import VideoWithAdPreview from "./components/AdHost/SingleAdHost/VideoWithAdDetail/VideoWithAdPreview/VideoWithAdPreview";
// import UserAdHost from "./components/AdHost/UserAdHost/UserAdHost";
// import UserAdHostList from "./components/AdHost/UserAdHostList/UserAdHostList";

import GetUserData from "./components/UI/GetUserData";
import GetUserAdHostList from "./components/UI/GetUserAdHostList";
import GetPageNotification from "./components/UI/GetPageNotification";
import HeadMetaTag from "./components/UI/HeadMetaTag";
import SetUserNameData from "./components/Auth/SetUserNameData";
import UserSocketConnect from './components/SocketConnect/UserSocketConnect';

// import Backdrop from "./components/Backdrop/Backdrop";
// import Modal from "./components/Modal/Modal";

import { useStore } from "./hook-store/store";


// import VideoTest from "./components/vidtest/VideoTest";
// import VideoTest2 from "./components/vidtest/VideoTest2";
// import VideoTest3 from './components/vidtest/VideoTest3';
// import RefTest from "./components/vidtest/RefTest";
// import VideoOuter from "./components/vidtest/VideoOuter";




//// dev urls
// export const ADNETWORKLINK_URL = process.env.REACT_APP_DEV_ADNETLINK_URL;
// export const NODEURL = process.env.REACT_APP_DEV_NODEURL;
// export const loginPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const signupPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const adPageUrl = process.env.REACT_APP_DEV_ADPAGE_URL
// export const exampleAdPlaceId = 'localhost:3000-1678953234980';
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';


//// test deploy urls
// export const ADNETWORKLINK_URL = process.env.REACT_APP_ADNETLINK_URL;
// export const NODEURL = process.env.REACT_APP_TEST_NODEURL;
// export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const adPageUrl = process.env.REACT_APP_ADPAGE_URL
// export const exampleAdPlaceId = 'localhost:3000-1678953234980';
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';


//// do urls deploy
export const ADNETWORKLINK_URL = process.env.REACT_APP_ADNETLINK_URL;
export const NODEURL = process.env.REACT_APP_NODEURL;
export const loginPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const signupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const adPageUrl = process.env.REACT_APP_ADPAGE_URL
export const exampleAdPlaceId = 'adnetwork-adhost.spaceeight.net-1684134427320';
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';



function App() {

  const [store, dispatch] = useStore();
  console.log('store-in-App.js',store);
  const { isAuth, isAdmin, userData } = store.uiStore;


  let routes;

  if (isAuth) {
    routes = (
      <div>
        <Routes>
          {/* <Route path="/" element={<AdHostListPage />} /> */}
          <Route path="/ad-host-list" element={<AdHostListPage />} />
          <Route path="/ad-host-list/:id" element={<SingleAdHost />} />
          
          <Route path="/userinfo" element={<UserInfoPage />} />
          <Route path="/ad-host-result" element={<AdHostResultPage />} />
          <Route path="/adhost-preview" element={<VideoWithAdPreview />} />

          <Route path="/pay-out-about" element={<PayOutAboutPage />} />
          <Route path="/pay-out-address" element={<PayOutAddressPage />} />
          <Route path="/pay-out-result" element={<PayOutResultPage />} />
          {/* <Route path="/create-adhost" element={<CreateAdHostPage />} /> */}
          
          <Route path="privacypolicy" 
            element={<PrivacyPolicy />} 
          />
          <Route path="termsofuse" 
            element={<TermsOfUse />} 
          />

          <Route path="/contact" element={<Contact />} />
          <Route path="/page-notification" element={<PageNotificationPage />} />

          {isAdmin && (
            <>
              <Route path="/admin/top-page" element={<AdminTopPage />} />
              <Route path="/admin/ad-host-list" element={<AdminAdHostListPage />} />
              <Route path="/admin/ad-host-result" element={<AdminAdHostResultPage />} />
              <Route path="/admin/pay-out-address" element={<AdminPayOutAddressPage />} />
              <Route path="/admin/pay-out-result" element={<AdminPayOutResultPage />} />
            </>
          )}

          <Route path="/*" element={<NotFoundPage />} />

        </Routes>
      </div>
    )
  }
  else {
    routes = (
      <Routes>

        <Route path="/pay-out-about" element={<PayOutAboutPage />} />

        <Route path="/contact" element={<Contact />} />
        
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    )
  }

  return (
    <Fragment>
      <BrowserRouter>
        <Layout>
          
          {/* <div style={{fontFamily:"Tahoma", fontSize:"2rem"}}>font-test-fonting This is font testing</div>
          <div style={{fontFamily:"CaveatBold", fontSize:"2rem"}}>font-test2-fonting</div> */}
          
          {/* <VideoTest /> */}
          {/* <VideoTest2 /> */}
          {/* <VideoTest3 /> */}
          {/* <RefTest /> */}
          {/* <VideoOuter /> */}
          
          
          {/* <AuthButtons /> */}
          {/* <UserAdHost />
          <UserAdHostList /> */}
          {/* <LinkButtons /> */}
          {routes}
        </Layout>

        <GetAuth />
        <AuthCheck />

        <GetUserData />

        <HeadMetaTag />
        
        {isAuth && (
          <div>
            <GetUserAdHostList />
            <GetPageNotification />
            <SetUserNameData />
            <UserSocketConnect />
          </div>
        )}

      </BrowserRouter>
    </Fragment>
  );
}

export default App;
