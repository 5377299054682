
import XlmLogo from '../../images/logos/xlm-logo.png';
import XrpLogo from '../../images/logos/xrp-logo.png';
import PolygonUSDTLogo from '../../images/logos/polygon-usdt-logo.webp';

export const displayCoinList = [
  {
    coin: 'xlm',
    displayName: 'XLM',
    logo: XlmLogo,
  },
  {
    coin: 'xrp',
    displayName: 'XRP',
    logo: XrpLogo,
  },
  {
    coin: 'polygon:usdt',
    displayName: 'USDT (polygon network)',
    logo: PolygonUSDTLogo,
  },
];
