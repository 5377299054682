// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
import { 
  getPageNotification,
  createTestPageNotification,
  updatePageNotificationLastOpenTime,
  updatePageNotificationReadState,
 } from "../../utils/page-notification/page-notification";

// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
// import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { NODEURL } from "../../App";

import './PageNotificationItem.css';
// import classes from './AdminAdElements.module.css';

const PageNotificationItem = (props) => {
  const { 
    notify,
    // showNotificationHandler,
   } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { adHostDataList } = store.adHostStore;
  // const { userPageNotification } = store.pageNotificationStore;
  // console.log('store in AdminAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);


  const updateReadStateHandler = async (id, readState) => {
    try {
      setIsLoading(true);
      const resData = await updatePageNotificationReadState(
        NODEURL, 
        localStorage.getItem('token'),
        id,
        readState,
      );

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }
      // console.log(adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };



  let pageNotificationItemBody;

  
  if (notify) {
    let linkEl;

    let displayTime = new Date(notify.creationTime).toLocaleDateString();

    if (notify.creationTime > Date.now() - 1000*60*60*12) {
      displayTime = new Date(notify.creationTime).toLocaleTimeString();
    }


    if (notify.dbInfo && notify.dbInfo.dataId && notify.dbInfo.schema === 'AdHost') {
      const adHostData = adHostDataList.find(ad => {
        return ad._id === notify.dbInfo.dataId;
      });

      if (adHostData) {
        linkEl = (
          <Link 
            to={`/ad-host-list/${adHostData._id}`}
            onClick={() => { dispatch('SET_STORESELECTEDADHOSTDATA', adHostData); }}
            // onClick={showNotificationHandler}
          >
            ad host data page
          </Link>
        );
      }
    }

    pageNotificationItemBody = (
      <div className="pageNotificationItem">
        {/* <div>{notify._id}</div> */}
        <div>
          <strong>{notify.title}</strong>
        </div>
        <div>
          {notify.message}
        </div>

        <div className="pageNotificationItemLink">
          {linkEl}
        </div>


        <div className="pageNotificationItemTime">
          {displayTime}
        </div>
        {/* {JSON.stringify(notify.dataForNotification)}
        <button 
        onClick={() => {
          updateReadStateHandler(notify._id, 'read')
        }}
        >
          update-read-state
        </button> */}
      </div>
    );
  }



  return (
    <Fragment>
      <div>{pageNotificationItemBody}</div>
    </Fragment>
  );
};

export default PageNotificationItem;
