import { Fragment } from "react";

import PageNotification from "../../components/PageNotification/PageNotification";

function PageNotificationPage(props) {

  return (
    <Fragment>
      <PageNotification />
    </Fragment>
  );
}

export default PageNotificationPage;
