
import { useEffect, useState, Fragment } from "react";

import Backdrop from "../../../../../Backdrop/Backdrop";
import Modal from "../../../../../Modal/Modal";
import Loader from "../../../../../Loader/Loader";
// import VideoWithAdVideos from "../VideoWithAdVideos";
// import UpdateThumbnail from "../../ThumbnailDisplay/UpdateThumbnail";
// import RefTest from "../../../vidtest/RefTest";

import { useStore } from "../../../../../../hook-store/store";

import { 
  addVideoWithAdBanner, 
} from "../../../../../../utils/ad-host/video-with-ad-banner-util";

import { toHHMMSS } from "../../../../../../utils/ad-host/video-with-ad-util";

import { NODEURL } from "../../../../../../App";

import classes from './AdBannerCreate.module.css';

function AdBannerCreate(props) {
  const { 
    adHostData,
    // setAdHostData,
    setBannerAds,
    bannerAds,
  } = props;

  // const [adHostData, setAdHostData] = useState();
  const [store, dispatch] = useStore();
  // const isAuth = store.uiStore.isAuth;

  // const [adHostData, setAdHostData] = useState();
  // const [bannerAds, setBannerAds] = useState([]);
  const [startInput, setStartInput] = useState(0);
  const [endInput, setEndInput] = useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(startInput, endInput);
  },[startInput, endInput]);

  const startInputHandler = (event) => {
    const floorStart = Math.floor(Number(event.target.value));
    setStartInput(floorStart);

    if (isNaN(Number(event.target.value)) || floorStart < 1) {
      setStartInput(-1);
    }
  };

  const endInputHandler = (event) => {
    const floorEnd = Math.floor(Number(event.target.value));
    setEndInput(floorEnd);

    if (isNaN(Number(event.target.value)) || floorEnd < 1) {
      setEndInput(-1);
    }
  };

  const isValidStartEnd = (start, end) => {
    if (
      !start || !end || isNaN(start) || isNaN(end) || 
      start < 0 || end < 0
    ) {
      return false;
    }

    if (end <= start) {
      return false;
    }

    const isExistSame = bannerAds.find(banner => {
      return banner.start === start && banner.end === end;
    });

    const overlapAd = bannerAds.find(banner => {
      return (
        (banner.end >= start && banner.start <= start) ||
        (banner.start <= end && banner.end >= end) ||
        (banner.start <= start && banner.end >= end)
      ) 
    });

    if (isExistSame || overlapAd) {
      return false;
    }

    return true;
  }

  const addBannerHandler = async (adPlaceId, adType, start, end) => {
    try {
      setIsLoading(true);

      const resData = await addVideoWithAdBanner(
        NODEURL,
        localStorage.getItem('token'),
        adPlaceId,
        adType,
        Number(start),
        Number(end),
      );

      console.log(resData);

      if (resData.data) {
        setBannerAds(resData.data);
        setShowCreateModal(false);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  }


  let videoWithAdBannerBody;

  if (adHostData) {
    videoWithAdBannerBody = (
      <div className={classes.adBannerCreate}>
        <button className="btnSmallBase"
          disabled={isLoading}
          onClick={() => {
            setShowCreateModal(true); 
            setStartInput(-1);
            setEndInput(-1);
          }}
        >
          Add new banner ad
        </button>

        {showCreateModal && (
          <div className={classes.adBannerCreateForm}>
            <div className={classes.adBannerCreateTime}>
              <div>
                <div className={classes.adBannerCreateTimeLabel}>
                  <label>Start (seconds)</label>
                </div>
                <input className="inputBase"
                  style={{maxWidth: '10rem'}} 
                  type="text"
                  // value={startInput}
                  onChange={startInputHandler}
                />
                {startInput && startInput >= 1 && (
                  <div>
                    {toHHMMSS(startInput)}
                  </div>
                )}

              </div>
              <div>
                <div className={classes.adBannerCreateTimeLabel}>
                  <label>
                    End (seconds)
                  </label>
                </div>
                <input className="inputBase"
                  style={{maxWidth: '10rem'}} 
                  type="text"
                  // value={endInput}
                  onChange={endInputHandler}
                />

                {endInput && endInput >= 1 && (
                  <div>
                    {toHHMMSS(endInput)}
                  </div>
                )}
              </div>
            </div>

            <div className={classes.adBannerCreateMessage}>
              (Time should be integer, more than 1. Time shuould not overlap other banner time)
            </div>

            <div className={classes.adBannerCreateModalButton}>
            <button className="btnSmallBase"
                disabled={isLoading}
                onClick={() => {
                  setShowCreateModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btnSmallBase"
                disabled={isLoading || !isValidStartEnd(startInput, endInput)}
                onClick={() => {
                  addBannerHandler(
                    adHostData.adPlaceId,
                    adHostData.adType,
                    startInput, 
                    endInput,
                  ); }}
              >
                Add
              </button>
            </div>
            {isLoading && ( <Loader />)}
          </div>
        )}
      </div>
    )
  }


  return (
    <Fragment>
      {videoWithAdBannerBody}
    </Fragment>
  );
}

export default AdBannerCreate;

