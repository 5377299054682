import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import  _ from 'lodash';

// import Backdrop from "../Backdrop/Backdrop";
import Loader from "../../Loader/Loader";

// import Modal from "../Modal/Modal";
import AdminPayOutResultListItem from "./AdminPayOutResultListItem";

import { useStore } from "../../../hook-store/store";

// import { 
//   getUserAdHostPayOutResults
// } from "../../../utils/pay-out-result/pay-out-result-util";
import {
  adminGetUserAdHostPayOutResults
} from '../../../utils/admin/admin-pay-out-result/admin-pay-out-result-util';
import { NODEURL } from "../../../App";

import classes from './AdminPayOutResultList.module.css';

function AdminPayOutResultList(props) {

  const [store, dispatch] = useStore();
  // const { userPayOutResults } = store.payOutStore;

  const { 
    adminUserPayOutResults,

    adminSelectedUser,
  } = store.adminStore;


  // const [showAddAddress, setShowAddAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (adminSelectedUser) {
      adminGetUserPayoutResultsHandler(adminSelectedUser.userId);
    }
  },[adminSelectedUser]);

  useEffect(() => {
    return () => {
      dispatch('SET_ADMINUSERPAYOUTRESULTS', []);
    }
  },[]);

  const adminGetUserPayoutResultsHandler = async (userId) => {
    try {
      setIsLoading(true);

      const resData = await adminGetUserAdHostPayOutResults(
        NODEURL,
        localStorage.getItem('token'),
        userId,
      );
  
      console.log(resData);
      
      if (resData.data) {
        // setUserPayoutAddress(resData.data);
        dispatch('SET_ADMINUSERPAYOUTRESULTS', resData.data);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let payOutResultListBody;

  if (adminUserPayOutResults && adminUserPayOutResults.length > 0) {
    
    // const displayList = _.sortBy(userPayoutAddress.addressList, 
    //   [function(o) { return o.coin; }]);
    
    payOutResultListBody = (
      <ul>
        {adminUserPayOutResults.map(payOut => {

          // const isUseAddress = address.id === userPayoutAddress.useAddressId;

          return (
            <li key={payOut._id}
              // style={{border:"1px solid red"}}
            >
              <AdminPayOutResultListItem 
                payOut={payOut}
              />
              {/* <PayoutAddressListItem 
                address={address}
                isUseAddress={isUseAddress}
              /> */}
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <Fragment>
      <div className={classes.payOutResultList}>
        <div
          title={JSON.stringify(adminSelectedUser)}
        >
          Admin-pay-out-result-page, 
          userId: {adminSelectedUser && adminSelectedUser.userId}
          <Link to="/admin/top-page">
            <button>admin-top-page</button>
          </Link>
        </div>
        <div className={classes.payOutResultListTitle}>
          Payout results
        </div>
        {isLoading && (<Loader />)}
        <div>
          {payOutResultListBody}
        </div>
      </div>
    </Fragment>
  );
}

export default AdminPayOutResultList;
