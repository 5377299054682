// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import _ from 'lodash';
// import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useStore } from "../../hook-store/store";
import { 
  getPageNotification,

 } from "../../utils/page-notification/page-notification";

import { NODEURL } from "../../App";

const GetPageNotification = (props) => {
  const { isAuth } = props;

  // const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store in getPageNotification', store);
  
  const { 
    userPageNotification,
    pageNotifyLocalLastOpenTime,
    // pageNotifyNewNotfiyNum,
    // pageNotifyLocalLastGetTime,
  } = store.pageNotificationStore;
  // console.log('store in AdminAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  // const [newNotfiyNum, setNewNotifyNum] = useState(0);
  // const [localLastGetTime, setLocalLastGetTime] = useState(0);

  // const [suggestId, setSuggestId] = useState('');
  // const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    // if (isAuth) {
    // }

    getPageNotificationHandler();
  },[]);

  useEffect(() => {
    if (userPageNotification) {
      const notifyList = userPageNotification.pageNotificationList;
      let newNotifyList = [];

      if (pageNotifyLocalLastOpenTime > 0) {
        newNotifyList = notifyList.filter(element => {
          return element.creationTime > pageNotifyLocalLastOpenTime;
        });
      } else {
        
        newNotifyList = notifyList.filter(element => {
          return element.creationTime > userPageNotification.lastOpenTime;
        });
  
      }

      console.log(newNotifyList);
      dispatch('SET_PAGENOTIFY_NEWNOTIFYNUM', newNotifyList.length);
      
      // if (newNotifyList.length > 0) {
      //   // setNewNotifyNum(newNotifyList.length);
      // }
    }
  },[userPageNotification, pageNotifyLocalLastOpenTime]);




  const getPageNotificationHandler = async () => {
    try {
      // console.log('getPageNotificationHandler');
      setIsLoading(true);
      const resData = await getPageNotification(NODEURL, localStorage.getItem('token'));
      // console.log(adList);

      console.log(resData);
      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);

        // setLocalLastGetTime(Date.now());
        dispatch('SET_PAGENOTIFY_LOCALLASTGETNUM', Date.now());

      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  



  return (
    <Fragment></Fragment>
  );
};

export default GetPageNotification;
