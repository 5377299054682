import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

// import Backdrop from "../Backdrop/Backdrop";
import Loader from "../Loader/Loader";
// import Modal from "../Modal/Modal";
import PayOutResultListItem from "./PayOutResultListItem";

import { useStore } from "../../hook-store/store";

import { 
  getUserAdHostPayOutResults
} from "../../utils/pay-out-result/pay-out-result-util";

import { NODEURL } from "../../App";

import classes from './PayOutResultList.module.css';

function PayOutResultList(props) {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { userPayOutResults } = store.payOutStore;

  // const [showAddAddress, setShowAddAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    getUserPayoutResultsHandler();
  },[]);

  const getUserPayoutResultsHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await getUserAdHostPayOutResults(
        NODEURL,
        localStorage.getItem('token'),
      );
  
      console.log(resData);
      
      if (resData.data) {
        // setUserPayoutAddress(resData.data);
        dispatch('SET_USERPAYOUTRESULTS', resData.data);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let payOutResultListBody;

  if (userPayOutResults && userPayOutResults.length > 0) {
    
    // const displayList = _.sortBy(userPayoutAddress.addressList, 
    //   [function(o) { return o.coin; }]);
    
    payOutResultListBody = (
      <ul>
        {userPayOutResults.map(payOut => {

          // const isUseAddress = address.id === userPayoutAddress.useAddressId;

          return (
            <li key={payOut._id}
              // style={{border:"1px solid red"}}
            >
              <PayOutResultListItem 
                payOut={payOut}
              />
              {/* <PayoutAddressListItem 
                address={address}
                isUseAddress={isUseAddress}
              /> */}
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <Fragment>
      <div className={classes.payOutResultList}>
        <div className={classes.payOutResultListTitle}>
          {t('payOutResult01', 'Payout results')}
        </div>
        {isLoading && (<Loader />)}
        <div>
          {payOutResultListBody}
        </div>
      </div>
    </Fragment>
  );
}

export default PayOutResultList;
