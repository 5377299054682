////  https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks

import { Fragment, useRef, useState, useEffect } from "react";

// import Loader from '../../../Loader/Loader';
import Loader from "../../../Loader/Loader";
import VideoOverElement from "./VideoOverElement";
import VideoAdVolume from "./VideoAdVolume";

import { useStore } from "../../../../hook-store/store";
// import { storeAdDisplay } from "../../../../util/ad/ad-display";
// import { storeAdVideoViewVisit } from "../../../../util/ad/ad-visit";
// import { calcVideoSize, toHHMMSS } from "../../../../util/ad/video-with-ad";
import { calcVideoSize, toHHMMSS } from "../../../../utils/ad-host/video-with-ad-util";

import { NODEURL, ADNETWORKLINK_URL } from "../../../../App";

// import { marks } from "../../../../util/marks";
import { marks } from "../../../../images/marks";

// import BgImage from './black-bg.png';
import TransBgImage from './trans-black-bg2.png';
// import Black5s from './black-bg-5s.mp4';
// import Black2s from './black-bg-2s.mp4';

// const items = [1,3,4,6];
// const items = [
//   {
//     type: 'pre-ad',
//     adElementId: '',
//     canSkip: false,
//     skipMinSec: null,
//     // vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
//     vSrc: `${Black2s}`,
//   },
//   {
//     type: 'ad',
//     adElementId: 'id-of-ad1',
//     canSkip: false,
//     skipMinSec: null,
//     vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
//   },
//   {
//     type: 'ad',
//     adElementId: 'id-of-ad2',
//     canSkip: true,
//     skipMinSec: 3,
//     vSrc: "http://www.w3schools.com/html/mov_bbb.mp4",
//   },
//   {
//     type: '',
//     adElementId: '',
//     canSkip: false,
//     skipMinSec: null,
//     vSrc: "http://www.w3schools.com/html/movie.mp4",
//   },
// ];

const ifHeight = 400;
const ifWidth = 400;

const curUrl = new URL(window.location.href);
const qMute = curUrl.searchParams.get("mute");

const VideoWithAdVideos = (props) => {
  const {
    // ad,
    adType,
    activeList,
    isVisible,
    // setPlayState,
    adPlaceId,

    items,
    setIsVideosStart,
    setIsVideosEnd,
    isPlaying,
    setIsPlaying,
    adHostData,
  } = props;
  // console.log('VideoWithAdVideos-props', props);

  const itemsRef = useRef([]);

  const [store, dispatch] = useStore();
  // console.log('store-in-VideoWithAdVideos', store);
  // const { videoVolume } = store.videoStore;

  // const [selectedItem, setSelectedItem] = useState();
  const [vIndex, setVIndex] = useState(0);

  const [afterMinSec, setAfterMinSec] = useState(false);
  const [preAdPlaying, setPreAdPlaying] = useState(false);

  const [isMute, setIsMute] = useState(true);
  const [videoElStyle, setVideoElStyle] = useState();
  // const [isPlaying, setIsPlaying] = useState(false);

  const [videoDuration, setVideoDuration] = useState(0);

  const [displayedAdList, setDisplayedAdList] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  const [dispId, setDispId] = useState(0);

  const [pipActive, setPipActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // const videosContainerEl = document.getElementById('videos-container');
  // you can access the elements with itemsRef.current[n]


  const defaultVolume = 0.20;

  useEffect(() => {
    if (items) {
      itemsRef.current = itemsRef.current.slice(0, items.length);
      // console.log(itemsRef.current);

      if (itemsRef.current.length > 0) {
        const videoEl = itemsRef.current[items.length - 1];

        videoEl.addEventListener("loadeddata", (event) => {
          // console.log(event, 'loadeddata, video', event.target.duration);
          setVideoDuration(event.target.duration);
        });
      }
    }
  }, [items]);

  useEffect(() => {
    videoPlayHandler();
  }, [itemsRef, vIndex]);

  //// set isLoading until videoDuration loaded
  useEffect(() => {
    setIsLoading(true);

    if (videoDuration) {
      setIsLoading(false);
    }
  }, [videoDuration]);

  useEffect(() => {
    // if (!localStorage.getItem('videoMuteState')) {
    //   setIsMute(false);
    // }

    if (qMute === "1") {
      setIsMute(true);
    } else {
      setIsMute(false);
    }
  }, []);

  useEffect(() => {
    //// store ad view click info
    if (
      afterMinSec &&
      !isClicked &&
      items[vIndex].type === "ad" &&
      items[vIndex].adElementId
    ) {
      adVideoViewClickHandler(
        items[vIndex].adElementId,
        adPlaceId,
        adType,
        dispId,
        "preVideo",
        itemsRef.current[vIndex].currentTime,
        "",
        vIndex
      );
    }
  }, [afterMinSec, isClicked]);

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    const forDispId = Math.floor(
      Number(randomNum.toString() + Date.now().toString())
    );
    // console.log('forDispId', forDispId);
    setDispId(forDispId);
  }, []);

  useEffect(() => {

    if (itemsRef.current.length > 0) {
      // console.log(itemsRef);
      const videoEl = itemsRef.current[vIndex];
      //// calculate video size after video info loaded
      const videosContainerEl = document.getElementById('videos-container');

      setVideoElStyle(
        calcVideoSize(
          videoEl.videoHeight,
          videoEl.videoWidth,
          videosContainerEl.offsetHeight,
          videosContainerEl.offsetWidth,
          isBannerAds
        )
      );
      
      console.log(videoEl.offsetHeight, videoEl.offsetWidth)
    }
  },[window.innerHeight, window.innerWidth]);


  // useEffect(() => {
  //   if (itemsRef.current.length > 0) {
  //     window.addEventListener("devicemotion", (event) => {
  //       console.log(event);
  //       const videoEl = itemsRef.current[vIndex];
  //       //// calculate video size after video info loaded
  //       const videosContainerEl = document.getElementById('videos-container');
  
  //       setVideoElStyle(
  //         calcVideoSize(
  //           videoEl.videoHeight,
  //           videoEl.videoWidth,
  //           videosContainerEl.offsetHeight,
  //           videosContainerEl.offsetWidth,
  //           isBannerAds
  //         )
  //       );
  //     }, true);
  //   }
  // },[itemsRef]);

  // useEffect(() => {
  //   console.log('isVisible', isVisible);
  // },[isVisible]);

  const videoPlayHandler = async () => {
    try {
      if (itemsRef.current.length > 0) {
        if (vIndex < itemsRef.current.length) {
          for (const el of itemsRef.current) {
            el.style.display = "none";
          }

          // console.log(vIndex);
          const currentEl = itemsRef.current[vIndex];

          currentEl.style.display = "block";

          // currentEl.src = "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4";
          // currentEl.src = items[vIndex].vSrc;
          // currentEl.currentTime = 0 //restart the video
          // await currentEl.pause();
          // currentEl.muted = true;

          const videosContainerEl = document.getElementById("videos-container");

          setVideoElStyle(
            calcVideoSize(
              currentEl.videoHeight,
              currentEl.videoWidth,
              videosContainerEl.offsetHeight,
              videosContainerEl.offsetWidth,
              isBannerAds
            )
          );

          if (vIndex > 0) {
            await currentEl.play();
          }



          //// set ad default volume
          if (items[vIndex].type === "ad") {
            currentEl.volume = defaultVolume;
          }

          //// when previous video's volume exist, set that volume
          if (vIndex >= 2) {
            const previousEl = itemsRef.current[vIndex -1];

            if (previousEl && previousEl.volume) {
              currentEl.volume = previousEl.volume;
            }
          }


          if (!isMute) {
            currentEl.muted = false;
          } else {
            currentEl.muted = true;
          }

          currentEl.controls = true;

          if (vIndex === 0 || items[vIndex].type === "ad") {
            currentEl.controls = false;
          }

          //// store video ad display info
          if (items[vIndex].type === "ad" && items[vIndex].adElementId) {
            storeAdDisplayHandler(
              items[vIndex].adElementId,
              adPlaceId,
              items[vIndex],
              dispId,
              "preVideo",
              vIndex
            );
          }

          // playHandler(vIndex);

          currentEl.addEventListener("loadeddata", (event) => {
            console.log(event, "loadeddata", vIndex);

            setVideoElStyle(
              calcVideoSize(
                currentEl.videoHeight,
                currentEl.videoWidth,
                videosContainerEl.offsetHeight,
                videosContainerEl.offsetWidth,
                isBannerAds
              )
            );
          });

          currentEl.addEventListener("play", (event) => {
            console.log(event, "play", vIndex);

            if (items[vIndex].type === "pre-ad") {
              setPreAdPlaying(true);
              currentEl.controls = false;
            }
          });

          currentEl.addEventListener("pause", (event) => {
            console.log(event, "paused", vIndex);

            setIsPlaying(false);

            if (items[vIndex].type === "pre-ad") {
              setPreAdPlaying(false);
              currentEl.controls = true;
            }

            console.log(
              currentEl.videoHeight,
              currentEl.videoWidth,
              (currentEl.videoHeight / currentEl.videoWidth).toFixed(2),
              (ifHeight / ifWidth).toFixed(2),
              vIndex
            );

            console.log(
              calcVideoSize(
                currentEl.videoHeight,
                currentEl.videoWidth,
                videosContainerEl.offsetHeight,
                videosContainerEl.offsetWidth,
                isBannerAds
              )
            );

            // const videoContainerEl = currentEl.parentNode.parentNode;
            // console.log(videosContainerEl.offsetWidth, videosContainerEl.getBoundingClientRect())
          });

          currentEl.addEventListener("ended", (event) => {
            console.log(event, "ended", vIndex);

            if (vIndex === items.length - 1) {
              setIsVideosEnd(true);
            }
          });

          currentEl.addEventListener("volumechange", (event) => {
            console.log(event, "volumechnage", vIndex, currentEl.muted, currentEl.volume);
            setIsMute(currentEl.muted);

            // if (currentEl.muted) {
            //   setInitialMuteState('muted');
            // } else {
            //   setInitialMuteState('');
            // }
          });

          setAfterMinSec(false);
          setIsClicked(false);

          currentEl.addEventListener("timeupdate", (event) => {
            // console.log(currentEl.duration, currentEl.currentTime, currentEl.muted, vIndex);
            setIsPlaying(true);

            if (currentEl.currentTime > items[vIndex].skipMinSec) {
              setAfterMinSec(true);
            }

            setVideoElStyle(
              calcVideoSize(
                currentEl.videoHeight,
                currentEl.videoWidth,
                videosContainerEl.offsetHeight,
                videosContainerEl.offsetWidth,
                isBannerAds
              )
            );

            setIsVideosStart(true);
          });

          currentEl.addEventListener("enterpictureinpicture", (event) => {
            setPipActive(true);
          });

          currentEl.addEventListener("leavepictureinpicture", (event) => {
            setPipActive(false);
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const skipVideoHandler = (index) => {
    if (itemsRef.current.length > 0 && index < items.length - 1) {
      itemsRef.current[index].pause();
      setVIndex(index + 1);
    }
  };

  const pauseHandler = (index) => {
    if (itemsRef.current.length > 0 && index < items.length - 1) {
      itemsRef.current[index].pause();
    }
  };

  const playHandler = (index) => {
    if (itemsRef.current.length > 0 && index < items.length - 1) {
      itemsRef.current[index].play();
    }
  };

  const muteHandler = (index) => {
    if (itemsRef.current.length > 0 && index < items.length - 1) {
      if (itemsRef.current[index].muted) {
        itemsRef.current[index].muted = false;
      } else {
        itemsRef.current[index].muted = true;
      }
    }
  };

  const addVIndexHandler = (index) => {
    if (index < items.length - 1) {
      setVIndex(index + 1);
    }

    // if (index === items.length - 1) {
    //   setVIndex(0);
    // }
  };

  const videoClickHandler = (index, isPlaying) => {
    if (isPlaying) {
      itemsRef.current[index].pause();
    }

    // if (!isPlaying) {
    //   itemsRef.current[index].play();
    // }

    //// restart when not playing and not ad video
    if (!isPlaying && items[vIndex].type !== "ad") {
      itemsRef.current[index].play();
    }

    if (index === 0 && !preAdPlaying) {
      itemsRef.current[index].play();
    }

    //// store ad view click info
    if (isPlaying && items[index].type === "ad" && items[index].adElementId) {
      adVideoViewClickHandler(
        items[index].adElementId,
        adPlaceId,
        adType,
        dispId,
        "preVideo",
        itemsRef.current[index].currentTime,
        "click",
        vIndex
      );
    }
  };

  const canSkipVideo = (index) => {
    if (
      itemsRef.current.length === 0 ||
      items[index].type !== "ad" ||
      items[index].canSkip === false
    ) {
      return false;
    }

    return true;
  };

  //  const setInitialMuteState = (value) => {
  //   localStorage.setItem('videoMuteState', value);
  //  }

  const storeAdDisplayHandler = async (
    adElementId,
    adPlaceId,
    item,
    dispId,
    displayType,
    vIndex
    // bDisplayStart,
    // bIndex,
  ) => {
    // console.log("isVisible", isVisible);
    // // if (isVisible && activeList.length > 0 && ad && adType) {
    // // if (isVisible && activeList.length > 0) {
    // if (activeList.length > 0) {
    //   // console.log('isVisible, displayAd exists ...');

    //   const isInDisplayedAdsList = displayedAdList.find((ad) => {
    //     return ad.adElementId === item.adElementId;
    //   });

    //   if (!isInDisplayedAdsList) {
    //     storeAdDisplay(
    //       NODEURL,
    //       localStorage.getItem("token"),
    //       adElementId,
    //       adPlaceId,
    //       dispId,
    //       displayType,
    //       vIndex
    //     );

    //     const dispAds = displayedAdList;
    //     const addedList = dispAds.concat([item]);
    //     setDisplayedAdList(addedList);

    //     // console.log('addedList', addedList);
    //   }
    // }
  };

  const adVideoViewClickHandler = async (
    adElementId,
    adPlaceId,
    adType,
    dispId,
    displayType,
    videoTime,
    isClick,
    vIndex
  ) => {
    // try {
    //   if (isClicked) {
    //     console.log("already store visit");
    //     return;
    //   }

    //   // if (isVisible && activeList.length > 0 && ad && adType) {
    //   // if (isVisible && activeList.length > 0) {
    //   if (activeList.length > 0) {
    //     setIsClicked(true);

    //     await storeAdVideoViewVisit(
    //       NODEURL,
    //       localStorage.getItem("token"),
    //       adElementId,
    //       adPlaceId,
    //       adType,
    //       dispId,
    //       displayType,
    //       videoTime,
    //       isClick,
    //       vIndex
    //     );
    //   }
    // } catch (err) {
    //   console.log(err);

    //   // if (err.message === 'budget-error' && ad) {
    //   //   //// adlist change (video)
    //   //   const deletedList = videoAdList.filter(vad => {
    //   //     return vad.adElementId !== ad.adElementId;
    //   //   });

    //   //   dispatch('SET_VIDEOADLIST', deletedList);
    //   // }
    // }
  };

  // const clickHandler = (index) => {
  //   // setSelectedItem(itemsRef.current[index]);
  //   setVIndex(index);
  // }

  // return props.items.map((item, i) => (
  //   <div
  //       key={i}
  //       ref={el => itemsRef.current[i] = el}
  //       style={{ width: `${(i + 1) * 100}px` }}
  //   >
  //     ...
  //   </div>
  // ));

  // return items.map((item, i) => (
  //   <div
  //       key={i}
  //       ref={el => itemsRef.current[i] = el}
  //       style={{
  //         width: `${(i + 1) * 100}px`,
  //         backgroundColor: vIndex === i ? 'red' : 'gray'
  //       }}
  //       onClick={() => {clickHandler(i); }}
  //   >
  //     {i} element
  //   </div>
  // ));

  let isFullScreen = false;

  if (window.innerHeight === window.screen.height) {
    isFullScreen = true;
  }

  let isBannerAds = false;

  if (adHostData && adHostData.bannerAds && adHostData.bannerAds.length > 0) {
    isBannerAds = true;
  }

  let videoRect;

  if (itemsRef && itemsRef.current.length > 0) {
    // console.log(itemsRef.current[0].getBoundingClientRect())
    videoRect = itemsRef.current[vIndex].getBoundingClientRect();
  }

  // console.log(videoRect, window.innerWidth, window.innerHeight)

  let videoBody;

  if (items && items.length > 0) {
    videoBody = (
      <ul>
        {items.map((item, i) => {
          let videoUrl = null;

          if (item.token) {
            videoUrl = `${ADNETWORKLINK_URL}?altk=${item.token}`;
          }
          if (!item.token && item.linkUrl) {
            videoUrl = "https://bookremeet.spaceeight.net";
          }

          //// delete link when ad is paused
          // if (items[vIndex].type === "ad" && itemsRef.current[vIndex].paused) {
          //   videoUrl = null;
          // }
          // console.log(item, videoUrl);

          return (
            <a
              key={i}
              // href={item.linkUrl ? item.linkUrl : null}
              href={videoUrl ? videoUrl : null}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <video
                // title={`index = ${i}`}
                // key={i}
                ref={(el) => (itemsRef.current[i] = el)}
                src={item.vSrc}
                muted={isMute}
                style={videoElStyle ? videoElStyle : null}
                // poster={vIndex === 0 
                //   ? items[items.length - 1].thumbnailUrl
                //   : BgImage
                // }
                // poster={vIndex === 0 ? TransBgImage : null}
                // style={createStyle(vIndex, i)}
                onClick={() => {
                  videoClickHandler(vIndex, isPlaying);
                }}
                onEnded={() => {
                  addVIndexHandler(vIndex);
                }}
                // onClick={() => { playHandler(vIndex); }}
              />
            </a>
          );
        })}
      </ul>
    );
  }

  if (!items || items.length === 0) {
    return null;
  }

  // console.log(window.innerHeight, window.innerWidth)

  return (
    <Fragment>
      {/* <div 
      // style={{width: `${ifWidth}px`, height:`${ifHeight}px`, border:"1px solid red"}}
    > */}
      <div
        id="videos-container"
        // style={{ position: 'relative', height: "100%", width: "100%"}}
        // style={{ position: 'relative', height: `${window.innerHeight*0.99}px`, width: `${window.innerWidth*0.99}px`, border:"1px solid rgba(0, 0, 0, 0.01)"}}
        style={{
          position: "relative",
          // height: `${windowValues.height * 0.99}px`,
          // width: `${windowValues.width * 0.99}px`,
          height: `${window.innerHeight * 0.99}px`,
          width: `${window.innerWidth * 0.99}px`,
          border: "1px solid rgba(0, 0, 0, 0.01)",
        }}
        // style={{ position: 'relative', height: `150px`, width: `150px`, border:"1px solid red"}}
      >
        {videoBody}

        {videoRect && canSkipVideo(vIndex) && afterMinSec && (
          <button
            style={{
              position: "absolute",
              // top:"50%",
              top: `${videoRect.top + videoRect.height / 2}px`,
              right: "0px",
              zIndex: "100",
              // height: "15%",
              minHeight: "50px",
              maxHeight: "75px",
              opacity: "0.7",
              background: "black",
              color: "white",
              border: "0.1px solid gray",
            }}
            // disabled={
            //   itemsRef.current.length === 0 ||
            //   items[vIndex].type !== 'ad' ||
            //   items[vIndex].canSkip === false
            // }
            disabled={!canSkipVideo(vIndex)}
            onClick={() => {
              skipVideoHandler(vIndex);
            }}
          >
            Skip Ad
          </button>
        )}

        {isLoading && (
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <Loader />
          </span>
        )}

        {videoRect && preAdPlaying && (
          <span
            style={{
              position: "absolute",
              // top:"50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              left: `${
                videoRect.left + (videoRect.right - videoRect.left) / 2 - 30
              }px`,
              top: `${
                videoRect.top + (videoRect.bottom - videoRect.top) / 2 - 30
              }px`,
            }}
          >
            <Loader />
          </span>
        )}

        {videoRect && items[vIndex].type === "ad" && (
          <span
            style={{
              position: "absolute",
              // top: "0px",
              // right: `${videoRect.right}`,
              top: `${videoRect.top}px`,
              left: "0",
              zIndex: "100",
              // background:"gray",
              opacity: "0.6",
              background: "black",
              color: "white",
            }}
          >
            <div style={{display: "flex", gap: "10px", alignItems:"start"}}>
              <span>Ad</span>
              <span style={{ fontSize: "12.5px" }}>
                {toHHMMSS(
                  itemsRef.current[vIndex].duration -
                    itemsRef.current[vIndex].currentTime
                )}
                {/* {(itemsRef.current[vIndex].duration - itemsRef.current[vIndex].currentTime).toFixed()} */}
              </span>
              <span
                // style={{ fontSize: "2rem", cursor: "pointer", width:"100px" }}
                // onClick={() => {
                //   muteHandler(vIndex);
                // }}
              >
                {/* {isMute && <span>{marks.volumeUpMark}</span>}
                {!isMute && <span>{marks.volumeOffMark}</span>} */}
                <VideoAdVolume 
                  items={items}
                  itemsRef={itemsRef}
                  vIndex={vIndex}
                  isMute={isMute}
                  defaultVolume={defaultVolume}
                  muteHandler={muteHandler}
                />
              </span>
            </div>
          </span>
        )}



        {videoRect && videoDuration > 0 && vIndex === 0 && !preAdPlaying && (
          <img 
            style={{
              position: "absolute",
              height: `${videoRect.height}px`,
              width: `${videoRect.width}px`,
              bottom: 0,
              right: 0,
              left: 0,
              margin: '0 auto',
              objectFit: 'cover',
              // border: "1px solid red"
            }}
            src={items[items.length - 1].thumbnailUrl}
            alt="thumbnail"
            onClick={() => {
              playHandler(vIndex);
            }}
          />
        )}
        

        {videoRect && videoDuration > 0 &&
          ((vIndex === 0 && !preAdPlaying) ||
            (items[vIndex].type === "ad" &&
              itemsRef.current[vIndex].paused)) && (
            <div
              style={{
                position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                left: `${
                  videoRect.left + (videoRect.right - videoRect.left) / 2 - 50
                }px`,
                top: `${
                  videoRect.top + (videoRect.bottom - videoRect.top) / 2 - 40
                }px`,
                cursor: "pointer",
                fontSize: "60px",
                color: "gray",
                opacity: "0.7",
                padding: "10px 20px",
                background: "white",
                borderRadius: "20px",
                zIndex:"100",
              }}
              onClick={() => {
                playHandler(vIndex);
              }}
            >
              {marks.playMark}
            </div>
          )}

        {videoRect && videoDuration > 0 && vIndex === 0 && !preAdPlaying && (
          <div
            style={{
              position: "absolute",
              // right: "5%",
              // bottom: "20%",
              left: `${videoRect.left + 20}px`,
              top: `${videoRect.bottom - 30}px`,
              color: "black",
              background: "white",
              opacity: 0.6,
              fontSize: "16px",
              fontWeight: "bold",
              maxHeight: "18px",
              padding: "0 2px",
            }}
          >
            {toHHMMSS(videoDuration)}
            {/* {toHHMMSS(60000)} */}
          </div>
        )}

        {!items[vIndex].type && (
          <div>
            <VideoOverElement
              items={items}
              itemsRef={itemsRef}
              vIndex={vIndex}
              isPlaying={isPlaying}
              adPlaceId={adPlaceId}
              dispId={dispId}
              adHostData={adHostData}
              videoRect={videoRect}
              pipActive={pipActive}
              isFullScreen={isFullScreen}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default VideoWithAdVideos;
