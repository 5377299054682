import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import i18n from '../../i18n';
import LanguageList from '../../translation/LanguageList';
import Modal from '../../components/Modal/Modal';
import Backdrop from '../../components/Backdrop/Backdrop';

import { useStore } from "../../hook-store/store";

import { getUserData } from "../../utils/user/user";
import { resources } from '../../translation/translation';

import { 
  NODEURL,
  USERACCOUNTPAGE_URL,
 } from "../../App";

import { marks } from "../../images/marks";
import classes from './UserInfo.module.css';


const UserInfo = (props) => {
  // const { } = props;

  const selectedLanguage = resources[i18n.language].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const { 
    isAdmin, 
    userData, 
    userNameData, 
  } = store.uiStore;

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  useEffect(() => {
    if (!userData) {
      getUserDataHandler(NODEURL, localStorage.getItem('token'));
    }
  }, []);

  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  const getUserDataHandler = async(url, token) => {
    try {
      const result = await getUserData(url, token);

      console.log(result);

      if (result.data) {
        dispatch('SET_USERDATA', result.data);
        // setUserData(result.data);

        if (result.data.status === 'admin') {
          dispatch('SET_ISADMIN', true);
        }
      }
    } catch(err) {
      console.log(err);
    }
  };


  const languageModalBody = (
    <div>
      <Backdrop onCancel={showLanguageModalHandler} />
      <Modal
        onCancel={showLanguageModalHandler}
      >
        <LanguageList 
          showLanguageModalHandler={showLanguageModalHandler}
          // getLanguageNameHandler={getLanguageNameHandler}
          // i18n={props.i18n}
        />
      </Modal>
    </div>
  );


  let userInfoBody;

  if (userData) {
    userInfoBody = (
      <div className={classes.userInfoContent}>
        {userNameData && (
          <div className={classes.userInfoItem}>
            name 
            {' '}
            <strong>{userNameData.name}</strong>
            <a
              href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
              target="_blank" rel="noopener noreferrer"
            >
              <button className="btnSmallBase">
                edit  {marks.externalLinkMark}
              </button>
            </a>
          </div>
        )}
        
        <div className={classes.userInfoItem}>
          {t('userInfo.text16', 'Email address')} <strong>{userData.email}</strong>
        </div>

        {userNameData && (
          <div className={classes.userInfoItem}>
              user image 
              {userNameData.imageUrl && (
                <img className={classes.userInfoUserImage}
                  src={userNameData.imageUrl} 
                />
              )}
              {!userNameData.imageUrl && (
                <span className={classes.userInfoUserNoImage}>
                  {marks.userMark}
                </span>
              )}
              {' '}
              <a
                href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
                target="_blank" rel="noopener noreferrer"
              >
                <button className="btnSmallBase">
                  ({t('general.text5', 'edit')}) {marks.externalLinkMark}
                </button>
              </a>
          </div>
        )}

        <div className={classes.userInfoItem}>
          {t('userInfo.text17', 'Creation time')}
          {' '}
          <strong>{new Date(userData.createdAt).toLocaleString()}</strong>
          {/* <strong>{moment(userData.createdAt).format("YYYY-MM-DD")}</strong> */}
        </div>
        <div className={classes.userInfoItem}
          style={{ display: 'flex', gap: "1rem", alignItems: "center"}}
        >
          <span>
            {t('userInfo.text11', 'Language')}
          </span>
          <span>
            <strong>{selectedLanguage}</strong>
          </span>
          <span>
            <button className="btnSmallBase"
              // disabled={descriptionInput ? descriptionInput.length > 300 : null}
              onClick={showLanguageModalHandler}
            >
              {t('userInfo.text12', 'Change')}
            </button>
          </span>
        </div>
      </div>
    );
  }
 

  return <Fragment>
    <div className="pageContentContainer">

      {/* <Link to="/page-notification">page-notify-page</Link> */}


      {isAdmin && (
        <div>
          <h2>admin-user</h2>
          <Link to="/admin/top-page">admin-top-page</Link>
        </div>
      )}

      {userInfoBody}

      {showLanguageModal && (
        <div>{languageModalBody}</div>
      )}
    </div>
  </Fragment>;
};

export default UserInfo;
