// import _ from 'lodash';

import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ADHOSTRESULT_DATALIST: (state, payload) => {
      return {
        // ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          adHostResultDataList: payload,
        }
      };
    },
    // SET_SEARCH_SELECTEDITEM: (state, payload) => {
    //   return {
    //     // ...state,
    //     adHostResultStore: {
    //       ...state.adHostResultStore,
    //       searchSelectedItem: payload,
    //     }
    //   };
    // },
    SET_LIST_FORTABLE:(state, payload) => {
      return {
        ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          listForTable: payload,
        }
      };
    },
    // SET_SELECTED_ADPLACEID:(state, payload) => {
    //   return {
    //     ...state,
    //     adHostResultStore: {
    //       ...state.adHostResultStore,
    //       selectedAdPlaceId: payload,
    //     }
    //   };
    // },
    // SET_SELECTED_ADPLACEIDLIST:(state, payload) => {
    //   return {
    //     ...state,
    //     adHostResultStore: {
    //       ...state.adHostResultStore,
    //       selectedAdPlaceIdList: payload,
    //     }
    //   };
    // },
    SET_DURATION:(state, payload) => {
      return {
        ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          duration: payload,
        }
      };
    },
    SET_TOTAL_RESULT:(state, payload) => {
      return {
        ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          totalResult: payload,
        }
      };
    },
    // SET_RESULTSEARCHITEM:(state, payload) => {
    //   return {
    //     ...state,
    //     adHostResultStore: {
    //       ...state.adHostResultStore,
    //       resultSearchItem: payload,
    //     }
    //   };
    // },
    SET_RESULTSEARCHSELECTID:(state, payload) => {
      return {
        ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          resultSearchSelectId: payload,
        }
      };
    },
    SET_ISRESULTSEARCHRESULTS:(state, payload) => {
      return {
        ...state,
        adHostResultStore: {
          ...state.adHostResultStore,
          isResultSearchResults: payload,
        }
      };
    },
  };

  initStore(actions, {
    adHostResultStore: {
      adHostResultDataList: [],
      listForTable: [],
      duration: 10,
      totalResult: {
        visitNum: 0,
        displayNum: 0,
        charge: 0,
        payOut: 0,
      },
      resultSearchSelectId: null,
      isResultSearchResults: false,
      // searchSelectedItem: null,
      // selectedAdPlaceId: '',
      // selectedAdPlaceIdList: [],
    }
  });
};

export default configureStore;
