// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdminAdHostList from '../../../components/Admin/AdminAdHostList/AdminAdHostList';
import { useStore } from '../../../hook-store/store';


const AdminAdHostListPage = props =>  {

  const [t] = useTranslation('translation');

  // const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
      <AdminAdHostList />
    </Fragment>
  );
}
export default AdminAdHostListPage;

