// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AdminUpdateAdStatus from './AdminUpdateAdStatus';
import Loader from '../../../Loader/Loader';


import { useStore } from '../../../../hook-store/store';
// import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';
// import { getUserAdHostsForAdmin } from '../../../../utils/admin/admin-ad-host-util';
// import { NODEURL } from '../../../../App';

import classes from './AdminAdHostListItem.module.css';

const AdminAdHostListItem = props =>  {
  const { 
    data 
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    usersForAdmin,  
    adminSelectedUser,
    adHostsForAdmin
  } = store.adminStore;
  // const { isAuth } = store.uiStore;

  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  let adminAdHostListItemBody;

  if (data) {
    adminAdHostListItemBody = (
      <div className={classes.adhostListItemContainer}>
        <div className={classes.adhostListItemElement}>
          adPlaceId: <strong>{data.adPlaceId}</strong>
        </div>
        <div className={classes.adhostListItemElement}>
          Host: <strong>{data.host}</strong>
        </div>
        <div className={classes.adhostListItemElement}>
          Ad type: <strong>{data.adType}</strong>
          </div>
        <div className={classes.adhostListItemElement}>
          Creation time: <strong>{new Date(data.createdAt).toLocaleString()}</strong>
        </div>
        <div className={classes.adhostListItemElement}>
          user-id: <strong>{data.userId}</strong>
        </div>

        <div className={classes.adhostListItemElement}>
          <AdminUpdateAdStatus 
            data={data}
          />
        </div>

        {data.adType === 'videoWithAd' && (
          <div>
            <div>video-with-ad-detail</div>
          <video 
            style={{maxWidth: "250px", maxHeight:"200px"}}
            src={data.uploadVideoUrl}
            controls
          />
          <img 
            style={{maxHeight:"150px", maxWidth:"150px"}}
            src={data.thumbnailUrl}
            alt='thumbnail' 
          />
          </div>
        )}
      </div>
    );
  }
    
  
  return (
    <Fragment>
      <div>{adminAdHostListItemBody}</div>
      {/* {isLoading && <Loader />} */}
    </Fragment>
  );
}

export default AdminAdHostListItem;

