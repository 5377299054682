import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';

import configureUiStore from './hook-store/ui-store';
// import configureAdStore from './hook-store/ad-store';
import configureAdHostStore from './hook-store/ad-host-store';
import configureAdHostResultStore from './hook-store/ad-host-result-store';
import configureVideoWithAdStore from './hook-store/video-with-ad-store';
import configurePayOutStore from './hook-store/pay-out-store';
import configureAdminStore from './hook-store/admin-store';
import configurePageNotificationStore from './hook-store/page-notification';

configureUiStore();
// configureAdStore();
configureAdHostStore();
configureAdHostResultStore();
configureVideoWithAdStore();
configurePayOutStore();
configureAdminStore();
configurePageNotificationStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
