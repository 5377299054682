import { Fragment } from "react";
// import { useEffect, useState } from 'react';
// import { BrowserRouter as Switch, Route, useHistory, Link } from "react-router-dom";

// import BucketObjectAllList from './components/BucketObjectAllList';
import { useStore } from "../../hook-store/store";

import { loginPageLink, signupPageLink } from "../../App";

import classes from './AuthRequired.module.css';

function AuthRequired() {
  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;

  return (
    <Fragment>
      <div className={classes.authRequired}>
        <div>
          Login is required or signup
        </div>
        <div>
          <a href={loginPageLink}>
            <button className="btnBase">
              Login
            </button>
          </a>
          <span> or </span>
          <a href={signupPageLink}>
            <button className="btnBase">
              Signup
            </button>
          </a>
        </div>
      </div>
    </Fragment>
  );
}

export default AuthRequired;
