// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  const title = `Adnetwork Ad host - ${t('headMeta01', 'display various ads in your site')}`;

  const description = `Adnetwork Ad host by SpaceEight ${t('headMeta02', 'enables you to display ads in Your website. As types of ad, there are banner ad, and video with ad.')}`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;