import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from "../../Backdrop/Backdrop";
import Loader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";

import { useStore } from "../../../hook-store/store";

import { 
  updateUserPayOutUseAddressName,
} from "../../../utils/pay-out-address/pay-out-address-util";

import { NODEURL } from "../../../App";

import { marks } from "../../../images/marks";

import classes from './PayOutAddressListItem.module.css';

function PayOutAddressNameUpdate(props) {
  const { 
    address,
    // isUseAddress,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { userPayOutAddress } = store.payoutStore;

  const [nameInput, setNameInput] = useState('');
  const [showNameUpdateModal, setShowNameUpdateModal] = useState(false);
  // const [addressClicked, setAddressClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // const addressClickeHandler = () => {
  //   if (!addressClicked) {
  //     setAddressClicked(true);
  
  //     setTimeout(() => {
  //       setAddressClicked(false);
  //     },3000);
  //   }
  // };

  const updatePayOutAddressNameHandler = async (id, name) => {
    try {
      setIsLoading(true);

      const resData = await updateUserPayOutUseAddressName(
        NODEURL,
        localStorage.getItem('token'),
        id,
        name,
      );
  
      console.log(resData);
      
      if (resData.data) {
        dispatch('SET_USERPAYOUTADDRESS', resData.data);
        setShowNameUpdateModal(false);
        setNameInput('');

        dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'updated',
            message: '',
          }
        );

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000*5);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };




  let payOutAddressNameUpdateBody;

  if (address) {
    payOutAddressNameUpdateBody = (
      <div className={classes.payOutAddressListItemDelete}>
        <button className="btnSmallBase"
          disabled={isLoading}
          onClick={() => {
            setShowNameUpdateModal(true);
            setNameInput('');
          }}
        >
          {t('payOutAddress16', 'Update nickname')}
        </button>
      </div>
    );
  }

  return (
    <Fragment>
      {isLoading && (<Loader />)}
      {payOutAddressNameUpdateBody}

      {showNameUpdateModal && (
        <div>
          <Backdrop 
            onCancel={() => { setShowNameUpdateModal(false); }}
          />
          <Modal
             onCancel={() => { setShowNameUpdateModal(false); }}
             isLoading={isLoading}
          >
            <div>
              <div>
                <div>
                  <label>
                    {t('payOutAddress17', 'New nickname input')}
                  </label>
                </div>
                <input className="inputBase"
                  style={{width: "100%"}}
                  type="text"
                  value={nameInput}
                  placeholder="up to 100 characters"
                  onChange={(event) => { setNameInput(event.target.value); }}
                />
              </div>
              <div className={classes.payOutAddressListItemDeleteButtons}>
                <button className="btnBase"
                  disabled={isLoading}
                  onClick={() => {
                    setShowNameUpdateModal(false);
                  }}
                >
                  {t('payOutAddress08', 'Cancel')}
                </button>
                <button className="btnBase"
                  disabled={isLoading || nameInput.trim().length > 100}
                  onClick={() => {
                    updatePayOutAddressNameHandler(address.id, nameInput);
                  }}
                >
                  {t('payOutAddress18', 'Update')}
                </button>
              </div>
              {isLoading && (<Loader />)}
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

export default PayOutAddressNameUpdate;
