// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../../components/Loader/Loader";

import { useStore } from "../../../hook-store/store";
import { adminUpdateUserAccountStatus } from "../../../utils/admin/admin-user-util";

import { NODEURL } from "../../../App";


// import classes from './AdminAdElements.module.css';

const AdminAccountStatus = (props) => {
//   console.log('AdminTopPage.js-props', props);
  const { userId } = props;

//   const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { usersForAdmin } = store.adminStore;
//   const adminSelectedUser = store.adminStore.adminSelectedUser;

  const [statusInput, setStatusInput] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {

//     console.log(statusInput);
//   },[statusInput]);


  const updateAccountStatusHandler = async (newStatus, userId) => {
    try {

      setIsLoading(true);

      const resData = adminUpdateUserAccountStatus(
        NODEURL,
        localStorage.getItem('token'),
        newStatus,
        userId,
      );

      if (resData) {
        const userList = usersForAdmin;

        const userIndex = userList.findIndex((el) => {
          return el.userId === userId;
        });

        userList[userIndex].accountStatus = newStatus;
        // console.log(adList);
        dispatch('SET_USERS_FORADMIN', userList);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

 
  return (
    <Fragment>
      <div>admin-update-account-status-content</div>
      <div>
            <span>account-status-choice: </span>
            <select 
            // className={classes.tableControlSelect}
              // // value={pageSize}
              // defaultValue={adStatus}
              onChange={e => {
                // console.log(e.target.value);
                setStatusInput(e.target.value);
                setShowConfirm(false);
              }}
            >
              {['', 'as1', 'as2', 'as-other', 'disabled' ].map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
            <button
              disabled={isLoading}
              onClick={() => { setShowConfirm(true); }}            
            >
                update-status
            </button>
            {showConfirm && (
              <div>
                <div>confirm-account-status-update: {statusInput}</div>
                <button 
                  disabled={isLoading}
                  onClick={() => { setShowConfirm(false); }}
                >
                  cancel
                </button>
                <button 
                  disabled={isLoading}
                  onClick={() => { updateAccountStatusHandler(statusInput, userId);}}
                >
                  update
                </button>
              </div>
            )}
            {isLoading && <Loader />}
          </div>
    </Fragment>
  );
};

export default AdminAccountStatus;
