// import _ from 'lodash';

import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ADHOST_DATALIST: (state, payload) => {
      return {
        // ...state,
        adHostStore: {
          ...state.adHostStore,
          adHostDataList: payload,
        }
      };
    },
    SET_SEARCH_SELECTEDITEM: (state, payload) => {
      return {
        // ...state,
        adHostStore: {
          ...state.adHostStore,
          searchSelectedItem: payload,
        }
      };
    },
    SET_STORESELECTEDADHOSTDATA: (state, payload) => {
      return {
        // ...state,
        adHostStore: {
          ...state.adHostStore,
          storeSelectedAdHostData: payload,
        }
      };
    },
    SET_THUMBNAILURLLIST: (state, payload) => {
      return {
        // ...state,
        adHostStore: {
          ...state.adHostStore,
          thumbnailUrlList: payload,
        }
      };
    },
  };

  initStore(actions, {
    adHostStore: {
      adHostDataList: [],
      searchSelectedItem: null,
      storeSelectedAdHostData: null,
      thumbnailUrlList: [],
    }
  });
};

export default configureStore;
