// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdminTopPageContent from '../../../components/Admin/AdminTopPageContent/AdminTopPageContent';
import { useStore } from '../../../hook-store/store';


const AdminTopPage = props =>  {

  const [t] = useTranslation('translation');

  // const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
      <AdminTopPageContent />
    </Fragment>
  );
}
export default AdminTopPage;

