import React, { Fragment, useEffect, useState} from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { useStore } from '../../hook-store/store';

import classes from './AdHostResultSearch.module.css';

const searchBoxStyle = {
  height: "44px",
  height: "2rem",
  border: "1px solid #dfe1e5",
  // border: "1px solid gray",
  // borderRadius: "24px",
  borderRadius: "6px",
  backgroundColor: "white",
  boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
  hoverBackgroundColor: "#eee",
  color: "#212121",
  // fontSize: "16px",
  fontSize: "1rem",
  fontFamily: "Arial",
  iconColor: "grey",
  lineColor: "rgb(232, 234, 237)",
  placeholderColor: "grey",
  // clearIconMargin: '3px 14px 0 0',
  clearIconMargin: '3px 5px 0 0',
  // searchIconMargin: '0 0 0 16px',
  searchIconMargin: '0 0 0 5px',
};

function AdHostResutSearch() {

  // const chartRef = useRef(null);
  // const gridRef = React.createRef();

  const [store, dispatch] = useStore();
  const { adHostResultDataList } = store.adHostResultStore;
  // const { showToggleNav } = store.uiStore;

  const list = adHostResultDataList.map((element, index) => {
    return {
      ...element,
      id: index,
    }
  });

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    console.log(string, results)
    if (!string) {
      // setSearchSelectedItem(null);
      dispatch('SET_RESULTSEARCHSELECTID', '');
    }

    if (results.length > 0) {
      dispatch('SET_ISRESULTSEARCHRESULTS', true);
    } else {
      dispatch('SET_ISRESULTSEARCHRESULTS', false);
    }
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result);
  }

  const handleOnSelect = (item) => {
    // the item selected
    console.log('selected item: ', item);
    // setSearchSelectedItem(item);
    dispatch('SET_RESULTSEARCHSELECTID', item.adPlaceId);
    dispatch('SET_ISRESULTSEARCHRESULTS', false);
  }

  const handleOnFocus = () => {
    console.log('Focused')
    // console.log(items);
  }

  const handleOnClear = () => {
    console.log('Cleared')
    // console.log(items);
    // setSearchSelectedItem(null);
    dispatch('SET_RESULTSEARCHSELECTID', '');
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem", whiteSpace: "normal" }}>
          <strong>
            adPlaceId: 
            <br />
            {item.adPlaceId}
          </strong>
        </span>
        {/* <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem" }}>
          host: {item.adHostData.host}
        </span> */}
        <span style={{ display: 'block', textAlign: 'left', paddingBottom: "0.25rem" }}>
          ad type: {item.adHostData.adType}
        </span>
        {/* <span style={{ display: 'block', textAlign: 'left',paddingBottom: "0.5rem" }}>type: <strong>{item.adType}</strong></span> */}
      </>
    )
  }


  return (
    <Fragment>
        <div className={classes.adHostResultSearch}
          // style={{ width: 400 }}
        >
          <ReactSearchAutocomplete
            // items={items}
            items={list}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            // autoFocus
            formatResult={formatResult}

            // fuseOptions={{ keys: ["host", "adType"] }}
            // resultStringKeyName="id"

            fuseOptions={{ keys: ["adPlaceId", "adHostData.adType"] }}
            resultStringKeyName="adPlaceId"

            placeholder="search adPlaceId or ad type"
            styling={searchBoxStyle}
            showIcon={false}
            // maxResults={10}
          />
        </div>
    </Fragment>
  );
}

export default AdHostResutSearch;