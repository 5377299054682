import { Fragment, useState, useEffect } from "react";
import { Link } from 'react-router-dom' 
import  _ from 'lodash';

// import AddPayOutAddress from "../AddPayOutAddress/AddPayOutAddress";
import Backdrop from "../../Backdrop/Backdrop";
import Loader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import PayOutAddressListItem from "./AdminPayOutAddressListItem";

import { useStore } from "../../../hook-store/store";

// import { 
//   getUserAdHostPayOutAddress,
// } from "../../../utils/pay-out-address/pay-out-address-util";

import {
  adminGetUserAdHostPayOutAddress
} from '../../../utils/admin/admin-pay-out/admin-pay-out-address-util';

import { NODEURL } from "../../../App";

import classes from './AdminPayOutAddressList.module.css';


function AdminPayOutAddressList(props) {

  const [store, dispatch] = useStore();
  // const { userPayOutAddress } = store.payOutStore;

  const {
    adminUserPayOutAddress,

    adminSelectedUser,
  } = store.adminStore;

  const [showAddAddress, setShowAddAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (adminSelectedUser) {
      adminGetUserPayOutAddressHandler(adminSelectedUser.userId);
    }
  },[adminSelectedUser]);


  useEffect(() => {
    return () => {
      dispatch('SET_ADMINUSERPAYOUTADDRESS', null);
    }
  },[]);

  const adminGetUserPayOutAddressHandler = async (userId) => {
    try {
      setIsLoading(true);

      const resData = await adminGetUserAdHostPayOutAddress(
        NODEURL,
        localStorage.getItem('token'),
        userId,
      );
  
      console.log(resData);
      
      if (resData.data) {
        // setUserPayoutAddress(resData.data);
        dispatch('SET_ADMINUSERPAYOUTADDRESS', resData.data);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let payOutAddressListBody;

  if (adminUserPayOutAddress && adminUserPayOutAddress.addressList.length > 0) {
    
    const displayList = _.sortBy(adminUserPayOutAddress.addressList.reverse(), 
      [function(o) { return o.coin; }]);

    payOutAddressListBody = (
      <ul>
        {displayList.map(address => {

          const isUseAddress = address.id === adminUserPayOutAddress.useAddressId;

          return (
            <li key={address.id}
              // style={{border:"1px solid red"}}
            >
              <PayOutAddressListItem 
                address={address}
                isUseAddress={isUseAddress}
              />
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <Fragment>
      <div className={classes.payOutAddressList}>
        <div 
          title={JSON.stringify(adminSelectedUser)}
        >
          admin-pay-out-address-page,
          userId: {adminSelectedUser && adminSelectedUser.userId}
          <Link to="/admin/top-page">
            <button>admin-top-page</button>
          </Link>
        </div>
        {/* <div>payout-address-component</div> */}

        <section className={classes.payOutAddressListAdd}>
          {/* <div>
            <button className="btnBase"
              disabled={isLoading}
              onClick={() => { setShowAddAddress(true); }}
            >
              Add new address
            </button>
          </div> */}

          {showAddAddress && (
            <div>
              <Backdrop 
                onCancel={() => { setShowAddAddress(false); }}
              />
              <Modal
                onCancel={() => { setShowAddAddress(false); }}
                isLoading={isLoading}
              >
                {/* <AddPayOutAddress 
                  setShowAddAddress={setShowAddAddress}
                /> */}
              </Modal>
            </div>
          )}
        </section>

        <section>
          {isLoading && (<Loader />)}
          <div className={classes.payOutAddressListList}>
            {payOutAddressListBody}
          </div>
        </section>

      </div>
    </Fragment>
  );
}

export default AdminPayOutAddressList;
