// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AdminPayOutResultList from '../../../components/Admin/AdminPayOutResult/AdminPayOutResultList';

import { useStore } from '../../../hook-store/store';

// import classes from './AdminAdHostResultPage.module.css';

const AdminPayOutResultPage = props =>  {

  const [t] = useTranslation('translation');

  // const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
      <AdminPayOutResultList />
    </Fragment>
  );
}
export default AdminPayOutResultPage;

