// const React = window.React = require('react');
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "../../../Loader/Loader";

import { useStore } from "../../../../hook-store/store";
// import { getUsersForAdmin } from '../../../utils/admin/admin-user-util';
// import { getUserAdHostsForAdmin } from "../../../../utils/admin/admin-ad-host-util";
import { adminUpdateAdStatus } from "../../../../utils/admin/admin-ad-host-util";

import { NODEURL } from "../../../../App";

import classes from "./AdminAdHostListItem.module.css";

const AdminUpdateAdStatus = (props) => {
  const { data } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { usersForAdmin, adminSelectedUser, adHostsForAdmin } =
    store.adminStore;
  // const { isAuth } = store.uiStore;

  const [adStatusChoice, setAdStatusChoice] = useState("");
  const [statusReason, setStatusReason] = useState("");
  const [statusComment, setStatusComment] = useState("");
  const [showStatusControl, setShowStatusControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // console.log('store in SingleAdElement.js', store);

  const statusDetailData = {
    status: adStatusChoice,
    reason: statusReason,
    comment: statusComment,
  };


  const adminUpdateAdStatusHandler = async (adPlaceId, adStatus, adStatusDetail) => {
    try {
      setIsLoading(true);

      if (!adStatus) {
        throw new Error('adStatus is required');
      }

      const resData = await adminUpdateAdStatus(
        NODEURL, 
        localStorage.getItem('token'), 
        adPlaceId, 
        adStatus, 
        adStatusDetail
      )

      console.log(resData);

      const adList = adHostsForAdmin;

      const adIndex = adList.findIndex((el) => {
        return el.adPlaceId === adPlaceId;
      });

      adList[adIndex].adStatus = resData.adStatus;
      adList[adIndex].adStatusDetail = resData.adStatusDetail;
      // console.log(adList);
      dispatch('SET_ADHOSTS_FORADMIN', adList);

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
    
  };

  let adminUpdateAdStatusBody;

  if (data) {
    adminUpdateAdStatusBody = (
      <div>
        <div>ad-status: {data.adStatus}</div>
        <button 
          onClick={() => { setShowStatusControl(!showStatusControl); }}
        >
          update-adStatus-by-admin
        </button>

        {showStatusControl && (
          <div>
            <div>stored-detail: {JSON.stringify(data.adStatusDetail)}</div>
            <div>
              <span>status-choice: </span>
              <select
                className={classes.tableControlSelect}
                // // value={pageSize}
                // defaultValue={adStatus}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setAdStatusChoice(e.target.value);
                }}
              >
                {["", "ok", "not-approved"].map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <span>reason-choice: </span>
              <select
                className={classes.tableControlSelect}
                // // value={pageSize}
                // defaultValue={adStatus}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setStatusReason(e.target.value);
                }}
              >
                {["", "reason1", "reason2", "reason3", "reason4"].map(
                  (status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  )
                )}
              </select>
            </div>
            <div>
              <span>comment-input:</span>
              <input
                type="text"
                onChange={(event) => {
                  setStatusComment(event.target.value);
                }}
              />
            </div>
            <div>
              {adStatusChoice}, {statusReason} {statusComment}
            </div>
            <div>
                <button
                  disabled={isLoading}
                  onClick={() => {
                    adminUpdateAdStatusHandler(
                      data.adPlaceId,
                      adStatusChoice,
                      statusDetailData,
                    );
                  }}
                >
                    change-status
                </button>
              </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <div>{adminUpdateAdStatusBody}</div>
      {isLoading && <Loader />}
    </Fragment>
  );
};

export default AdminUpdateAdStatus;
