import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ADHOSTS_FORADMIN:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adHostsForAdmin: payload,
        }
      };
    },
    SET_LISTFORADMIN_SEARCHAD:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          listForAdminSearchAd: payload,
        }
      };
    },
    SET_USERS_FORADMIN:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          usersForAdmin: payload,
        }
      };
    },
    SET_ADMIN_SELECTEDUSER:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminSelectedUser: payload,
        }
      };
    },
    SET_ADMIN_SEARCHEDADHOST:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminSearchedAdHost: payload,
        }
      };
    },

    SET_ADMINADHOSTRESULT_DATALIST: (state, payload) => {
      return {
        // ...state,
        adminStore: {
          ...state.adminStore,
          adminAdHostResultDataList: payload,
        }
      };
    },
    SET_ADMINLIST_FORTABLE:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminListForTable: payload,
        }
      };
    },
    SET_ADMINDURATION:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminDuration: payload,
        }
      };
    },
    SET_ADMINTOTAL_RESULT:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminTotalResult: payload,
        }
      };
    },
    SET_ADMINRESULTSEARCHSELECTID:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminResultSearchSelectId: payload,
        }
      };
    },
    SET_ADMINISRESULTSEARCHRESULTS:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminIsResultSearchResults: payload,
        }
      };
    },

    SET_ADMINUSERPAYOUTADDRESS: (state, payload) => {
      return {
        // ...state,
        adminStore: {
          ...state.adminStore,
          adminUserPayOutAddress: payload,
        }
      };
    },
    SET_ADMINUSERPAYOUTRESULTS: (state, payload) => {
      return {
        // ...state,
        adminStore: {
          ...state.adminStore,
          adminUserPayOutResults: payload,
        }
      };
    },

  };

  initStore(actions, {
    adminStore: { 
      adHostsForAdmin: [],
      listForAdminSearchAd: [],
      usersForAdmin: [],
      adminSelectedUser: null,
      adminSearchedAdHost: null,

      adminAdHostResultDataList: [],
      adminListForTable: [],
      adminDuration: 10,
      adminTotalResult: {
        visitNum: 0,
        displayNum: 0,
        charge: 0,
        payOut: 0,
      },
      adminResultSearchSelectId: null,
      adminIsResultSearchResults: false,

      adminUserPayOutAddress: null,
      adminUserPayOutResults: [],
    }
  });
};

export default configureStore;
