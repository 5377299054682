import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Loader from "../../Loader/Loader";

import { useStore } from "../../../hook-store/store";

import { 
  addUserAdHostPayOutAddress
} from "../../../utils/pay-out-address/pay-out-address-util";

import { NODEURL } from "../../../App";

import XlmLogo from '../../../images/logos/xlm-logo.png';
import XrpLogo from '../../../images/logos/xrp-logo.png';
import PolygonUSDTLogo from '../../../images/logos/polygon-usdt-logo.webp';

import classes from './AddPayOutAddress.module.css';

function AddPayOutAddress(props) {
  const {
    setShowAddAddress
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { userPayOutAddress } = store.payOutStore;

  const [coinInput, setCoinInput] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [memoInput, setMemoInput] = useState('');
  const [nameInput, setNameInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const addInputs = {
    coin: coinInput,
    address: addressInput,
    memo: memoInput,
    name: nameInput,
  };

  console.log(addInputs);
  
  // useEffect(() => {
  // },[]);

  const coinInputChangeHandler = (event) => {
    setCoinInput(event.target.value);
  }

  const addressInputChangeHandler = (event) => {
    setAddressInput(event.target.value);
  }

  const memoInputChangeHandler = (event) => {
    setMemoInput(event.target.value);
  }

  const nameInputChangeHandler = (event) => {
    setNameInput(event.target.value);
  }

  const isAddressExist = (coinName, addressContent) => {
    if (userPayOutAddress && userPayOutAddress.addressList) {
      const isExistAddress = userPayOutAddress.addressList.find(address => {
        return address.coin === coinName && address.address === addressContent;
      });

      if (isExistAddress) {
        return true;
      }
    }
  };


  const addUserPayOutAddressHandler = async (addInputs) => {
    try {
      setIsLoading(true);

     const resData = await addUserAdHostPayOutAddress(
      NODEURL,
      localStorage.getItem('token'),
      addInputs.coin,
      addInputs.address,
      addInputs.memo,
      addInputs.name,
     );

     console.log(resData);

     if (resData.data) {
      dispatch('SET_USERPAYOUTADDRESS', resData.data);
      setShowAddAddress(false);

      dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'added',
          message: '',
        }
      );

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      }, 1000*5);
    }

     
     setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  let memoInputBody;
  let logoBody;
  const logoStyle = {height:"1.5rem", width: "1.5rem"};
  

  if (coinInput === 'xlm') {
    memoInputBody = (
        <div>
          <label>
            {t('payOutAddress02', 'Memo input (when memo is required)')}
          </label>
          <div>
            <input className="inputBase"
              style={{width: "10rem"}}
              type="text"
              onChange={memoInputChangeHandler}
            />
          </div>
        </div>
      );
    
    logoBody = (
      <img
        style={logoStyle}
        src={XlmLogo}
        alt="logo"
      />
    );
  }

  if (coinInput === 'xrp') {
    memoInputBody = (
      <div>
        <label>
          {t('payOutAddress03', 'Destination tag input (when destination tag is required)')}
        </label>
        <div>
          <input className="inputBase"
            style={{width: "10rem"}}
            type="text"
            onChange={memoInputChangeHandler}
          />
        </div>
      </div>
    );

    logoBody = (
      <img
        style={logoStyle}
        src={XrpLogo}
        alt="logo"
      />
    );
  }

  if (coinInput === 'polygon:usdt') {
    logoBody = (
      <img
        style={logoStyle}
        src={PolygonUSDTLogo}
        alt="logo"
      />
    );
  }

  let addPayOutAddressBody;

  addPayOutAddressBody = (
    <div>
      <div className={classes.addPayOutAddressInput}>
        <label>{t('payOutAddress04', 'Coin name')}</label>
        <div className={classes.addPayOutAddressCoinSelect}>
            <select className="selectBase"
              value={coinInput}
              onChange={coinInputChangeHandler}
            > 
              <option value={''}>
                {t('payOutAddress05', 'Select coin')}
              </option>
              {['xlm', 'xrp', 'polygon:usdt'].map(coin => {

                let displayCoinName = 'XLM (Stellar)' ;

                if (coin === 'xrp') {
                  displayCoinName = 'XRP (Ripple)';
                }

                if (coin === 'polygon:usdt') {
                  displayCoinName = 'USDT (polygon network)'
                }

                return (
                  <option key={coin} value={coin}>
                    {displayCoinName}
                  </option>
                )  
              }
              )}
            </select>
            
            {logoBody}
    
          </div>
      </div>
      <div className={classes.addPayOutAddressInput}>
        <label>{t('payOutAddress06', 'Address input')}</label>
        <div>
          <input className="inputBase"
            style={{width: "100%"}}
            type="text"
            onChange={addressInputChangeHandler}
          />
        </div>
      </div>

      <div className={classes.addPayOutAddressInput}>
        {memoInputBody}
      </div>
        {/* <label>name-input</label>
        <input 
          type="text"
          onChange={nameInputChangeHandler}
        /> */}

        {isAddressExist(coinInput, addressInput) && (
          <div>
            <strong>
              {t('payOutAddress07', 'Address already exist')}
            </strong>
          </div>
        )}

        <div className={classes.addPayOutAddressButtons}>
          <button className="btnBase"
            disabled={isLoading}
            onClick={() => {
              setShowAddAddress(false);
            }}
          >
            {t('payOutAddress08', 'Cancel')}
          </button>
          <button className="btnBase"
            disabled={isLoading || !coinInput || !addressInput || isAddressExist(coinInput, addressInput)}
            onClick={() => {
              addUserPayOutAddressHandler(addInputs);
            }}
          >
            {t('payOutAddress09', 'Add')}
          </button>
        </div>
        {isLoading && (<Loader />)}
    </div>
  );
  return (
    <Fragment>
      {/* <div>add-payout-address-component</div> */}
      <div>{addPayOutAddressBody}</div>
    </Fragment>
  );
}

export default AddPayOutAddress;
