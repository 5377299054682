import { Fragment } from "react";

import CreateResultData from "../../components/AdHostResult/CreateResultData";
import AdHostResultTable from "../../components/AdHostResult/AdHostResultTable";

import classes from './AdHostResultPage.module.css';

function AdHostResultPage(props) {

  return (
    <Fragment>
      <CreateResultData />  
      <div className={classes.adHostResultTableContainer}
        // style={{paddingBottom:"300px", zIndex: '10'}}
      >
        <AdHostResultTable />    
      </div>
    </Fragment>
  );
}

export default AdHostResultPage;
