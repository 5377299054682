import React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';
// import { useTranslation } from 'react-i18next/hooks';
import _ from 'lodash';

// import Loader from '../../../Loader/Loader';
// import TopBarContents from '../GroupTopElements/TopBarContents';
// import RightContents from '../GroupRightElements/RightContents';

// import AdItems from '../AdItems/AdItems';
// import VideoAdItems from '../AdItems/VideoAdItems';
import VideoWithAdVideos from '../AdItems/VideoWithAd/VideoWithAdVideos';
// import GetAdList from '../GetAds/GetAdList';

// import { storeClickData, getNearAdElements, createDisplayAd } from '../../../util/ad/ad-visit';
// import { storeAdDisplay } from '../../../util/ad/ad-display';

import { useStore } from '../../../hook-store/store';
import { useOnScreen } from '../../../custom-hooks/useOnScreen';

// import { NODEURL } from '../../../App';

import Black2s from '../AdItems/VideoWithAd/black-bg-2s.mp4';
// import VwaFallbackVideo from '../../../images2/remeet-test2-2-vwa-fallback.mp4';
import VwaFallbackVideo from '../../../images/remeet-test2-w-sound-fallback.mp4';
// import classes from './GroupTopElements.module.css';

const VideoWithAdDisplayAdHost = (props) => {
  // console.log('VideoWithAdDisplay-props', props);

  const { 
    adType, 
    adPlaceId, 
    adHostData,
    // setPlayState, 
  } = props;


  // const currentUrl = new URL(window.location.href);
  // const queryParams = currentUrl.searchParams;
  // const roomIdParam = queryParams.get('groupRoomId');
  // console.log('queryParams', queryParams.get('groupRoomId'));

  // const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-in VideoAdElementDisplay.js', store);
  // const adList = store.adStore.adList;
  // const videoAdList = store.adStore.videoAdList;
  const adList = [];
  const videoAdList = [];
  
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const [isLoading, setIsLoading] = useState(true);
  // const [activeList, setActiveList] = useState([]);
  // const [displayAd, setDisplayAd] = useState();
  
  const [isDisplayed, setIsDisplayed] = useState(false);
  // const [isClicked, setIsClicked] = useState(false);

  const [displayVideoAd, setDisplayVideoAd] = useState();
  const [activeVideoList, setActiveVideoList] = useState([]);

  const [videosItems, setVideosItems] = useState([]);
  const [isVideosStart, setIsVideosStart] = useState(false);
  const [isVideosEnd, setIsVideosEnd] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [testxxx, setTestxxx] = useState();
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000* 60);

    if (adList.length > 0) {
      setIsLoading(false);
    }
  },[adList]);

  
  // useEffect(() => {
  //   // console.log('isVisible', isVisible, displayAd)
  //   if (isVisible && activeVideoList.length > 0 && displayVideoAd && adType) {
  //     console.log('isVisible displayVideoAd', displayVideoAd);
  //     console.log('isVisible, displayAd exists ...');

  //     if (!isDisplayed) {
  //       console.log('isVisible, now displayed, adplaceId', adPlaceId);
  //       setIsDisplayed(true);

  //       // storeAdDisplay(ADNETWORK_URL, 'token', displayVideoAd.adElementId, adPlaceId);
  //       storeAdDisplayHandler(
  //         NODEURL, 
  //         localStorage.getItem('token'), 
  //         displayVideoAd.adElementId, 
  //         adPlaceId
  //       );
        
  //     } else {
  //       console.log('isVisible, already displayed, adplaceId', adPlaceId);
  //     }
  //   }
  // },[isVisible, activeVideoList, displayVideoAd, adType])

  useEffect(() => {
    if (videoAdList.length > 0 && adHostData && !isVideosStart) {
      const activeVideoList = videoAdList.filter(ad => {
        return ad.start < Date.now() && ad.end > Date.now();
      });
      console.log('activeVideoList', activeVideoList);
      setActiveVideoList(activeVideoList);
    
      if (activeVideoList.length > 0) {
        // const displayVideoAd = createDisplayAd(activeVideoList);
        setDisplayVideoAd(displayVideoAd);
        console.log('displayVideoAd', displayVideoAd);

        setVideosItems(createVideoItems(adHostData, [displayVideoAd]));
        
        if (isVideosEnd) {
          setIsVideosEnd(false);
        }
      }

      if (videoAdList.length === 0 && adHostData && !isVideosStart) {
        console.log('adHostData', adHostData);
      }



      //// select random unique ads from list think about later
      ////
      // const activeUniqs = _.uniqBy(activeVideoList, 'adElementId');
      // const activeUniqLength = activeUniqs.length;
      // console.log(activeUniqs, activeUniqLength);

      // if (activeUniqLength >= 3) {
      //   ///// select uniq ads 
      // }
      // if (activeUniqLength === 2) {
      //   uniqVs = _.uniqBy(activeVideoList, 'adElementId');
      //   uniqVs = randomizeElemnts(activeVideoList, 1);
      //   console.log(uniqVs);
      // } else {
      //   uniqVs = randomizeElemnts(activeVideoList, 1);
      //   console.log(uniqVs);
      // }

      // setVideosItems(createVideoItems(adHostData, uniqVs));


    }

  },[videoAdList, adHostData, isVideosStart, isVideosEnd]);

  useEffect(() => {
    // console.log('isVideos', isVideosStart, isVideosEnd);

    //// initialize start end state when video play ended
    if (isVideosStart && isVideosEnd) {
      setIsVideosStart(false);
      setIsVideosEnd(false);
    }
  },[isVideosStart, isVideosEnd]);



  const getRandomObj = (objArray, length) => {
    return objArray[Math.floor(length * Math.random())]
  }
  
  const randomizeElemnts = (array, count) => {
      if (count > array.length) {
          throw new Error('Array size cannot be smaller than expected random numbers count.');
      }
      const result = [];
      const guardian = new Set();
      // while (result.length < count) {
      //     const index = randomizeIndex(count);
      //     console.log(index, guardian);
      //     if (guardian.has(index)) {
      //         continue;
      //     }
      //     const element = array[index];
      //     guardian.add(index);
      //     result.push(element);
      // }
      while (result.length < count) {
        // const index = randomizeIndex(count);
        const randomObj = getRandomObj(array, array.length);

        console.log(result, randomObj);
        // if (guardian.has(index)) {
        //     continue;
        // }
        // const element = array[index];
        // guardian.add(index);

        const isInResult = result.find(ele => {
          return ele.id === randomObj.id;
          return ele.adElementId === randomObj.adElementId;
        });

        if (!isInResult) {
          result.push(randomObj);
        }
    }
      return result;
  };










  // useEffect(() => {
  //   console.log('videoState', isVideosStart, isVideosEnd);
  // },[videoAdList, isVideosStart, isVideosEnd]);

  // const storeAdDisplayHandler = async (url, token, adElementId, adPlaceId) => {
  //   try {
  //     await storeAdDisplay(url, token, adElementId, adPlaceId);
  //     // console.log(resData);
  //   } catch(err) {
  //     console.log(err);

  //     if (err.message === 'budget-error') {
  //       //// adlist change (video)
  //       const deletedList = videoAdList.filter(vad => {
  //         return vad.adElementId !== adElementId;
  //       });

  //       dispatch('SET_VIDEOADLIST', deletedList);
  //     }
  //   }
  // };


  const createVideoItems = (adHostData, ads) => {
    let items = [
      { 
        id: 0,
        type: 'pre-ad',
        adElementId: '',
        canSkip: false,
        skipMinSec: null,
        // vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
        vSrc: `${Black2s}`,
        thumbnailUrl: '',
        linkUrl: '',
        token: ''
      },
      // { 
      //   id: 1,
      //   type: 'ad',
      //   adElementId: ads[0].adElementId,
      //   // adElementId: '60e110d91892a4426830768c-1666770196301',
      //   canSkip: true,
      //   skipMinSec: 3,
      //   vSrc: ads[0].adImageUrl,
      //   thumbnailUrl: '',
      //   linkUrl: ads[0].linkUrl,
      // },
      // { 
      //   id: 2,
      //   type: 'ad',
      //   adElementId: ads[1].adElementId,
      //   // adElementId: '60e110d91892a4426830768c-1668502168734',
      //   canSkip: true,
      //   skipMinSec: 3,
      //   vSrc: ads[1].adImageUrl,
      //   thumbnailUrl: '',
      //   linkUrl: ads[1].linkUrl,
      // },
      // {
      //   id: 3,
      //   type: '',
      //   adElementId: '',
      //   canSkip: false,
      //   skipMinSec: null,
      //   vSrc: adHostData.uploadVideoUrl,
      //   thumbnailUrl: adHostData.thumbnailUrl,
      //   linkUrl: '',
      // },
      // ...
    ];

    for (let i=0; i<ads.length; i++) {
      items.push({ 
        id: i+1,
        type: 'ad',
        adElementId: ads[i].adElementId,
        // adElementId: '60e110d91892a4426830768c-1666770196301',
        canSkip: true,
        skipMinSec: 11,
        vSrc: ads[i].adImageUrl,

        thumbnailUrl: '',
        linkUrl: ads[i].linkUrl,
        token: ads[i].token,
      });
    }

    items.push({
      id: items.length,
      type: '',
      adElementId: '',
      canSkip: false,
      skipMinSec: null,
      vSrc: adHostData.uploadVideoUrl,
      thumbnailUrl: adHostData.thumbnailUrl,
      linkUrl: '',
      token: '',
    });

    console.log('items', items);

    return items;

  };


  let adElementDisplayBody;

  if (isVideosEnd) {
    adElementDisplayBody = null;
  }
  
  // if (!isVideosEnd && adHostData && !displayVideoAd) {
  if (!isVideosEnd && adHostData && !displayVideoAd) {
    const fItems = [  
      { 
        id: 0,
        type: 'pre-ad',
        adElementId: '',
        canSkip: false,
        skipMinSec: null,
        // vSrc: "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4",
        vSrc: `${Black2s}`,
        thumbnailUrl: '',
        linkUrl: '',
        token: '',
      },
      { 
        id: 1,
        type: 'ad',
        adElementId: '',
        canSkip: true,
        skipMinSec: 5,
        vSrc: VwaFallbackVideo,
        thumbnailUrl: '',
        linkUrl: 'https://bookremeet.spaceeight.net',
        token: '',
      },
      {
        id: 1,
        type: '',
        adElementId: '',
        canSkip: false,
        skipMinSec: null,
        vSrc: adHostData.uploadVideoUrl,
        thumbnailUrl: adHostData.thumbnailUrl,
        linkUrl: '',
        token: '',
      }
    ];

    adElementDisplayBody = (
      <VideoWithAdVideos
        // ad={adList[0]} 
        // ad={displayVideoAd}
        adType={adType} 
        // roomIdParam={roomIdParam}
        activeList={activeVideoList}
        isVisible={isVisible}
        // setPlayState={setPlayState}
        adPlaceId={adPlaceId}

        // items={videosItems}
        items={fItems}
        setIsVideosStart={setIsVideosStart}
        setIsVideosEnd={setIsVideosEnd}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        adHostData={adHostData}
      />
    );
  }

  if (!isVideosEnd && adHostData && displayVideoAd) {
    adElementDisplayBody = (
      <VideoWithAdVideos
        // ad={adList[0]} 
        // ad={displayVideoAd}
        adType={adType} 
        // roomIdParam={roomIdParam}
        activeList={activeVideoList}
        isVisible={isVisible}
        // setPlayState={setPlayState}
        adPlaceId={adPlaceId}

        items={videosItems}
        setIsVideosStart={setIsVideosStart}
        setIsVideosEnd={setIsVideosEnd}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        adHostData={adHostData}
      />
    );
  }


  return (
    <Fragment>
      {/* <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div> */}
      <span ref={ref}>{isVisible && ``}</span>
      {/* <div style={{ height:"100%", width: "100%" }}> */}
        {adElementDisplayBody}

        {/* <video 
          style={{height: "100px", width: "200px"}}
          src={adHostData.uploadVideoUrl}
          controls
        /> */}
    </Fragment>
  );
}

export default VideoWithAdDisplayAdHost;