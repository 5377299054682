import { Fragment } from "react";

import UserAdHostList from "../../components/AdHost/UserAdHostList/UserAdHostList";

function AdHostListPage(props) {

  return (
    <Fragment>
      <UserAdHostList />
    </Fragment>
  );
}

export default AdHostListPage;
