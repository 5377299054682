import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../hook-store/store';

import classes from './Footer.module.css';


// import { Link } from 'react-router-dom';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

function Footer(props) {
  // console.log('Layout.js-props', props);

  const [store, dispatch] = useStore();
  // console.log('store- Layout.js', store);


  return (
    <Fragment>

      <div className={classes.footer}>
        <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
            <Link to="/privacypolicy">privacy policy</Link>
          </span>
          <span className={classes.footerLink}>
            <Link to="/termsofuse">terms of use</Link>
          </span>
          <span className={classes.footerLink}>
            <Link to="/contact">contact</Link>
          </span>
        </div>
        
        <div className={classes.footerLinks}>
          <span className={classes.footerLink}>
              Adnetwork Ad host by SpaceEight
          </span>
        </div>
      </div>

    </Fragment>
  );
}

export default Footer;
