// import _ from 'lodash';

import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_USERPAYOUTADDRESS: (state, payload) => {
      return {
        // ...state,
        payOutStore: {
          ...state.payOutStore,
          userPayOutAddress: payload,
        }
      };
    },
    SET_USERPAYOUTRESULTS: (state, payload) => {
      return {
        // ...state,
        payOutStore: {
          ...state.payOutStore,
          userPayOutResults: payload,
        }
      };
    },
  };

  initStore(actions, {
    payOutStore: {
      userPayOutAddress: null,
      userPayOutResults: [],
    }
  });
};

export default configureStore;
