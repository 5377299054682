
import  { Fragment, useRef, useState, useEffect } from 'react';

// import Loader from '../../../Loader/Loader';
// import AdElementDisplay from '../../AdElememtDisplay/AdElementDisplay';
// import VideoWithAdBannerDisplay from '../../AdElememtDisplay/VideoWithAdBannerDisplay';
import VideoWithAdBannerDisplayAdHost from '../../AdElementDisplay/VideoWithAdBannerDisplayAdHost';

import { useStore } from '../../../../hook-store/store';
// import { storeAdDisplay } from '../../../../util/ad/ad-display';
// import { storeAdVideoViewVisit } from '../../../../util/ad/ad-visit';
// import { calcVideoSize, toHHMMSS } from '../../../../util/ad/video-with-ad';

// import { NODEURL } from '../../../../App';

// const bAds = [
//   { id: 0, start: 2, end: 5 },
//   { id: 1, start: 8, end: 11 },
//   { id: 2, start: 14, end: 17 },
// ];

const VideoOverElement = props => {
  const { 
    items, 
    itemsRef,
    vIndex,
    isPlaying,
    // videoCurrentTime,
    adPlaceId,
    dispId,
    adHostData,
    videoRect,
    pipActive,
    isFullScreen,
  } = props;
  // console.log('VideoOverElement-props', props);

  const [store, dispatch] = useStore();
  // console.log(window.innerHeight, window.innerWidth);
  
  // const [videoRect, setVideoRect] = useState();
  const [hideClick, setHideClick] = useState(false);
  const [displayedBannerAds, setDisplayedBannerAds] = useState([]);
  const [bIndex, setBIndex] = useState(-1);
  // const [sortedBannerAds, setSortedBannerAds] = useState([]);

  let bAds = [];

  if (adHostData && adHostData.bannerAds && adHostData.bannerAds.length > 0) {
    bAds = adHostData.bannerAds; 
  }
  // useEffect(() => {
  //   if (vIndex && isPlaying) {
  //     const videoEl = itemsRef.current[vIndex];      
  //     // console.log(vIndex, videoEl, videoEl.getBoundingClientRect());
  //     setVideoRect(videoEl.getBoundingClientRect());
  //   }
  // },[vIndex, isPlaying]);

  // useEffect(() => {
  //   console.log(itemsRef.current[vIndex].currentTime);
  // },[itemsRef.current[vIndex].currentTime]);

  useEffect(() => {

    if (isPlaying) {
      const videoEl = itemsRef.current[vIndex];
  
      videoEl.addEventListener('timeupdate', (event) => {
        // console.log(videoEl.currentTime,  vIndex);

        const adTimeIndex = bAds.findIndex(ad => {
          return videoEl.currentTime >= ad.start && videoEl.currentTime <= ad.end;
        });

        // console.log(adTimeIndex);
        if (adTimeIndex < 0) {
          setHideClick(false);
        }

        if (adTimeIndex >= 0) {
          setBIndex(adTimeIndex);
        }
      });
    }
  },[isPlaying]);


  const hideClickHandler = () => {
    setHideClick(true);
  }

  const setDisplayedBannerAdsHandler = (ad) => {
    const dBannerAds = displayedBannerAds;
    const addedList = dBannerAds.concat([ad]);
    setDisplayedBannerAds(addedList);

    // setBIndex(bIndex+1);
    // console.log(addedList);
  };

  const videoEl = itemsRef.current[vIndex];
  let isInAdTime;

  if (videoEl && videoEl.currentTime > 0) {

    isInAdTime = bAds.find(ad => {
      return videoEl.currentTime >= ad.start && videoEl.currentTime <= ad.end;
    });
  }


  // console.log(isFullScreen);

  // console.log(videoRect, window.innerWidth, window.innerHeight);
  
  const display300x65 = (
    <VideoWithAdBannerDisplayAdHost
      adType='300x65'
      adPlaceId={adPlaceId}
      displayedBannerAds={displayedBannerAds}
      setDisplayedBannerAdsHandler={setDisplayedBannerAdsHandler}
      dispId={dispId}
      itemsRef={itemsRef}
      vIndex={vIndex}
      bIndex={bIndex}
      bAds={bAds}
      // sortedBannerAds={sortedBannerAds}
      // adHostData={adHostData}
    />
  );

  const display450x90 = (
    <VideoWithAdBannerDisplayAdHost
      adType='450x90'
      adPlaceId={adPlaceId}
      displayedBannerAds={displayedBannerAds}
      setDisplayedBannerAdsHandler={setDisplayedBannerAdsHandler}
      dispId={dispId}
      itemsRef={itemsRef}
      vIndex={vIndex}
      bIndex={bIndex}
      bAds={bAds}
      // sortedBannerAds={sortedBannerAds}
      // adHostData={adHostData}
    />
  );

  const display600x130 = (
    <VideoWithAdBannerDisplayAdHost
      adType='600x130'
      adPlaceId={adPlaceId}
      displayedBannerAds={displayedBannerAds}
      setDisplayedBannerAdsHandler={setDisplayedBannerAdsHandler}
      dispId={dispId}
      itemsRef={itemsRef}
      vIndex={vIndex}
      bIndex={bIndex}
      bAds={bAds}
      // sortedBannerAds={sortedBannerAds}
      // adHostData={adHostData}
    />
  );

  let overElStyle;
  let videoDisplayEl;



  let bannerBottom = 0;

  if (videoRect) {
    bannerBottom = `${videoRect.height - 30}px`;
  }


  if (window.innerWidth <= 480) {

    if (videoRect) {
      if (videoRect.height >= window.innerHeight - 70) {
        bannerBottom = `${window.innerHeight - 70}px`;
      } else {
        bannerBottom = `${videoRect.height - 30}px`;
      }
    }

    overElStyle = {
      position:"absolute",
      // top: "1px", 
      bottom: bannerBottom,
      left: "50%",
      transform: "translate(-50%, 0)",
      zIndex:"100", 
      height:"68px", 
      width:"305px", 
      // border:"1px solid red",
      // background: "gray",
    };

    if (window.innerWidth <= 300) {
      overElStyle = {
        position:"absolute",
        // top: "1px", 
        bottom: bannerBottom,
        left: "0",
        zIndex:"100", 
        height:"68px", 
        width:"305px", 
        // border:"1px solid red",
        // background: "gray",
      };
    }

    videoDisplayEl = (
      display300x65
    );
  }

  if (window.innerWidth > 480 && window.innerWidth <= 768) {
    if (videoRect) {
      if (videoRect.height >= window.innerHeight - 95) {
        bannerBottom = `${window.innerHeight - 100}px`;
      } else {
        bannerBottom = `${videoRect.height - 30}px`;
      }
    }
    

    //// top space larger case
    if (window.innerHeight - videoRect.height > 65) {  
      overElStyle = {
        position:"absolute",
        // top: "1px", 
        bottom: bannerBottom,
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex:"100", 
        height:"95px", 
        width:"455px", 
        // border:"1px solid red",
        // background: "gray",
      };

      videoDisplayEl = (
        display450x90
      );
    }

    //// top space smaller case (mobile ...)
    if (window.innerHeight - videoRect.height <= 65) {
      //// top of video
      let bannerTop = window.innerHeight - videoRect.height;

      if (window.innerHeight - videoRect.height > 30) {
        // bannerTop = window.innerHeight - videoRect.height -30;
      }
      overElStyle = {
        position:"absolute",
        // top: "1px", 
        top: `${bannerTop}px`, 
        // bottom: bannerBottom,
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex:"100", 
        height:"68px", 
        width:"305px", 
        // border:"1px solid red",
        // background: "gray",
      };

      videoDisplayEl = (
        display300x65
      );
    }
  }

  if (window.innerWidth > 768) {

    if (videoRect) {
      if (videoRect.height >= window.innerHeight - 135) {
        bannerBottom = `${window.innerHeight - 140}px`;
      } else {
        bannerBottom = `${videoRect.height - 30}px`;
      }
    }

    //// top space larger case
    if (window.innerHeight - videoRect.height > 65) {
      
      overElStyle = {
        position:"absolute",
        // top: "1px", 
        bottom: bannerBottom,
        left: "50%",
        transform: "translate(-50%, 0)",
        zIndex:"100", 
        height:"135px", 
        width:"605px", 
        // border:"1px solid red",
        // background: "gray",
      };
  
      videoDisplayEl = (
        display600x130
      );
    }

    //// top space smaller case
    if (window.innerHeight - videoRect.height <= 65) {

      //// top of video
      let bannerTop = window.innerHeight - videoRect.height;

      if (window.innerHeight - videoRect.height > 30) {
        // bannerTop = window.innerHeight - videoRect.height -30;
      }

      if (videoRect.height > 350) {

        overElStyle = {
          position:"absolute",
          // top: "1px", 
          top: `${bannerTop}px`, 
          // bottom: bannerBottom,
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex:"100", 
          height:"95px", 
          width:"455px", 
          // border:"1px solid red",
          // background: "gray",
        };
  
        videoDisplayEl = (
          display450x90
        );
      }

      if (videoRect.height <= 350) {
        overElStyle = {
          position:"absolute",
          // top: "1px", 
          top: `${bannerTop}px`, 
          // bottom: bannerBottom,
          left: "50%",
          transform: "translate(-50%, 0)",
          zIndex:"100", 
          height:"68px", 
          width:"305px", 
          // border:"1px solid red",
          // background: "gray",
        };
  
        videoDisplayEl = (
          display300x65
        );
      }


    }
  }

  // console.log(videoRect)
  
  let videoOverElementBody;

  if (!isInAdTime) {
    videoOverElementBody = null;
  }


  if (isInAdTime && !items[vIndex].type && !hideClick && adPlaceId && 
    !pipActive && !isFullScreen) {

      let closeStyleBase = {
        position:"absolute", 
        top:"0", 
        background: "black",
        color: "white",
        opacity: "0.5",
        fontSize: "22px",
        maxHeight:"22px",
        maxWidth: "22px",
        lineHeight: "14px",
        padding: "0 1px 3px 1px",
        cursor: "pointer",
        // width:"20px",
      }

      let closeStyle;

      if (window.innerWidth <= 350) {
        closeStyle = {
          ...closeStyleBase,
          left: "0",
        }
      }

      if (window.innerWidth > 350) {
        closeStyle = {
          ...closeStyleBase,
          right:"-12px",
        }
      }


    videoOverElementBody = (
      <span style={overElStyle}>
        {/* <VideoWithAdBannerDisplay
          adType='300x65'
          adPlaceId={adPlaceId}
          displayedBannerAds={displayedBannerAds}
          setDisplayedBannerAdsHandler={setDisplayedBannerAdsHandler}
          dispId={dispId}
          itemsRef={itemsRef}
          vIndex={vIndex}
          bIndex={bIndex}
          bAds={bAds}
          // sortedBannerAds={sortedBannerAds}
          // adHostData={adHostData}
        /> */}
        {videoDisplayEl}
        <span
          style={closeStyle}
          onClick={() => { 
            console.log('clicked');
            hideClickHandler();
          }}
        >
          x
        </span>
      
      </span>
    );
  }

  return (
  <Fragment>
    {!hideClick && (
      <div>
        {videoOverElementBody}
      </div>
    )}
  </Fragment>
);
}

export default VideoOverElement;